define('vopay_app/routes/dashboard/bulk-payment/group', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		firebase: _ember['default'].inject.service('firebase'),
		model: function model(id) {
			var authed = true;
			if (authed) {

				return _ember['default'].RSVP.hash({
					key: id["template"],
					template: this.get("firebase").getSingleRecordUser("templates", id["template"]),
					beneficiaries: this.get("firebase").getRecordUser("beneficiary")
				});
			} else {
				this.transitionTo('login');
			}
		}
	});
});