define('vopay_app/components/jump-page', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		isVisible: true,
		routing: _ember['default'].inject.service('-routing'),
		init: function init() {
			this._super.apply(this, arguments);
		},

		actions: {
			goToBalance: function goToBalance() {
				this.get("routing").transitionTo("dashboard.balances");
				return false;
			},
			goToPayment: function goToPayment() {
				sessionStorage.setItem("isDeposit", "false");
				this.get("routing").transitionTo("dashboard.add-account");
				return false;
			},
			goToMoneyTransfer: function goToMoneyTransfer() {
				this.get("routing").transitionTo("dashboard.transactions");
				return false;
			},
			goToBank: function goToBank() {
				sessionStorage.setItem("isDeposit", "true");
				this.get("routing").transitionTo("dashboard.fund");
				return false;
			},
			goToCollect: function goToCollect() {
				this.get("routing").transitionTo("dashboard.collect");
				return false;
			},
			goToConverPay: function goToConverPay() {

				this.get("routing").transitionTo("dashboard.convert-pay");
				return false;
			},
			goToEVoucher: function goToEVoucher() {
				this.get("routing").transitionTo("dashboard.e-voucher");
				return false;
			}
		}
	});
});