define('vopay_app/components/login-form', ['exports', 'ember', 'vopay_app/mixins/manage-cookies', 'vopay_app/config/environment'], function (exports, _ember, _vopay_appMixinsManageCookies, _vopay_appConfigEnvironment) {

	var businessShortName = _vopay_appConfigEnvironment['default'].businessShortName;
	var businessLongName = _vopay_appConfigEnvironment['default'].businessLongName;
	//const apiBase = config.apiBase;

	exports['default'] = _ember['default'].Component.extend(_vopay_appMixinsManageCookies['default'], {
		SupportEmail: _vopay_appConfigEnvironment['default'].businessEmail,
		LongName: businessLongName,
		ShortName: businessShortName,
		image: _vopay_appConfigEnvironment['default'].imageLarge,
		session: _ember['default'].inject.service('session'),
		routing: _ember['default'].inject.service('-routing'),
		api: _ember['default'].inject.service('api'),
		firebase: _ember['default'].inject.service('firebase'),
		email: null,
		errorMsg: null,
		fbURL: null,
		googleURL: null,
		init: function init() {

			this._super.apply(this, arguments);

			// Remember me
			if (localStorage.getItem("logout") === true || localStorage.getItem("logout") === "true") {
				this.set("logoutMessage", true);
				localStorage.removeItem("logout");
			}
			if (localStorage.getItem("reset") === true || localStorage.getItem("reset") === "true") {
				this.set("resetMessage", true);
				localStorage.removeItem("reset");
			}
			if (this.getCookie('VOP-USR-EMAIL')) {

				this.set('email', this.getCookie('VOP-USR-EMAIL'));
			}
		},
		didInsertElement: function didInsertElement() {
			_ember['default'].$('form').parsley({
				successClass: " ",
				errorClass: "form__input--error",
				errorsWrapper: "<p></p>",
				errorTemplate: "<span class='form__message'></span>"
			});
			_ember['default'].$("input").keyup(function () {
				_ember['default'].$(this).addClass("form__input--active");
			});
			_ember['default'].$("input").focusout(function () {
				_ember['default'].$(this).removeClass("form__input--active");
			});
		},
		getTransactions: function getTransactions(pageNumber, transactions) {
			var pageSize = this.get("pageSize");
			var filteredList = {};
			if (transactions) {
				var keys = Object.keys(transactions);
				for (var i = (pageNumber - 1) * pageSize; i < keys.length && i < pageNumber * pageSize; i++) {

					filteredList[keys[i]] = transactions[keys[i]];
				}
				this.set("recordCount", keys.length);
				this.set("pageNumber", pageNumber);
				this.set("filteredList", filteredList);
			} else {
				this.set("recordCount", 0);
				this.set("pageNumber", 1);
			}
		},
		filterTransactions: function filterTransactions(transactions) {
			var filteredList = {};

			if (transactions) {
				var keys = Object.keys(transactions);

				for (var i = 0; i < keys.length; i++) {

					if (typeof transactions[keys[i]] !== 'undefined') {

						if (transactions[keys[i]].SubType != null && transactions[keys[i]].SubType == "PayLink") {

							filteredList[keys[i]] = transactions[keys[i]];
						}
					}
				}

				return filteredList;
			}
		},
		hasPaylink: function hasPaylink(transactions, Id) {

			if (transactions) {
				var keys = Object.keys(transactions);

				for (var i = 0; i < keys.length; i++) {

					if (typeof transactions[keys[i]] !== 'undefined') {

						if (transactions[keys[i]].PaylinkRequestID == Id) {

							return true;
						}
					}
				}
			}
			return false;
		},
		actions: {
			/***
     * login
     *
     * Authenticates the user
     */
			closeModalLogout: function closeModalLogout() {
				this.set("logoutMessage", false);
			},
			closeModaReset: function closeModaReset() {
				this.set("resetMessage", false);
			},
			login: function login() {
				var self = this;
				//serialize the form and get the values
				var loginData = _ember['default'].$('#loginForm').serializeArray();
				var arrLength = loginData.length;
				var options = {};

				//validate here, if all good proceed
				var form = _ember['default'].$('#loginForm');
				form.parsley().validate();

				//check if its valid
				if (form.parsley().isValid()) {
					//set all the form data
					for (var i = 0; i < arrLength; i++) {
						options[loginData[i].name] = loginData[i].value;
					}

					// Remember me
					if (options['StoreLogin']) {

						var expDate = new Date(new Date().setFullYear(9999)).toUTCString();
						this.setCookie('VOP-USR-EMAIL', options['Username'] + '; expires=' + expDate);
					} else {

						this.removeCookie('VOP-USR-EMAIL');
					}
					localStorage.setItem("Username", options['Username']);

					// sign up the user
					this.get('session').authenticate('authenticator:custom', options).then(function () {
						//if we're logged in, send the user to the transfer

						if (self.get('session.isAuthenticated')) {
							var d = new Date();
							sessionStorage.setItem("SessionTime", d.getTime());
							clearInterval(window.sessionTimeout);
							window.sessionTimeout = setInterval(function () {
								var d2 = new Date();
								if (600000 < d2.getTime() - sessionStorage.getItem("SessionTime")) {
									clearInterval(window.sessionTimeout);
									localStorage.removeItem("key");
									localStorage.removeItem("accountID");
									localStorage.removeItem("signature");
									self.get("routing").transitionTo("login");
								}
							}, 1000);
							setTimeout(function () {
								self.get("firebase").getRecordUser("beneficiary").then(function (data2) {

									var optionsPaylink = {
										PaylinkRequestType: "beneficiary"
									};
									data2 = self.filterTransactions(data2);

									self.get("api").getPaylinkHistory(optionsPaylink).then(function (data) {

										var paylinkRequests = data.PaylinkRequests;
										for (var i = 0; i < Object.keys(paylinkRequests).length; i++) {
											if (paylinkRequests[i].Status == "completed") {
												if (!self.hasPaylink(data2, paylinkRequests[i].PaylinkRequestID)) {
													var object = {
														FirstName: paylinkRequests[i].FirstName,
														LastName: paylinkRequests[i].LastName,
														Token: paylinkRequests[i].Token,
														MaskedAccount: paylinkRequests[i].AccountNumber,
														BranchTransitNumber: paylinkRequests[i].BranchTransitNumber,
														FinancialInstitutionNumber: paylinkRequests[i].FinancialInstitutionNumber,
														OuthType: "iQ11",
														SubType: "PayLink",
														PaylinkRequestID: paylinkRequests[i].PaylinkRequestID,
														Currency: "CAD",
														Country: "CA",
														Type: "Bank Account"
													};

													self.get("firebase").createRecordUser("beneficiary", object);
												}
											}
										}
									}, function (error) {});
								});
							}, 100);

							self.get("routing").transitionTo("dashboard.info");
						}
					})['catch'](function (result) {
						self.set('errorMsg', result);
					});
				}

				return false;
			},
			setGoogle: function setGoogle() {
				localStorage.setItem("googleLogin", true);
				return true;
			},
			setFB: function setFB() {
				localStorage.setItem("facebookLogin", true);
				return true;
			}
		}
	});
});