define('vopay_app/components/operating-calendar', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    api: _ember['default'].inject.service('api'),
    init: function init() {
      this._super.apply(this, arguments);

      var bankHoliday = this.get("bankHoliday.BankHolidays");
      var events = [];
      var keys = Object.keys(bankHoliday);
      for (var i = 0; i < keys.length; i++) {
        var value = bankHoliday[i];
        var valueNext = bankHoliday[i + 1];
        var color = "#17a2b8";
        if (valueNext != null && value.HolidayDate == valueNext.HolidayDate && value.HolidayName == valueNext.HolidayName) {
          color = "#007bff";
          i++;
        } else if (value.Country == "Canada") {
          color = "#6f42c1";
        }

        var _event = {
          start: value.HolidayDate,
          title: value.HolidayName,
          color: color
        };

        events.push(_event);
      }

      this.set("CalendarEvents", events);
    },
    didInsertElement: function didInsertElement() {

      _ember['default'].$('#calendar').fullCalendar({
        header: {
          right: false,
          left: false,
          center: false
        },
        // customize the button names,
        // otherwise they'd all just say "list"
        defaultView: 'listYear',
        views: {
          listYear: { buttonText: 'Day' },
          listMonth: { buttonText: 'Month' },
          listWeek: { buttonText: 'Week' }
        },
        navLinks: false, // can click day/week names to navigate views
        editable: false,
        displayEventTime: false,
        eventLimit: true, // allow "more" link when too many events
        viewRender: function viewRender(view) {
          var title = view.title;
          if (view.type == "listYear") {
            title = title + " Holidays";
          }
          _ember['default'].$("#CalendarTitle").html(title);
        },
        events: this.get("CalendarEvents")
      });
    },
    actions: {
      next: function next() {
        _ember['default'].$('#calendar').fullCalendar('next');
      },
      prev: function prev() {
        _ember['default'].$('#calendar').fullCalendar('prev');
      },
      ViewChanged: function ViewChanged(value) {
        _ember['default'].$('#calendar').fullCalendar('changeView', value);
      }
    }
  });
});