define('vopay_app/routes/dashboard/schedule/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    api: _ember['default'].inject.service('api'),
    model: function model() {
      var options = {};
      return _ember['default'].RSVP.hash({
        data: this.get('api').getScheduleTransactions(options)

      });
    }

  });
});