define("vopay_app/components/schedule-list", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Component.extend({
        ScheduleTypes: ["All", "Single", "Recurring"],
        Statuses: ["All", "Cancelled", "Scheduled", "Completed", "In Progress"],
        Frequencies: ["All", "Monthly", "Weekly", "Bi-Weekly", "Quarterly", "Semi-Annually", "Annually", "Bi-Monthly"],
        ScheduleType: "All",
        Status: "All",
        Frequency: "All",
        keyword: "",
        init: function init() {
            this._super.apply(this, arguments);
            var data = this.get("data");
            this.set("Schedules", data);
            this.set("SchedulesOriginal", data);
        },
        frequencyName: function frequencyName(value) {
            if (value == "Monthly") {
                return "monthly";
            } else if (value == "Weekly") {
                return "weekly";
            } else if (value == "Bi-Weekly") {
                return "biweekly";
            } else if (value == "Quarterly") {
                return "3 months";
            } else if (value == "Semi-Annually") {
                return "6 months";
            } else if (value == "Annually") {
                return "yearly";
            } else if (value == "Bi-Monthly") {
                return "bimonthly";
            } else {
                return "monthly";
            }
        },
        filterTransactions: function filterTransactions(searchTerms, Frequency, ScheduleType, Status) {
            var SchedulesOriginal = this.get("SchedulesOriginal");
            var filteredList = {};

            searchTerms = searchTerms.toLowerCase();
            searchTerms = searchTerms.replace(/\$/g, '');
            searchTerms = searchTerms.replace(/\,/g, '');
            searchTerms = searchTerms.replace(/\s|_/g, "", '');

            if (SchedulesOriginal) {
                var keys = Object.keys(SchedulesOriginal);
                var next = -1;
                for (var i = 0; i < keys.length; i++) {

                    if (typeof SchedulesOriginal[i] !== 'undefined') {

                        if (Frequency === null || Frequency === "" || Frequency === "All" || this.frequencyName(Frequency) === SchedulesOriginal[i].NameOfFrequency.toLowerCase() && SchedulesOriginal[i].Frequency != "single") {
                            if (ScheduleType === null || ScheduleType === "" || ScheduleType === "All" || ScheduleType.toLowerCase() === SchedulesOriginal[i].Frequency.toLowerCase()) {
                                if (Status === null || Status === "" || Status === "All" || Status.toLowerCase() === SchedulesOriginal[i].Status.toLowerCase()) {
                                    var Name = SchedulesOriginal[i].ScheduledDetails.FirstName + " " + SchedulesOriginal[i].ScheduledDetails.LastName;
                                    if (SchedulesOriginal[i].ScheduledDetails.CompanyName && SchedulesOriginal[i].ScheduledDetails.CompanyName.toLowerCase().replace(/\s|_/g, "").includes(searchTerms) || Name && Name.toLowerCase().replace(/\s|_/g, "").includes(searchTerms) || SchedulesOriginal[i].ID.toLowerCase().replace(/\s|_/g, "").includes(searchTerms)) {
                                        next = next + 1;
                                        filteredList[next] = SchedulesOriginal[i];
                                    }
                                }
                            }
                        }
                    }
                }
                this.set("Schedules", filteredList);
            }
        },
        actions: {
            filterStatus: function filterStatus(value) {
                this.set("Status", value);
                this.filterTransactions(this.get("keyword"), this.get("Frequency"), this.get("ScheduleType"), this.get("Status"));
            },
            filterFrequencies: function filterFrequencies(value) {
                this.set("Frequency", value);
                this.filterTransactions(this.get("keyword"), this.get("Frequency"), this.get("ScheduleType"), this.get("Status"));
            },
            filterScheduleTypes: function filterScheduleTypes(value) {
                this.set("ScheduleType", value);
                this.filterTransactions(this.get("keyword"), this.get("Frequency"), this.get("ScheduleType"), this.get("Status"));
            },
            filterKeyword: function filterKeyword(value) {
                this.set("keyword", value);
                this.filterTransactions(this.get("keyword"), this.get("Frequency"), this.get("ScheduleType"), this.get("Status"));
            }
        }
    });
});