define('vopay_app/components/money-transfer', ['exports', 'ember', 'vopay_app/constants', 'vopay_app/utils/entry-filter', 'vopay_app/utils/pooler', 'vopay_app/mixins/error-handler', 'vopay_app/config/environment', 'vopay_app/mixins/manage-cookies'], function (exports, _ember, _vopay_appConstants, _vopay_appUtilsEntryFilter, _vopay_appUtilsPooler, _vopay_appMixinsErrorHandler, _vopay_appConfigEnvironment, _vopay_appMixinsManageCookies) {

  var isProduction = _vopay_appConfigEnvironment['default'].isProduction;

  exports['default'] = _ember['default'].Component.extend(_vopay_appMixinsErrorHandler['default'], _vopay_appMixinsManageCookies['default'], {
    //default state setup
    session: _ember['default'].inject.service('session'),
    api: _ember['default'].inject.service('api'),
    routing: _ember['default'].inject.service('-routing'),
    facebookConversion: false,
    purposes: ["Sending to Self", "Rent or Mortgage", "Family Support", "Family Emergency", "Sending to Friend", "Gift"],
    entryUtil: _vopay_appUtilsEntryFilter['default'].create(),
    poolerUtil: _vopay_appUtilsPooler['default'].create(),
    hasAddress: false,
    resetRecipentInput: "test",
    resetDeliveryInput: "test",
    isShowingTransferSummary: null,
    purpose: null,
    authed: false,
    DeliveryMethodLabel: "Bank Deposit",
    cash: {},
    bank: {},
    DeliveryMethod: "bank",
    defaultSendingAmount: 300,
    isoCountry: 'US',
    sendingAmount: 300,
    countryID: 2,
    fromPersonalDetails: null,
    timer: null,
    fee: _ember['default'].computed('rates', function () {
      var fee = this.get('rates.StandardQuote.TransactionFee');
      return parseFloat(fee);
    }),

    sameamount: true,
    lastAmount: 300,
    maxAmout: _ember['default'].computed('userInfo', function () {
      var limit = this.get('userInfo.RemittanceLimit');
      if (limit) {
        return parseFloat(limit);
      } else {
        return 999;
      }
    }),
    limit: _ember['default'].computed('userInfo', function () {
      var limit = this.get('userInfo.RemittanceLimit');
      if (limit) {
        return parseFloat(limit);
      } else {
        return 999;
      }
    }),
    minAmout: 1,
    arrivalDate: _ember['default'].computed('rates', function () {
      var arrivalDate = this.get('rates.StandardQuote.EstimatedArrivalDate');
      return arrivalDate;
    }),
    totalToPay: _ember['default'].computed('rates', function () {
      var total = this.get('rates.StandardQuote.TotalToPay');
      return parseFloat(total);
    }),

    // Recipient info
    recipientAmount: _ember['default'].computed('rates', function () {
      var total = this.get('rates.StandardQuote.RecipientAmount');
      return parseFloat(total).toLocaleString();
    }),
    discount: null,
    promocode: "",
    recipientToAdd: null,
    recipient: null, // Selected
    recipientAvailable: {}, // Restored from API
    recipientsFiltered: [],
    setupBankInfo: {},

    // Payment info
    paymentToAdd: null,

    // Last Object to send {purpose, recipient, amount, currency ...}
    transaction: {}, //RemittanceRecipientID, BankAccountID, AmountSendCAD, Purpose

    lastCountry: '',
    country: 'United States',
    currency: 'USD',
    exchangeRate: _ember['default'].computed('rates', function () {
      var rates = this.get('rates.StandardQuote.ExchangeRate');
      return parseFloat(rates);
    }),

    paymentMethod: 'Bank',
    selectedPaymentMethod: null,
    selectedDeliveryMethod: null,
    addRecipientFormVisible: false,
    addPaymentMethodFormVisible: false,
    steps: {
      step_one: {
        state: 'incomplete' //can be active / complete / incomplete
      },
      step_two: {
        state: 'incomplete'
      },
      step_three: {
        state: 'incomplete'
      },
      step_four: {
        state: 'incomplete'
      }
    },
    stepOneError: false,
    stepTwoError: false,
    isIndividual: false,
    businessSections: ["Option 1", "Option 2", "Option2", "Option4", "Other"],
    businessTypes: ["Private Corporation", "Public Corporation", "Sole Proprietorship", "Not for Profit", "Government Entity", "Other"],
    countryList: ["Australia", "Bulgaria", "China", "Denmark", "France", "Germany", "Honge Kong", "Hungary", "India", "Italy", "Japan", "New Zealand", "Norway", "Poland", "United Kingdom", "Romania", "Russia", "Singapore", "Spain", "South Africa", "Sweden", "Switzerland", "Thailand", "United Arab Emirates", "United States of America", "Other"],
    currencyList: ["AUD (Australian Dollar)", "BGN (Bulgarian Lev)", "CHF (Swiss Franc)", "CNY (Chinese Yuan)", "DKK (Danish Krone) ", "EUR (EURO)", "GBP (Pound Sterling ) ", "HKD (Hong Kong Dollar) ", "HUF (Hungarian Forint) ", "INR (Indian Rupee) ", "JPY (Japanese Yen) ", "NOK (Norwegian Krone)", "NZD (New Zealand Dollar)", "PLN (Polish Zloty) ", "RON (Romanian New Leu)", "RUB (Russian Rouble) ", "SEK (Swedish Krona) ", "SGD (Singapore Dollar)", "THB (Thai Baht)", "USD (United States Dollar)", "ZAR (South African Rand)", "Other"],
    directorNumbers: ["1"],
    directorCurrNum: 1,
    ownerNumbers: ["1"],
    ownerCurrNum: 1,
    currencyNumbers: ["1"],
    currencyCurrNum: 1,
    countryNumbers: ["1"],
    countryCurrNum: 1,
    ownerTypeSelected: false,
    ownerTypes: ["Individual", "Corperate"],
    // Entry Data checker
    watchFirstName: _ember['default'].observer('firstname', function () {

      this.set('firstname', this.get('entryUtil').onlyCharacters(this.get('firstname')));
    }),

    watchLastName: _ember['default'].observer('lastname', function () {

      this.set('lastname', this.get('entryUtil').onlyCharacters(this.get('lastname')));
    }),
    watchCity: _ember['default'].observer('city', function () {

      this.set('city', this.get('entryUtil').onlyCharacters(this.get('city')));
    }),

    watchProvince: _ember['default'].observer('province', function () {

      this.set('province', this.get('entryUtil').onlyCharacters(this.get('province')));
    }),

    setDefaultCookies: function setDefaultCookies() {
      localStorage.setItem('timeStored', this.getCookie("timeStored"));
      if (this.getCookie('senderAmount')) {
        this.set("defaultSendingAmount", this.getCookie('senderAmount'));
        this.set("sendingAmount", this.getCookie('senderAmount'));
      }
      if (this.getCookie('recipientCurrency')) {
        this.set("currency", this.getCookie('recipientCurrency'));
      }
      if (this.getCookie('recipientCountry')) {
        this.set("country", this.getCookie('recipientCountry'));
      }
    },
    setDefaultLocalStorage: function setDefaultLocalStorage() {
      if (localStorage.recipientID) {
        this.set('recipient', localStorage.recipientName);
        this.set('transaction.RemittanceRecipientID', localStorage.recipientID);
        this.set("country", localStorage.recipientCountry);
        this.set("currency", localStorage.recipientCurrency);
        this.set("DeliveryMethod", localStorage.recipientDeliveryMethod);
        if (this.get("DeliveryMethod") === "cash") {
          this.set("DeliveryMethodLabel", "Cash Pick-Up");
        } else {}

        localStorage.removeItem("recipientID");
        localStorage.removeItem("recipientCountry");
        localStorage.removeItem("recipientCurrency");
        localStorage.removeItem("recipientName");
        localStorage.removeItem("recipientDeliveryMethod");
      }
    },
    startTimer: function startTimer() {
      window.iterations = 0;
      this.set("timer", setInterval(function () {
        if (window.iterations === 60) {
          _ember['default'].$("#stepFiveBtn").hide();
          _ember['default'].$("#resubmit").show();
        } else {
          if (window.iterations < 60) {
            window.iterations = window.iterations + 1;
            _ember['default'].$("#quoteRefresh").html(60 - window.iterations);
          } else {
            _ember['default'].$("#quoteRefresh").html("0");
          }
        }
      }, 1000));
    },
    resetTimer: function resetTimer() {
      window.iterations = 0;
      clearInterval(this.get("timer"));
      this.set("timer", setInterval(function () {
        if (window.iterations === 60) {
          _ember['default'].$("#stepFiveBtn").hide();
          _ember['default'].$("#resubmit").show();
        } else {
          if (window.iterations < 60) {
            window.iterations = window.iterations + 1;
            _ember['default'].$("#quoteRefresh").html(60 - window.iterations);
          } else {
            _ember['default'].$("#quoteRefresh").html("0");
          }
        }
      }, 1000));
    },
    init: function init() {

      this._super.apply(this, arguments);

      if (window.facebookConversion) {
        this.set("facebookConversion", true);
        window.facebookConversion = false;
      }

      this.set('hasAddress', !!this.get('userInfo.Country'));

      this.set('steps.step_one.state', 'active');
      if (isProduction) {
        if (window.__adroll_loaded) {
          try {
            window.__adroll.record_user({ "adroll_segments": "72b68b7d" });
          } catch (err) {}
        }
      }

      //this.filterRecipients(this.get("DeliveryMethod"));

      this.set('steps.step_two.state', 'incomplete');
      this.set('steps.step_three.state', 'incomplete');
      this.set('steps.step_four.state', 'incomplete');
      this.set('transaction.FeeType', 'Standard');

      //check if local storage is available
      if (typeof Storage !== "undefined") {
        //set values from local storage and clear local storge
        if (this.getCookie("timeStored")) {
          if (localStorage.getItem('timeStored')) {
            if (localStorage.getItem('timeStored') !== this.getCookie("timeStored")) {
              this.setDefaultCookies();
            } else {
              this.setDefaultLocalStorage();
            }
          } else {
            this.setDefaultCookies();
          }
        } else {
          this.setDefaultLocalStorage();
        }
      }
    },

    didInsertElement: function didInsertElement() {

      _ember['default'].$('form').parsley({
        successClass: " ",
        errorClass: "form__input--error",
        errorsWrapper: "<p></p>",
        errorTemplate: "<span class='form__message'></span>"
      });

      this.initialiseAutoAddress();
      // Prepare IBAN dependency to apply rules on recipient fields
      this.updateBankInfo(this.get('country'));

      _ember['default'].$("input").keyup(function () {
        _ember['default'].$(this).addClass("form__input--active");
      });
      _ember['default'].$("input").focusout(function () {
        _ember['default'].$(this).removeClass("form__input--active");
      });

      _ember['default'].$("#street-address").focusout(function () {
        setTimeout(function () {
          var city = _ember['default'].$("#city").val();
          var state = _ember['default'].$("#state").val();
          var postcode = _ember['default'].$("#postcode").val();
          if (city) {
            _ember['default'].$("#city").parsley().reset();
          }
          if (state) {
            _ember['default'].$("#state").parsley().reset();
          }
          if (postcode) {
            _ember['default'].$("#postcode").parsley().reset();
          }
        }, 500);
      });

      _ember['default'].$("#postcode").focusout(function () {
        setTimeout(function () {
          var city = _ember['default'].$("#city").val();
          var state = _ember['default'].$("#state").val();
          var streetAddress = _ember['default'].$("#street-address").val();
          if (city) {
            _ember['default'].$("#city").parsley().reset();
          }
          if (state) {
            _ember['default'].$("#state").parsley().reset();
          }
          if (streetAddress) {
            _ember['default'].$("#street-address").parsley().reset();
          }
        }, 500);
      });
    },

    didRender: function didRender() {
      //hide and show transfer summary
      _ember['default'].$("html input").focusin(function () {

        if (_ember['default'].$("html").width() <= 600) {

          _ember['default'].$("#transferSummary").hide();
        }
      });
      _ember['default'].$("html input").focusout(function () {

        _ember['default'].$("#transferSummary").show();
      });
    },

    /***
     * filterRecipients
     *
     * Filter Recipients by Country and Currency
     */
    filterRecipients: function filterRecipients(type) {
      var _this = this;

      if (!type) {
        type = "bank";
      }

      var ct = this.get('countries').filter(function (fl) {
        return fl.Country.toUpperCase() === _this.get('country').toUpperCase();
      });

      var arrUserRecipients = [].concat(this.get('userRecipients')).filter(function (f) {
        var jsonBank = JSON.parse(f.BankAccount);
        return f.Currency === _this.get('currency') && jsonBank['bank_country'] === ct[0].Abbreviation && f.DeliveryMethod === type;
      });

      this.set('recipientsFiltered', arrUserRecipients);
    },

    /***
     * getUrlParameter
     *
     * Get the parameters from the URL
     */
    getUrlParameter: function getUrlParameter(sParam) {
      var sPageURL = decodeURIComponent(window.location.hash.substring(11)),
          sURLVariables = sPageURL.split('&'),
          sParameterName = undefined,
          i = undefined;

      for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
          return sParameterName[1] === undefined ? true : sParameterName[1];
        }
      }
    },

    /***
     * initialiseAutoAddress
     *
     * Initialises the Canada Post address library
     */
    initialiseAutoAddress: function initialiseAutoAddress() {
      /** *
      let fields = [{
       element: "street-address",
       field: "Line1"
      }, {
       element: "street-address2",
       field: "Line2",
       mode: pca.fieldMode.POPULATE
      }, {
       element: "city",
       field: "City",
       mode: pca.fieldMode.POPULATE
      }, {
       element: "state",
       field: "ProvinceName",
       mode: pca.fieldMode.POPULATE
      }, {
       element: "postcode",
       field: "PostalCode"
      }, {
       element: "country",
       field: "CountryName",
       mode: pca.fieldMode.COUNTRY
      }],
      options = {
       key: "UN11-KD36-HG19-PD82"
      },
       control = new pca.Address(fields, options); // jshint ignore:line
       **/
      window.initializeLocationIQ("beneficiary-address", "beneficiary-address2", "Country", "beneficiary-state", "beneficiary-city", "beneficiary-postcode", this.get("isoCountry"), "Address1");
    },

    /***
     * updateBankInfo
     *
     * Initialises Bank validation info
     */
    updateBankInfo: function updateBankInfo(country) {

      var isoCountry = this.getIsoCountry(country);
      var iban = {};

      // Configure each field separated
      if (this.get('recipientAvailable').hasOwnProperty('Iban')) {
        for (var key in IBAN.countries) {
          if (isoCountry === IBAN.countries[key].countryCode) {
            // iban = Object.assign({}, IBAN.countries[key]);
            iban = _ember['default'].$.extend({}, IBAN.countries[key]);
            iban.example = iban.example.substring(2, IBAN.countries[key].example.length);
            iban.charmax = IBAN.countries[key].length - 2;
          }
        }
        this.set('recipientAvailable.IbanRules', iban);
      }
    },

    /***
     * addRecipient
     * @param formObj (Array) - serialized form
     * @param country (string) - Name of the country
     * @param currency (number) - selected currency
     *
     * Returns a recipient serialized object to send
     */
    addRecipient: function addRecipient(formObj, country, currency) {
      var countries = _vopay_appConstants['default'].countries;
      var recipient = {};

      formObj.map(function (d) {
        recipient[d['name']] = d['value'];
      });

      recipient.country = this.getIsoCountry(country);
      recipient.currency = currency;

      if (recipient.Iban) {

        // restore country into iban number
        recipient.Iban = '' + this.getIsoCountry(country) + recipient.Iban;
      }
      recipient.accountHolderName = recipient.accountHolderName.trim();
      if (recipient.country === "CN") {
        recipient.BeneficiaryFirstName = recipient.accountHolderName;
        recipient.BeneficiaryLastName = " ";
      } else {
        var arrNames = recipient.accountHolderName.split(' ');
        recipient.BeneficiaryFirstName = arrNames.shift();
        recipient.BeneficiaryLastName = arrNames.pop();
      }
      for (var code in countries) {
        var country2 = countries[code];
        if (country2.code === recipient.BeneficiaryCountry) {
          recipient.BeneficiaryPhoneNumberCountry = country2.countryCode;
        }
      }

      return recipient;
    },
    getIsoCountry: function getIsoCountry(name) {
      var ctr = this.get('countries').filter(function (f) {
        return f.Country.toUpperCase() === name.toUpperCase();
      }).map(function (c) {
        return c.Abbreviation;
      });
      return ctr[0];
    },

    /***
     * addPaymentMethod
     * @param formObj (Array) - serialized form
     *
     * Returns a payment method serialized object to send
     */
    addPaymentMethod: function addPaymentMethod(formObj) {
      var arrLength = formObj.length;
      var payment = {};

      for (var i = 0; i < arrLength; i++) {
        payment[formObj[i].name] = formObj[i].value;
      }

      if (payment.IsDefault === "on") {
        payment.IsDefault = true;
      } else {
        payment.IsDefault = false;
      }

      if (this.get('paymentMethod') === 'CreditCard') {
        payment.type = 'creditcard';
      } else if (this.get('paymentMethod') === 'Bank') {
        payment.type = 'bankaccount';
      }

      return payment;
    },

    /***
     * validateForm
     * @param formId (string) - Form selector #Id
     *
     * Validate form returning true | false (valid or not)
     */
    validateForm: function validateForm(formId) {

      var form = _ember['default'].$(formId);

      form.parsley({
        excluded: 'input[type=button], input[type=submit], input[type=reset]',
        inputs: 'input, textarea, select, input[type=hidden], :hidden'
      }).validate();

      return form.parsley().isValid();
    },

    /***
     * listAvailabeRecipients
     *
     * list Available rules for create recipient
     */
    listAvailabeRecipients: function listAvailabeRecipients() {
      var cts = this.get('countries');
      var self = this;
      var isoCode = cts.filter(function (f) {
        return f.Country.toUpperCase() === self.get('country').toUpperCase();
      }).map(function (c) {
        return c.Abbreviation;
      });
      var DeliveryMethods = cts.filter(function (f) {
        return f.Country.toUpperCase() === self.get('country').toUpperCase();
      }).map(function (c) {
        return c.DeliveryMethods;
      });

      var params = {
        currency: this.get('currency'),
        bankCountry: isoCode[0],
        benefCountry: isoCode[0]

      };

      // Reset recipients rules
      this.set('recipientAvailable', {});
      this.set('recipientAvailableCash', {});
      var methods = DeliveryMethods[0];
      this.set("DeliveryMethods", methods);
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        //methods
        for (var property in methods) {
          if (methods.hasOwnProperty(property)) {
            var type = methods[property];
            self.set('' + type, {});

            // self.get('api').availableRecipients(params, type).then(
            //   data => {

            //     // let obj = data.List[0];
            //     // for (let key in obj) {

            //     //   self.set(`${type}.${key}`, obj[key]);

            //     // }
            //   },
            //   error => {
            //     self.handleErrors(null, error);
            //     reject(error);
            //   }
            //   );
          }
        }

        self.get('api').availableRecipients(params, self.get("DeliveryMethod")).then(function (data) {

          var obj = data.List[0];
          for (var key in obj) {
            self.set('recipientAvailable.' + key, obj[key]);
            resolve();
          }
        }, function (error) {

          self.handleErrors(null, error);
          reject(error);
        });
      });
    },

    _finalizeTransaction: function _finalizeTransaction(data) {

      if (data.error) {

        this.handleErrors(null, data.error);
      } else {

        _ember['default'].$('#StepFourBtn').removeAttr('disabled');
        this.get('routing').transitionTo('dashboard.confirmation', [data.result.RemittanceTransactionID]);
      }
    },

    _addSwiftToRecipient: function _addSwiftToRecipient(recipient) {
      var _this2 = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {

        _this2.get('api').ibanChecker(recipient.Iban).then(function (data) {

          recipient.BicSwift = data['bank_data'].bic;
          resolve(recipient);
        }, function (error) {

          reject(error);
        });
      });
    },

    actions: {

      /***
       * afterSelectPurposes
       *
       * Set purpose to send to the transaction
       */
      afterSelectPurposes: function afterSelectPurposes(purpose) {
        _ember['default'].$("#purpose").parsley().reset();
        this.set('purpose', purpose);
        this.set('transaction.Purpose', purpose);
      },

      toggleModal: function toggleModal() {
        this.toggleProperty('isShowingModal');
      },
      updateProductServices: function updateProductServices() {

        //serialize the form and get the values
        //let primaryContactData = Ember.$('#productServicesForm').serializeArray();

        //validate here, if all good proceed

        _ember['default'].$("#productServices").slideToggle("slow");
        _ember['default'].$("#primaryContact").slideToggle("slow");
        _ember['default'].$('html,body').scrollTop(0);
      },
      updatePrimaryConact: function updatePrimaryConact() {

        //serialize the form and get the values
        //let primaryContactData = Ember.$('#primaryContactForm').serializeArray();

        //validate here, if all good proceed

        _ember['default'].$("#primaryContact").slideToggle("slow");
        _ember['default'].$("#signingAuthority").slideToggle("slow");
        _ember['default'].$('html,body').scrollTop(0);
      },
      updateSigner: function updateSigner() {

        //serialize the form and get the values
        //let authorityData = Ember.$('#signAuthorityForm').serializeArray();

        //validate here, if all good proceed

        _ember['default'].$("#signingAuthority").slideToggle("slow");
        _ember['default'].$("#directors").slideToggle("slow");
        _ember['default'].$('html,body').scrollTop(0);
      },
      updateDirectors: function updateDirectors() {

        //serialize the form and get the values
        //let directorData = Ember.$('#directorsForm').serializeArray();

        //validate here, if all good proceed

        _ember['default'].$("#directors").slideToggle("slow");
        _ember['default'].$("#fileUpload").slideToggle("slow");
        _ember['default'].$('html,body').scrollTop(0);
      },
      uploadFiles: function uploadFiles() {
        _ember['default'].$("#overlay").show();
        _ember['default'].$("#termsConditions").show();
      },
      AgreeToTerms: function AgreeToTerms() {
        _ember['default'].$("#termsConditions").hide();
        _ember['default'].$("#riskDisclosureSatment").show();
      },
      backTerms: function backTerms() {
        _ember['default'].$("#overlay").hide();
        _ember['default'].$("#termsConditions").hide();
      },
      AgreeToRisk: function AgreeToRisk() {
        _ember['default'].$("#clientApplicationAuthorization").show();
        _ember['default'].$("#riskDisclosureSatment").hide();
      },
      backRisk: function backRisk() {
        _ember['default'].$("#termsConditions").show();
        _ember['default'].$("#riskDisclosureSatment").hide();
      },
      AgreeToAgreeToAuthoization: function AgreeToAgreeToAuthoization() {
        _ember['default'].$("#clientApplicationAuthorization").hide();
        _ember['default'].$("#fileUpload").hide();
        _ember['default'].$(".active2").hide();
        _ember['default'].$("#overlay").hide();
        _ember['default'].$("#email").show();
        _ember['default'].$('html,body').scrollTop(0);
      },
      backAuthorization: function backAuthorization() {
        _ember['default'].$("#clientApplicationAuthorization").hide();
        _ember['default'].$("#riskDisclosureSatment").show();
      },

      /***
       * update
       *
       * Updates the user account by adding the address
       */
      update: function update() {

        //serialize the form and get the values
        //let signupData = Ember.$('#signupForm').serializeArray();

        //validate here, if all good proceed

        _ember['default'].$('#signupBtn').removeAttr('disabled');
        _ember['default'].$("#bussinessRegistration").slideToggle("slow");
        _ember['default'].$("#productServices").slideToggle("slow");
        _ember['default'].$('html,body').scrollTop(0);
      },
      businessTypeSelected: function businessTypeSelected(value) {
        if (value === "Other") {
          _ember['default'].$("#businessType").val("");
          _ember['default'].$("#businessType").attr('type', 'text');
          _ember['default'].$("#businessType").focus();
          _ember['default'].$("#businessTypeDd").hide();
        } else {
          _ember['default'].$("#businessType").val(value);
          _ember['default'].$("#businessType").parsley().validate();
        }
      },
      businessSectorSelected: function businessSectorSelected(value) {
        if (value === "Other") {
          _ember['default'].$("#businessSector").val("");
          _ember['default'].$("#businessSector").attr('type', 'text');
          _ember['default'].$("#businessSector").focus();
          _ember['default'].$("#businessSectorDd").hide();
        } else {
          _ember['default'].$("#businessSector").val(value);
          _ember['default'].$("#businessSector").parsley().validate();
        }
      },
      currencySelected: function currencySelected(value) {},
      countrySelected: function countrySelected(value) {},
      ownerTypeSelected: function ownerTypeSelected(value) {
        _ember['default'].$("#ownerType").val(value);
        _ember['default'].$("#ownerType").parsley().validate();

        this.set('ownerTypeSelected', true);
        if (value === "Individual") {
          this.set('isIndividual', true);
        } else {
          this.set('isIndividual', false);
        }
      },
      addDirector: function addDirector() {
        this.set("directorCurrNum", this.get("directorCurrNum") + 1);
        this.get('directorNumbers').pushObject(this.get("directorCurrNum"));
      },
      removeDirector: function removeDirector() {
        this.set('directorNumbers', this.get('directorNumbers').slice(0, -1));
        this.set("directorCurrNum", this.get("directorCurrNum") - 1);
      },
      addOwner: function addOwner() {
        this.set("ownerCurrNum", this.get("ownerCurrNum") + 1);
        this.get('ownerNumbers').pushObject(this.get("ownerCurrNum"));
      },
      removeOwner: function removeOwner() {
        this.set('ownerNumbers', this.get('ownerNumbers').slice(0, -1));
        this.set("ownerCurrNum", this.get("ownerCurrNum") - 1);
      },
      addCountry: function addCountry() {
        this.set("countryCurrNum", this.get("countryCurrNum") + 1);
        this.get('countryNumbers').pushObject(this.get("countryCurrNum"));
      },
      removeCountry: function removeCountry() {
        this.set('countryNumbers', this.get('countryNumbers').slice(0, -1));
        this.set("countryCurrNum", this.get("countryCurrNum") - 1);
      },
      addCurrency: function addCurrency() {
        this.set("currencyCurrNum", this.get("currencyCurrNum") + 1);
        this.get('currencyNumbers').pushObject(this.get("currencyCurrNum"));
      },
      removeCurrency: function removeCurrency() {
        this.set('currencyNumbers', this.get('currencyNumbers').slice(0, -1));
        this.set("currencyCurrNum", this.get("currencyCurrNum") - 1);
      },
      editSignUp: function editSignUp(step, currentStep) {
        if (step === "step_one" || currentStep === "step_one") {
          _ember['default'].$("#bussinessRegistration").slideToggle("slow");
        }
        if (step === "step_two" || currentStep === "step_two") {
          _ember['default'].$("#productServices").slideToggle("slow");
        }
        if (step === "step_three" || currentStep === "step_three") {
          _ember['default'].$("#primaryContact").slideToggle("slow");
        }
        if (step === "step_four" || currentStep === "step_four") {
          _ember['default'].$("#signingAuthority").slideToggle("slow");
        }
        if (step === "step_five" || currentStep === "step_five") {
          _ember['default'].$("#directors").slideToggle("slow");
        }
        if (step === "step_six" || currentStep === "step_six") {
          _ember['default'].$("#fileUpload").slideToggle("slow");
        }
        _ember['default'].$('html,body').scrollTop(0);
      },
      /***
       * edit
       * @param step (String) - Step to edit
       *
       * The step that we want to edit
       */
      edit: function edit(step, currentStep) {
        //set the various step states dependent on status
        if (step === "step_one") {
          this.set("resetRecipentInput", null);
          this.set("resetDeliveryInput", null);
          this.set("isShowingTransferSummary", null);
          if (currentStep === "step_four") {
            _ember['default'].$("#section3").slideToggle("slow");
            _ember['default'].$(".active2").slideToggle("slow");
            _ember['default'].$('html,body').scrollTop(0);
          } else {
            _ember['default'].$("#stepFour").slideUp("slow");
            _ember['default'].$("#section2").slideUp("slow");
            _ember['default'].$(".active2").slideToggle("slow");
            _ember['default'].$('html,body').scrollTop(0);
          }
          this.set("addPaymentMethodFormVisible", null);
          this.set("addRecipientFormVisible", null);
        } else if (step === "step_two") {
          this.set("resetDeliveryInput", null);
          _ember['default'].$("#section3").slideUp("slow");
          _ember['default'].$("#stepFive").slideUp("slow");
          _ember['default'].$("#stepTwo").slideToggle("slow");
          _ember['default'].$("#stepFour").show();
          _ember['default'].$("#stepFive").hide();
          _ember['default'].$('html,body').scrollTop(0);
          this.set("addPaymentMethodFormVisible", null);
          this.set("resetDeliveryInput", null);
        } else if (step === "step_four" && currentStep === "step_three") {
          _ember['default'].$("#stepFour").slideToggle("slow");
          _ember['default'].$("#stepFive").slideToggle("slow");
          _ember['default'].$('html,body').scrollTop(0);
        }
      },

      /***
       * completeStepOne
       *
       * Triggered when the user attempts to complete step one
       */
      completeStepOne: function completeStepOne() {
        var _this3 = this;

        if (this.get("country") === "Philippines") {
          this.set("DeliveryMethod", null);
        }

        var valid = false;
        var min = this.get('minAmout');
        var sendAmount = this.get('sendingAmount');

        if (sendAmount !== '' && sendAmount >= min) {

          valid = true;
          this.set('stepOneError', false);
        } else {

          this.set('stepOneError', true);
        }

        if (valid) {
          this.filterRecipients(this.get("DeliveryMethod"));
          this.set("resetRecipentInput", "test");

          // Avoid call API every time that user return to step one and doesn't change the country.
          if (this.get('lastCountry') !== this.get('country')) {

            _ember['default'].$('#stepOneBtn').attr('disabled', 'disabled');
            this.listAvailabeRecipients().then(function () {
              window.ga('send', 'pageview', '/funnel-S1');
              if (isProduction) {
                if (window.__adroll_loaded) {
                  try {
                    window.__adroll.record_user({ "adroll_segments": "e492a225" });
                  } catch (err) {}
                }
              }
              _this3.set("fromPersonalDetails", null);
              _ember['default'].$('#stepOneBtn').removeAttr('disabled');
              _ember['default'].$("#stepTwo").show();
              _ember['default'].$(".active2").slideToggle("slow");
              _ember['default'].$("#section2").slideToggle("slow");
              _this3.set("isShowingTransferSummary", "true");
              _ember['default'].$('html,body').scrollTop(0);
              _this3.updateBankInfo(_this3.get('country'));
            });

            this.set('lastCountry', this.get('country'));
          } else {
            window.ga('send', 'pageview', '/funnel-S1');
            if (isProduction) {
              if (window.__adroll_loaded) {
                try {
                  window.__adroll.record_user({ "adroll_segments": "e492a225" });
                } catch (err) {}
              }
            }
            this.set("fromPersonalDetails", false);
            _ember['default'].$('#stepOneBtn').removeAttr('disabled');
            _ember['default'].$("#stepTwo").show();
            _ember['default'].$(".active2").slideToggle("slow");
            _ember['default'].$("#section2").slideToggle("slow");
            this.set("isShowingTransferSummary", "true");
            _ember['default'].$('html,body').scrollTop(0);
          }

          this.set('transaction.AmountSendCAD', this.get('sendingAmount'));
        }
      },

      /***
       * completeStepTwo
       *
       * Triggered when the user attempts to complete step two
       */
      completeStepTwo: function completeStepTwo() {
        var _this4 = this;

        if (this.validateForm('#recipientForm')) {
          (function () {
            _this4.set("resetDeliveryInput", "test");
            _ember['default'].$('#stepTwoBtn').attr('disabled', 'disabled');
            var self = _this4;
            var country = _this4.get('country');
            var currency = _this4.get('currency');

            // Add NEW recipient if form active
            if (_this4.get('addRecipientFormVisible')) {
              (function () {

                var recipientFormArr = _ember['default'].$('#recipientForm').serializeArray();

                _this4.set('recipientToAdd', _this4.addRecipient(recipientFormArr, country, currency));
                _this4.set('recipient', _this4.get('recipientToAdd.accountHolderName'));

                // let recipientAdd = Object.assign({}, this.get('recipientToAdd'));
                var recipientAdd = _ember['default'].$.extend({}, _this4.get('recipientToAdd'));

                var kissmetrics = _ember['default'].$.extend({}, _this4.get('recipientToAdd'));

                if (recipientAdd.hasOwnProperty('BicSwift')) {
                  if (country === "Singapore") {
                    recipientAdd.BicSwift = recipientAdd.BicSwiftShort;
                    _this4.get('api').addRecipient(recipientAdd).then(function (data) {
                      window.ga('send', 'pageview', '/funnel-S2');
                      if (isProduction) {
                        if (window.__adroll_loaded) {
                          try {
                            window.__adroll.record_user({ "adroll_segments": "f90fb137" });
                          } catch (err) {}
                        }
                      }
                      self.set('transaction.RemittanceRecipientID', data.RemittanceRecipientID);
                      _ember['default'].$("#stepTwo").slideToggle("slow");
                      if (_this4.get('userInfo.Country')) {
                        _ember['default'].$("#stepFour").slideToggle("slow");
                      } else {
                        _ember['default'].$("#stepFive").slideToggle("slow");
                      }
                      _ember['default'].$('html,body').scrollTop(0);
                      _ember['default'].$('#stepTwoBtn').removeAttr('disabled');
                    }, function (err) {

                      _this4.handleErrors(recipientFormArr, err);
                      _ember['default'].$('#stepTwoBtn').removeAttr('disabled');
                    });
                  } else {
                    // Run promise IBAN to retrieve SWIFT code
                    _this4._addSwiftToRecipient(recipientAdd).then(function (rec) {

                      // Run promise AddRecipient
                      _this4.get('api').addRecipient(rec).then(function (data) {
                        window.ga('send', 'pageview', '/funnel-S2');
                        if (isProduction) {
                          if (window.__adroll_loaded) {
                            try {
                              window.__adroll.record_user({ "adroll_segments": "f90fb137" });
                            } catch (err) {}
                          }
                        }
                        self.set('transaction.RemittanceRecipientID', data.RemittanceRecipientID);
                        _ember['default'].$("#stepTwo").slideToggle("slow");
                        _ember['default'].$("#section3").slideToggle("slow");
                        if (_this4.get('userInfo.Country')) {
                          _ember['default'].$("#stepFour").show();
                        } else {
                          _ember['default'].$("#stepFive").show();
                        }
                        _ember['default'].$('#stepTwoBtn').removeAttr('disabled');
                        _ember['default'].$('html,body').scrollTop(0);
                      }, function (err) {

                        _ember['default'].$('#stepTwoBtn').removeAttr('disabled');
                        _this4.handleErrors(recipientFormArr, err);
                      });
                    }, function (err) {

                      _this4.handleErrors(recipientFormArr, err);
                    });
                  }
                } else {

                  // Run promise AddRecipient
                  _this4.get('api').addRecipient(recipientAdd).then(function (data) {
                    window.ga('send', 'pageview', '/funnel-S2');
                    if (isProduction) {
                      if (window.__adroll_loaded) {
                        try {
                          window.__adroll.record_user({ "adroll_segments": "f90fb137" });
                        } catch (err) {}
                      }
                    }
                    self.set('transaction.RemittanceRecipientID', data.RemittanceRecipientID);
                    _ember['default'].$("#stepTwo").slideToggle("slow");
                    _ember['default'].$("#section3").slideToggle("slow");
                    if (_this4.get('userInfo.Country')) {
                      _ember['default'].$("#stepFour").show();
                    } else {
                      _ember['default'].$("#stepFive").show();
                    }
                    _ember['default'].$('html,body').scrollTop(0);
                    _ember['default'].$('#stepTwoBtn').removeAttr('disabled');
                  }, function (err) {

                    _this4.handleErrors(recipientFormArr, err);
                    _ember['default'].$('#stepTwoBtn').removeAttr('disabled');
                  });
                }

                if (kissmetrics.Iban) {
                  delete kissmetrics.Iban;
                }
                if (kissmetrics.AccountNumber) {
                  delete kissmetrics.AccountNumber;
                }
                kissmetrics.country = _this4.get("country");
                kissmetrics.RecipientID = _this4.get('transaction.RemittanceRecipientID');
              })();
            } else {

              window.ga('send', 'pageview', '/funnel-S2');
              if (isProduction) {
                if (window.__adroll_loaded) {
                  try {
                    window.__adroll.record_user({ "adroll_segments": "f90fb137" });
                  } catch (err) {}
                }
              }
              _ember['default'].$("#stepTwo").slideToggle("slow");
              _ember['default'].$("#section3").slideToggle("slow");
              _ember['default'].$("#stepFour").show();
              _ember['default'].$('#stepTwoBtn').removeAttr('disabled');
              _ember['default'].$('html,body').scrollTop(0);
            }

            _this4.set('stepTwoError', false);
          })();
        }
      },

      /***
       * completeStepFour
       *
       * Triggered when the user attempts to complete step four
       */
      completeStepFour: function completeStepFour() {
        var _this5 = this;

        var self = this;
        var paymentFormArr = _ember['default'].$('#addPayment').serializeArray();

        // Validate if payment selected
        if (this.validateForm('#paymentForm')) {
          // Send NEW payment to API
          if (this.get('addPaymentMethodFormVisible')) {
            if (this.validateForm('#addPayment')) {
              // Add payment

              if (this.get('paymentMethod') === 'CreditCard' || this.get('paymentMethod') === 'Bank') {

                this.set('paymentToAdd', this.addPaymentMethod(paymentFormArr));
              }
            }
          } else {
            this.set('transaction.PaymentMethod', this.get('paymentMethod'));
          }
          _ember['default'].$('#stepFourBtn').attr('disabled', 'disabled');
          this.set('transaction.PromoCode', this.get("promocode"));
          // Send transaction
          // If it is a new payment, save payment after send transaction
          var ckd = this.get('sameamount');
          if (ckd) {
            this.set("transaction.FeeType", "Standard");
          }

          if (this.get('paymentToAdd')) {

            this.get('api').addPaymentMethod(this.get('paymentToAdd')).then(function (bank) {
              // Resolve payment method

              if (bank.hasOwnProperty('ID')) {
                _this5.set('transaction.BankAccountID', bank['ID']);
              }
              if (_this5.get("paymentToAdd.IsDefault")) {
                _this5.get('api').setDefault(self.get('transaction.BankAccountID')).then(function () {}, function (err) {
                  _this5.handleErrors(paymentFormArr, err);
                });
              }

              window.ga('send', 'pageview', '/funnel-S3');

              /*
                            */
              _ember['default'].$("#stepFour").slideToggle("slow");
              _ember['default'].$("#stepFive").slideToggle("slow");
              _ember['default'].$('#stepFourBtn').removeAttr('disabled');
              _ember['default'].$('html,body').scrollTop(0);
            }, function (err) {
              // Error Payment Method

              _this5.handleErrors(paymentFormArr, err);
            });
          } else {
            _ember['default'].$("#stepFour").slideToggle("slow");
            _ember['default'].$("#stepFive").slideToggle("slow");
            _ember['default'].$('#stepFourBtn').removeAttr('disabled');
            _ember['default'].$('html,body').scrollTop(0);
            window.ga('send', 'pageview', '/funnel-S3');
            /*
            window.ga('send', 'pageview', '/funnel-S3');
            this.get('api').performTransaction(self.get('transaction')).then(
              trans => {
                let kissmetrics = Ember.$.extend({}, this.get('paymentToAdd'), this.get('transaction'));
                if(kissmetrics.AccountNumber){
                  delete kissmetrics.AccountNumber;
                }
                
                  
                window.ga('send', 'pageview', '/funnel-S4');
                if(isProduction){
                  if(window.__adroll_loaded){
                    try{
                      window.__adroll.record_user({"adroll_segments": "2d679be1"});   
                    } catch(err) {}
                  }
                }
                Ember.$("#conversion").html('<script src="https://cdn.ywxi.net/js/conversion.js" class="mcafeesecure-track-conversion" data-type="purchase" data-orderid="'+trans.result.RemittanceTransactionID+'" data-email="'+this.get('userInfo.Email')+'" data-firstname="'+this.get('userInfo.LastName')+'" data-lastname="'+this.get('userInfo.FirstName')+'" data-country="CA"></script>');
                this._finalizeTransaction(trans);
              },
              err => {
                  this.handleErrors(paymentFormArr, err);
              }
              );
            */
          }
          this.startTimer();
        }
      },
      /***
       * completeStepFour
       *
       * Triggered when the user attempts to complete step four
       */
      completeStepFive: function completeStepFive() {
        var _this6 = this;

        var self = this;
        this.set('transaction.AmountSendCAD', this.get('sendingAmount'));
        this.get('api').performTransaction(self.get('transaction')).then(function (trans) {
          // Resolve Transaction
          window.ga('send', 'pageview', '/funnel-S4');
          if (isProduction) {
            if (window.__adroll_loaded) {
              try {
                window.__adroll.record_user({ "adroll_segments": "2d679be1" });
              } catch (err) {}
            }
          }
          _ember['default'].$("#conversion").html('<script src="https://cdn.ywxi.net/js/conversion.js" class="mcafeesecure-track-conversion" data-type="purchase" data-orderid="' + trans.result.RemittanceTransactionID + '" data-email="' + _this6.get('userInfo.Email') + '" data-firstname="' + _this6.get('userInfo.LastName') + '" data-lastname="' + _this6.get('userInfo.FirstName') + '" data-country="CA"></script>');
          _this6._finalizeTransaction(trans);
        }, function () {// Error Transaction

        });
      },

      /***
       * senderAmountChanged
       * @param amount (Number) - Selected amount
       *
       * TODO - Refactor this method
       */
      senderAmountChanged: function senderAmountChanged(amount) {

        var self = this;
        var pool = this.get('poolerUtil');
        this.set('sendingAmount', amount);
        //Ember.$("#senderAmountForm").parsley().reset()

        pool.setInterval(500);
        pool.stop();
        pool.start(this, function () {
          var _this7 = this;

          // Get fee based amount send TODO - Component is not place to call data (it should come from Model - Ember Data)
          var getQuotes = new _ember['default'].RSVP.Promise(function (resolve, reject) {

            pool.stop();
            return _this7.get('api').getQuotes({
              SendingAmount: amount,
              SendingCurrency: 'CAD',
              RecipientCurrency: self.get('currency'),
              DeliveryMethod: _this7.get("DeliveryMethod")
            }).then(function (quote) {

              resolve(quote);
            }, function (err) {

              reject(err);
            });
          });
          _ember['default'].$("#currencyConverterLoader").show();
          getQuotes.then(function (quote) {

            var fee = quote.StandardQuote.TransactionFee;
            var exchangeRate = quote.StandardQuote.ExchangeRate;
            var arrivalDate = quote.StandardQuote.EstimatedArrivalDate;
            var totalPay = 0;
            var ckd = _this7.get('sameamount');
            _this7.set('arrivalDate', arrivalDate);

            _this7.set('fee', fee);

            if (_this7.get('exchangeRate') !== exchangeRate) {

              _this7.set('exchangeRate', exchangeRate);
            }

            // If include fee checked
            if (ckd) {

              if (amount && amount >= fee) {

                var amountNumber = amount !== amount ? 0 : parseFloat(amount);
                var difference = 0;

                amountNumber = amountNumber < fee ? fee : amountNumber;
                difference = amountNumber - fee;
                totalPay = parseFloat(fee) + parseFloat(difference);

                _this7.set('recipientAmount', quote.StandardQuote.RecipientAmount.toLocaleString());

                _this7.set('lastAmount', amountNumber);
                _this7.set('totalToPay', quote.StandardQuote.TotalToPay.toFixed(2));
              }

              _this7.set('minAmout', fee);
              if (amount < fee) {
                _this7.set('totalToPay', "N/A");
                _this7.set('recipientAmount', "N/A");
                _this7.set('fee', "N/A");
                setTimeout(function () {
                  _ember['default'].$("#senderAmountForm").parsley().validate();
                }, 0);
              }
            } else {
              // not checked
              if (amount < 1) {
                _this7.set('totalToPay', "N/A");
                _this7.set('recipientAmount', "N/A");
                _this7.set('fee', "N/A");
              } else {
                totalPay = parseFloat(fee) + parseFloat(amount);
                _this7.set('recipientAmount', quote.StandardQuote.RecipientAmount.toFixed(2).toLocaleString());
                _this7.set('lastAmount', amount ? amount : 0);
                _this7.set('totalToPay', totalPay.toFixed(2));
              }
              _this7.set('minAmout', 1);
            }

            _ember['default'].$("#currencyConverterLoader").hide();
            setTimeout(function () {
              //Ember.$("#senderAmountForm").parsley().validate();
            }, 500);
          }, function (err) {
            _ember['default'].$("#currencyConverterLoader").hide();
            _this7.handleErrors(null, err);
          });
        });
      },

      recipientAmountChanged: function recipientAmountChanged(amount) {

        var self = this;
        var pool = this.get('poolerUtil');
        this.set('recipientAmount', amount);
        //Ember.$("#senderAmountForm").parsley().reset()

        pool.setInterval(500);
        pool.stop();
        pool.start(this, function () {
          var _this8 = this;

          // Get fee based amount send TODO - Component is not place to call data (it should come from Model - Ember Data)
          var getQuotes = new _ember['default'].RSVP.Promise(function (resolve, reject) {

            pool.stop();
            return _this8.get('api').getQuotes({
              RecipientAmount: amount,
              SendingCurrency: 'CAD',
              RecipientCurrency: self.get('currency'),
              DeliveryMethod: _this8.get("DeliveryMethod")
            }).then(function (quote) {

              resolve(quote);
            }, function (err) {

              reject(err);
            });
          });
          _ember['default'].$("#currencyConverterLoader").show();
          getQuotes.then(function (quote) {

            var fee = quote.StandardQuote.TransactionFee;
            var exchangeRate = quote.StandardQuote.ExchangeRate;
            var arrivalDate = quote.StandardQuote.EstimatedArrivalDate;
            var totalPay = 0;
            var ckd = _this8.get('sameamount');
            self.set('arrivalDate', arrivalDate);

            self.set('fee', fee);

            if (self.get('exchangeRate') !== exchangeRate) {

              self.set('exchangeRate', exchangeRate);
            }

            // If include fee checked
            if (ckd) {

              if (amount && amount >= fee) {

                var amountNumber = amount !== amount ? 0 : parseFloat(amount);
                var difference = 0;

                amountNumber = amountNumber < fee ? fee : amountNumber;
                difference = amountNumber - fee;
                totalPay = parseFloat(fee) + parseFloat(difference);
                if (quote.StandardQuote.SendingAmount > 999) {
                  _this8.send("senderAmountChanged", 999);
                } else {
                  self.set('sendingAmount', quote.StandardQuote.SendingAmount.toLocaleString());
                  self.set('lastAmount', amountNumber);
                  self.set('totalToPay', quote.StandardQuote.TotalToPay.toFixed(2));
                }
              }

              _this8.set('minAmout', fee);
            } else {
              // not checked

              totalPay = parseFloat(fee) + parseFloat(amount);
              if (quote.StandardQuote.SendingAmount > 999.00) {
                _this8.send("senderAmountChanged", 999);
              } else {
                self.set('sendingAmount', quote.StandardQuote.SendingAmount.toLocaleString());

                self.set('lastAmount', amount ? amount : 0);
                self.set('totalToPay', totalPay.toFixed(2));
                self.set('minAmout', 1);
              }
            }

            _ember['default'].$("#currencyConverterLoader").hide();
            setTimeout(function () {
              //Ember.$("#senderAmountForm").parsley().validate();
            }, 500);
          }, function (err) {
            _ember['default'].$("#currencyConverterLoader").hide();
            self.handleErrors(null, err);
          });
        });
      },

      /***
       * recipientCurrencyChanged
       * @param country (String) - Selected country
       * @param currency (String) - Selected currency
       * @param currency (String) - Selected countryID
       * The step that we want to edit
       */
      recipientCurrencyChanged: function recipientCurrencyChanged(country, currency, amount, countryID) {
        var _this9 = this;

        this.set("DeliveryMethod", "bank");

        this.set("DeliveryMethodLabel", "Bank Deposit");
        this.set("countryID", countryID);
        var ckd = this.get('sameamount');
        var params = {
          SendingAmount: amount,
          SendingCurrency: 'CAD',
          RecipientCurrency: currency,
          DeliveryMethod: 'bank' // TODO - Fixed payment method
        };

        this.set('country', country);
        this.set('currency', currency);
        this.set('isoCountry', this.getIsoCountry(country));

        _ember['default'].$("#currencyConverterLoader").show();

        this.get('api').getQuotes(params).then(function (data) {
          var fee = data.StandardQuote.TransactionFee;
          _this9.set('fee', fee);
          var exc = parseFloat(data.StandardQuote.ExchangeRate);
          _this9.set('recipientAmount', ckd ? data.StandardQuote.RecipientAmount.toLocaleString() : data.StandardQuote.RecipientAmount.toLocaleString());
          var totalPay = parseFloat(fee) + parseFloat(amount);
          _this9.set('totalToPay', totalPay.toFixed(2));
          _this9.set('exchangeRate', exc.toFixed(4));
          var arrivalDate = data.StandardQuote.EstimatedArrivalDate;
          _this9.set('arrivalDate', arrivalDate);
          _ember['default'].$("#currencyConverterLoader").hide();
          if (ckd) {
            _this9.set('minAmout', fee);
            if (amount < fee) {
              _this9.set('totalToPay', "N/A");
              _this9.set('recipientAmount', "N/A");
              _this9.set('fee', "N/A");
              setTimeout(function () {
                _ember['default'].$("#senderAmountForm").parsley().validate();
              }, 0);
            }
            var _totalPay = parseFloat(amount);
            _this9.set('totalToPay', _totalPay.toFixed(2));
          } else {
            var _totalPay2 = parseFloat(fee) + parseFloat(amount);
            _this9.set('totalToPay', _totalPay2.toFixed(2));
          }
        }, function (err) {
          _ember['default'].$("#currencyConverterLoader").hide();
          _this9.handleErrors(null, err);
        });

        this.filterRecipients();
      },

      /***
       * recipientChanged
       * @param option (String) - Flag for showing the recipient form
       *
       * The step that we want to edit
       */
      recipientChanged: function recipientChanged(name, id) {

        if (name === 'new') {
          this.set('addRecipientFormVisible', true);
          this.set('recipient', null);
        } else {
          this.set('recipient', name);
          this.set('transaction.RemittanceRecipientID', id);
          this.set('addRecipientFormVisible', false);
        }
      },

      /***
       * paymentChanged
       * @param paymentId (String) - ID of the payment method
       *
       * Triggers when the payment method has changed
       */
      paymentChanged: function paymentChanged(paymentId, paymentType) {
        this.set('paymentMethod', paymentType);
        this.set('addPaymentMethodFormVisible', !!!paymentId);

        if (paymentType === "CreditCard") {
          this.set('transaction.CreditCardID', paymentId);
        }

        if (paymentType === "Bank") {
          this.set('transaction.BankAccountID', paymentId);
        }
      },

      /***
       * deliveryChanged
       * @param method (String) - Deliver method
       *
       * Triggers when the delivery method has changed
       */
      deliveryChanged: function deliveryChanged(method) {
        var _this10 = this;

        this.set('selectedDeliveryMethod', method);
        var params = {
          SendingAmount: this.get("defaultSendingAmount"),
          SendingCurrency: 'CAD',
          RecipientCurrency: this.get("currency")
        };
        if (method === "Bank Deposit") {
          this.filterRecipients("bank");
          this.set("recipientAvailable", this.get("bank"));
          this.set("DeliveryMethod", "bank");
          params.DeliveryMethod = "bank";
        }
        if (method === "Cash Pick-Up") {
          this.set("recipientAvailable", this.get("cash"));
          this.filterRecipients("cash");
          this.set("DeliveryMethod", "cash");
          params.DeliveryMethod = "cash";
        }

        var ckd = this.get('sameamount');
        this.get('api').getQuotes(params).then(function (data) {
          if (ckd) {
            _this10.set("totalToPay", data.StandardQuote.TotalToPay);
            _this10.set("recipientAmount", data.StandardQuote.RecipientAmount.toLocaleString());
            _this10.set("fee", data.StandardQuote.TransactionFee);
          } else {
            _this10.set("totalToPay", data.StandardQuote.TotalToPay);
            _this10.set("recipientAmount", data.StandardQuote.RecipientAmount.toLocaleString());
            _this10.set("fee", data.StandardQuote.TransactionFee);
          }
        }, function (err) {
          _this10.handleErrors(null, err);
        });
      },

      promoCodeApplied: function promoCodeApplied(promocode) {
        var _this11 = this;

        this.set("promocode", promocode);
        var params = {
          SendingAmount: this.get("defaultSendingAmount"),
          SendingCurrency: 'CAD',
          RecipientCurrency: this.get("currency"),
          DeliveryMethod: this.get("DeliveryMethod"), // TODO - Fixed payment method
          PromoCode: promocode
        };
        var ckd = this.get('sameamount');
        this.get('api').getQuotes(params).then(function (data) {
          _this11.set("discount", data.StandardQuote.AppliedDiscount);
          if (!_this11.get("discount")) {
            _ember['default'].$("#promoCode").parsley({
              successClass: " ",
              errorClass: "transferSummary__input--error",
              errorsWrapper: "<p></p>",
              errorTemplate: "<span class='transferSummary__message'></span>"
            });
            _ember['default'].$("#promoCode").val("");
            _ember['default'].$("#promoCode").parsley().validate();
          }
          if (ckd) {
            _this11.set("totalToPay", data.StandardQuote.TotalToPay.toFixed(2));
            _this11.set("recipientAmount", data.StandardQuote.RecipientAmount.toLocaleString());
            _this11.set("fee", data.StandardQuote.TransactionFee);
          } else {
            _this11.set("totalToPay", data.StandardQuote.TotalToPay.toFixed(2));
            _this11.set("recipientAmount", data.StandardQuote.RecipientAmount.toLocaleString());
            _this11.set("fee", data.StandardQuote.TransactionFee);
          }
        }, function (err) {
          _this11.handleErrors(null, err);
        });
      },
      requote: function requote() {

        this.send("senderAmountChanged", this.get("sendingAmount"));
        _ember['default'].$("#stepFiveBtn").show();
        _ember['default'].$("#resubmit").hide();
        this.resetTimer();
      },
      promoCodeRemoved: function promoCodeRemoved() {
        var _this12 = this;

        this.set("discount", null);
        this.set("promocode", '');
        var params = {
          SendingAmount: this.get("defaultSendingAmount"),
          SendingCurrency: 'CAD',
          RecipientCurrency: this.get("currency"),
          PaymentMethod: 'Bank' // TODO - Fixed payment method
        };
        var ckd = this.get('sameamount');
        this.get('api').getQuotes(params).then(function (data) {
          if (ckd) {

            _this12.set("totalToPay", data.StandardQuote.TotalToPay).toFixed(2);
            _this12.set("recipientAmount", data.StandardQuote.RecipientAmount.toLocaleString());
          } else {
            _this12.set("totalToPay", data.StandardQuote.TotalToPay.toFixed(2));
            _this12.set("recipientAmount", data.StandardQuote.RecipientAmount.toLocaleString());
          }
        }, function (err) {
          _this12.handleErrors(null, err);
        });
      },
      logout: function logout() {
        var _this13 = this;

        this.get("api").logout().then(function () {
          _this13.setCookie("CSRF-TOKEN", "null");
          location.reload(false);
          return false;
        }, function (error) {

          return false;
        });
      }

    }
  });
});
/* global pca */
/* global IBAN */