define('vopay_app/components/convert-pay-overview', ['exports', 'ember', 'vopay_app/utils/entry-filter', 'vopay_app/constants', 'vopay_app/config/environment'], function (exports, _ember, _vopay_appUtilsEntryFilter, _vopay_appConstants, _vopay_appConfigEnvironment) {
	exports['default'] = _ember['default'].Component.extend({
		SupportEmail: _vopay_appConfigEnvironment['default'].businessEmail,
		api: _ember['default'].inject.service('api'),
		entryUtil: _vopay_appUtilsEntryFilter['default'].create(),
		conversionOptions: ["Sell", "Buy"],
		ConversionType: "Sell",
		defaultConversionType: "Sell",
		toCurrency: "USD",
		toCurrencyName: "United States Dollar",
		fromCurrency: "CAD",
		fromCurrencyName: "Canadian Dollar",
		currencies2: _vopay_appConstants['default'].currencies,
		amount: "0.00",
		currency: "CAD",
		wachValue: _ember['default'].observer('amount', function () {

			var amount = this.get('amount');
			if (amount) {
				var myElement = this.$("input")[0];
				if (myElement) {
					var start = myElement.selectionStart;
					var end = myElement.selectionEnd;

					var amount2 = amount;
					if (amount.replace()) {
						amount2 = Math.floor(parseFloat(amount.replace(/,/g, '')));
					}
					var split = amount.split(".");
					var ending = split[1];
					var amount3 = undefined;
					if (Math.floor(amount2) === 0) {
						amount3 = amount;
					} else {

						if (ending || ending === 0) {
							amount3 = amount2.toLocaleString(undefined) + "." + ending;
						} else {
							amount3 = amount2.toLocaleString(undefined);
						}
					}

					this.$(myElement).val(amount3);

					if (myElement.setSelectionRange) {
						if (amount3.length > amount.length && amount3.length > 4) {
							myElement.setSelectionRange(start + 1, end + 1);
						} else if (amount3.length < amount.length) {
							myElement.setSelectionRange(start - 1, end - 1);
						} else {
							myElement.setSelectionRange(start, end);
						}
					} else if (myElement.createTextRange) {
						var range = myElement.createTextRange();
						range.collapse(true);
						range.moveEnd('character', start);
						range.moveStart('character', end);
						range.select();
					}
				}
			}
		}),
		startTimer: function startTimer() {
			window.iterations = 0;
			this.set("timer", setInterval(function () {
				if (window.iterations === 30) {
					_ember['default'].$("#quoteButton").hide();
					_ember['default'].$("#resubmit").show();
				} else {
					if (window.iterations < 30) {
						window.iterations = window.iterations + 1;
						_ember['default'].$("#quoteRefresh").html(30 - window.iterations);
					} else {
						_ember['default'].$("#quoteRefresh").html("0");
					}
				}
			}, 1000));
		},
		resetTimer: function resetTimer() {
			window.iterations = 0;
			clearInterval(this.get("timer"));
			_ember['default'].$("#quoteButton").show();
			_ember['default'].$("#resubmit").hide();
			this.set("timer", setInterval(function () {
				if (window.iterations === 30) {
					_ember['default'].$("#quoteButton").hide();
					_ember['default'].$("#resubmit").show();
				} else {
					if (window.iterations < 30) {
						window.iterations = window.iterations + 1;
						_ember['default'].$("#quoteRefresh").html(30 - window.iterations);
					} else {
						_ember['default'].$("#quoteRefresh").html("0");
					}
				}
			}, 1000));
		},
		init: function init() {
			this._super.apply(this, arguments);

			var currencies2 = this.get("currencies2");
			var currencies = this.get("currencies.CurrenciesList");
			var currencies3 = {};
			for (var key in currencies) {
				if (currencies2[currencies[key]]) {
					currencies3[currencies[key]] = {};
					currencies3[currencies[key]]["name"] = currencies2[currencies[key]]["name"];
					currencies3[currencies[key]]["iso3"] = currencies2[currencies[key]]["iso3"];
				}
			}

			this.set("currencies3", currencies3);
			if (sessionStorage.getItem("currency")) {
				this.set("toCurrency", sessionStorage.getItem("currency"));
				if (this.get("toCurrency") === "CAD") {
					this.set("fromCurrency", "USD");
					this.set("fromCurrencyName", "United States Dollar");
				} else {
					this.set("fromCurrency", "CAD");
					this.set("fromCurrencyName", "Canadian Dollar");
				}

				var currencyList = this.get("currencies2");
				this.set("toCurrencyName", currencyList[sessionStorage.getItem("currency")].name);

				sessionStorage.removeItem("currency");
			}
			var currencies4 = {};
			for (var key2 in currencies) {
				if (currencies2[currencies[key2]]) {

					if (currencies2[currencies[key2]]["iso3"] !== this.get("fromCurrency")) {
						currencies4[currencies[key2]] = {};
						currencies4[currencies[key2]]["name"] = currencies2[currencies[key2]]["name"];
						currencies4[currencies[key2]]["iso3"] = currencies2[currencies[key2]]["iso3"];
					}
				}
			}
			this.set("currencies4", currencies4);
		},
		actions: {
			handleConversionType: function handleConversionType(value) {
				this.set("ConversionType", value);
			},
			buyCurrencyChanged: function buyCurrencyChanged(currency) {
				var currencyList = this.get("currencies2");
				this.set("toCurrency", currency);
				this.set("toCurrencyName", currencyList[currency].name);
			},
			sellCurrencyChanged: function sellCurrencyChanged(currency) {
				var currencyList = this.get("currencies2");
				this.set("fromCurrency", currency);
				this.set("fromCurrencyName", currencyList[currency].name);
				if (this.get("fromCurrency") === this.get("toCurrency")) {
					if (this.get("fromCurrency") === "CAD") {
						this.set("toCurrency", "USD");
						this.set("toCurrencyName", "United States Dollar");
					} else {
						this.set("toCurrency", "CAD");
						this.set("toCurrencyName", "Canadian Dollar");
					}
				}

				var currencies2 = this.get("currencies2");
				var currencies = this.get("currencies.CurrenciesList");
				var currencies4 = {};
				for (var key in currencies) {
					if (currencies2[currencies[key]]) {

						if (currencies2[currencies[key]]["iso3"] !== this.get("fromCurrency")) {
							currencies4[currencies[key]] = {};
							currencies4[currencies[key]]["name"] = currencies2[currencies[key]]["name"];
							currencies4[currencies[key]]["iso3"] = currencies2[currencies[key]]["iso3"];
						}
					}
				}
				this.set("currencies4", currencies4);
			},
			closeModal: function closeModal() {
				this.set("isShowingModal", false);
				return false;
			},
			openModal: function openModal() {
				this.set("isShowingModal", true);
				return false;
			},
			quote: function quote() {
				var _this = this;

				if (localStorage.getItem("GCMEnabled") === "0" || localStorage.getItem("PortalViewOnly") === "1") {
					this.set("isShowingModal", true);
				} else {
					var sellCurrency;
					var buyCurrency;

					(function () {
						_this.resetTimer();
						var amount = parseFloat(_this.get('amount').replace(/\,/g, ""));

						sellCurrency = _this.get('fromCurrency');
						buyCurrency = _this.get('toCurrency');
						_this.get("api").getGCMRate({
							SellCurrency: sellCurrency,
							BuyCurrency: buyCurrency
						}).then(function (rate) {
							_this.set("ExchangeRate", rate.ConversionRate);
							if (_this.get("ConversionType") === "Sell") {
								_this.get("api").getGCMQuote({
									SellCurrency: _this.get('fromCurrency'),
									SellAmount: amount,
									BuyCurrency: _this.get('toCurrency')
								}).then(function (quote) {

									_this.set("toAmount", quote.BuyAmount);
									_this.set("fromAmount", amount);
									_ember['default'].$("#conversions").hide();
									_ember['default'].$("#Quote").show();
								});
							} else {
								_this.get("api").getGCMQuote({
									SellCurrency: _this.get('fromCurrency'),
									BuyAmount: amount,
									BuyCurrency: _this.get('toCurrency')
								}).then(function (quote) {
									_this.set("toAmount", amount);
									_this.set("fromAmount", quote.SellAmount);
									_ember['default'].$("#conversions").hide();
									_ember['default'].$("#Quote").show();
								});
							}
						});
					})();
				}
			},
			moveToConversion: function moveToConversion() {
				this.set("buyAmount", 0);
				this.set("sellAmont", 0);
				this.set("ExchangeRate", 0);
				_ember['default'].$("#conversions").show();
				_ember['default'].$("#Quote").hide();
			},
			senderAmountChanged: function senderAmountChanged(amount) {

				this.set("amount", amount);
			},

			convert: function convert() {
				var amount = parseFloat(this.get('amount').replace(/\,/g, ""));
				if (this.get("ConversionType") === "Sell") {
					this.get("api").getGCMConversion({
						SellCurrency: this.get('fromCurrency'),
						SellAmount: amount,
						BuyCurrency: this.get('toCurrency')
					}).then(function (quote) {
						window.location.href = "#/dashboard/transaction/Conversion/" + quote.TransactionID;
					});
				} else {
					this.get("api").getGCMConversion({
						SellCurrency: this.get('fromCurrency'),
						BuyAmount: amount,
						BuyCurrency: this.get('toCurrency')
					}).then(function (quote) {
						window.location.href = "#/dashboard/transaction/Conversion/" + quote.TransactionID;
					});
				}
			}
		}
	});
});