define('vopay_app/components/recipient-country-currency', ['exports', 'ember', 'vopay_app/utils/entry-filter'], function (exports, _ember, _vopay_appUtilsEntryFilter) {
  exports['default'] = _ember['default'].Component.extend({
    menuActive: false,
    dropDownClass: "form__dropdownToggle--grey",
    dropDownSelClass: "form__selDropdown--grey",
    dropDownStateClass: "",
    selDropDownStateClass: "",
    filteredData: null,
    currentSelection: null,
    restrictions: null,
    selected: false,
    entryUtil: _vopay_appUtilsEntryFilter['default'].create(),
    watchCurrentSelection: _ember['default'].observer('currentSelection', function () {
      var currentSelection = this.get('currentSelection');
      if (this.get("restrict") === 'characters') {
        this.set('currentSelection', this.get('entryUtil').onlyCharacters(currentSelection));
      } else if (this.get("restrict") === 'numbers') {
        this.set('currentSelection', this.get('entryUtil').onlyNumbers(currentSelection));
      } else {}
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.set("filteredData", this.get("data"));
      if (this.get("selected")) {
        this.set("currentSelection", this.get("default"));
        this.set('dropDownClass', '');
        this.set('dropDownSelClass', '');
        this.set('default', "Choose Bank");
      }
    },
    toggleMenu: function toggleMenu() {

      if (!this.get('menuActive')) {
        this.set('menuActive', true);
        this.set('dropDownStateClass', 'form__dropdownToggle--active');
        this.set('selDropDownStateClass', 'form__selDropdown--active');
      } else {
        this.set('menuActive', false);
        this.set('dropDownStateClass', '');
        this.set('selDropDownStateClass', '');
      }
    },
    didInsertElement: function didInsertElement() {

      this._super.apply(this, arguments);
      this.$(".dropdowncontainer").focusout(function () {
        _ember['default'].$(this).removeClass("open");
      });
      this.$("input").focusin(function () {
        _ember['default'].$(this).val("");
        var self2 = this;
        setTimeout(function () {
          _ember['default'].$(".open").removeClass("open");
          _ember['default'].$(self2).parent().addClass("open");
        }, 100);
      });

      this.$("input").click(function () {
        _ember['default'].$(".open").removeClass("open");
        _ember['default'].$(this).parent().addClass("open");
        return false;
      });
      this.$('li a').on('mousedown',
      /** @param {!jQuery.Event} event */
      function (event) {
        event.preventDefault();
      });
    },
    actions: {
      /***
      * toggleMenu
      *
      * Toggles the dropdown menu
      */
      activateMenu: function activateMenu() {
        this.toggleMenu();
      },
      /***
      * delevry dropdown
      * @params item (String)
      * Sets current selected item the dropdown
      ***/
      selectItem: function selectItem(item) {
        this.sendAction('value', item);
        var name = "";
        if (item === "New") {
          name = "New";
        } else {
          var data = this.get("data");
          var chosen = data[item];
          if (chosen.CompanyName) {
            name = chosen.CompanyName;
          } else if (chosen.RecipientName) {
            name = chosen.RecipientName;
          } else {
            name = chosen.FirstName + " " + chosen.LastName;
          }
        }
        this.set('currentSelection', name);
        this.toggleMenu();
        this.set('selected', true);
      },
      filter: function filter() {
        var data = this.get("data");
        if (this.get("currentSelection") && !this.get("selected")) {
          var filterTerm = this.get("currentSelection").toUpperCase();
          var newData = {};
          for (var key in data) {
            var name = "";
            var chosen = data[key];
            if (chosen.CompanyName) {
              name = chosen.CompanyName;
            } else if (chosen.RecipientName) {
              name = chosen.RecipientName;
            } else {
              name = chosen.FirstName + " " + chosen.LastName;
            }
            if (name.includes(filterTerm)) {
              newData[key] = data[key];
            }
          }
          this.set("selected", false);
          this.set("filteredData", newData);
        } else {
          this.set("selected", false);
          this.set("filteredData", data);
        }
      },
      resetFeild: function resetFeild() {
        if (!this.get("selected")) {
          this.set('currentSelection', '');
          this.sendAction('value', "");
        }
      }

    }
  });
});