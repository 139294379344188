define('vopay_app/components/update-auto-bank', ['exports', 'ember', 'vopay_app/constants', 'vopay_app/helpers/complete-with-zeros', 'vopay_app/utils/entry-filter', 'vopay_app/mixins/error-handler', 'vopay_app/config/environment'], function (exports, _ember, _vopay_appConstants, _vopay_appHelpersCompleteWithZeros, _vopay_appUtilsEntryFilter, _vopay_appMixinsErrorHandler, _vopay_appConfigEnvironment) {
	var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

	var businessShortName = _vopay_appConfigEnvironment['default'].businessShortName;
	var businessLongName = _vopay_appConfigEnvironment['default'].businessLongName;

	exports['default'] = _ember['default'].Component.extend(_vopay_appMixinsErrorHandler['default'], {
		SupportEmail: _vopay_appConfigEnvironment['default'].businessEmail,
		LongName: businessLongName,
		ShortName: businessShortName,
		api: _ember['default'].inject.service('api'),
		firebase: _ember['default'].inject.service('firebase'),
		routing: _ember['default'].inject.service('-routing'),
		institutionName: '',
		financialInstitutionNumber: null,
		accountNumber: null,
		branchTransitNumber: null,
		entryUtil: _vopay_appUtilsEntryFilter['default'].create(),
		isStore: true,
		isDefault: true,
		isDual: false,
		userName: null,
		signatureNumbers: [],
		signatureCurrNum: 0,
		signatureNumDel: 0,
		isEndDate: true,
		currentDateValid: true,
		currentEndDateValid: true,
		accountOptions: ["Deposit", "Withdraw"],
		InformationOptions: ["Manual", "Online Banking"],
		accountTypeOptions: ["Business", "Individual"],
		frequencyOptions: ["One Time", "Recurring"],
		defaultAccountType: "Business",
		paymentTypeOptions: ["Bank Account", "Interac E-Transfer Money Request"],
		paymentTypeOptionsCollect: ["Bank Account", "Interac E-Transfer Money Request"],
		paymentTypeOptionsWithdraw: ["Bank Account", "Interac E-Transfer"],
		defaultPaymentType: "Bank Account",
		paymentTypeOptionsSingle: ["Bank Account"],
		defaultPaymentType: "Bank Account",
		PaymentType: "Bank Account",
		isBusiness: true,
		isDeposit: false,
		formatBank: true,
		isOneTime: true,
		NameOfFrequency: "monthly",
		frequenciesOptions: ["Monthly", "Daily", "Weekly", "Bi-Weekly"],
		endatestuff: ["End Date", "Payments"],
		Frequency: "single",
		isInteracEtransfer: false,
		country: "Canada",
		currency: "CAD",
		currencyName: "Canadian Dollar",
		currency2: "CAD",
		countryID: 2,
		stageNumber: 1,
		stageName: "Balance Transfer Details",
		defaultType: "Deposit",
		amount: "",
		amount2: "",
		FlinksURL: "",
		max: "0",
		fundCount: 0,
		isShowingModal: false,
		currencies2: _vopay_appConstants['default'].currencies,
		currencies4: {
			CAD: {
				name: 'Canadian Dollar',
				iso3: 'CAD'
			}
		},
		purposes: ["Sending to Self", "Rent or Mortgage", "Family Support", "Family Emergency", "Sending to Friend", "Gift"],
		purpose: null,
		accounts: null,
		agreedToTerms: false,
		// Avoiding wrong entry data
		watchFirstName: _ember['default'].observer('firstname', function () {
			this.set('firstname', this.get('entryUtil').onlyNameCharacters(this.get('firstname')));
		}),

		watchLastName: _ember['default'].observer('lastname', function () {
			this.set('lastname', this.get('entryUtil').onlyNameCharacters(this.get('lastname')));
		}),
		watchCompanyName: _ember['default'].observer('companyName', function () {
			this.set('companyName', this.get('entryUtil').onlyNameCharacters(this.get('companyName')));
		}),

		watchIsName: _ember['default'].observer("isAgree", function () {
			if (this.get("isAgree") === true) {
				_ember['default'].$("#termsConditions").show();
				_ember['default'].$("#overlay").show();
			}
		}),
		watchUserName: _ember['default'].observer('userName', function () {

			this.set('userName', this.get('entryUtil').onlyCharacters(this.get('userName')));
		}),

		watchfInstitutionNumber: _ember['default'].observer('financialInstitutionNumber', function () {

			this.set('financialInstitutionNumber', this.get('entryUtil').onlyNumbers(this.get('financialInstitutionNumber')));
		}),

		watchAccountNumber: _ember['default'].observer('accountNumber', function () {

			this.set('accountNumber', this.get('entryUtil').onlyNumbers(this.get('accountNumber')));
		}),

		watchBranchTransitNumber: _ember['default'].observer('branchTransitNumber', function () {

			this.set('branchTransitNumber', this.get('entryUtil').onlyNumbers(this.get('branchTransitNumber')));
		}),
		watchPhoneNumber: _ember['default'].observer('PhoneNumber', function () {

			this.set('PhoneNumber', this.get('entryUtil').onlyNumbers(this.get('PhoneNumber')));
		}),
		watchDualPhoneNumber: _ember['default'].observer('DualPhoneNumber', function () {

			this.set('DualPhoneNumber', this.get('entryUtil').onlyNumbers(this.get('DualPhoneNumber')));
		}),
		watchSignatoryPhoneNumber: _ember['default'].observer('SignatoryPhoneNumber', function () {

			this.set('SignatoryPhoneNumber', this.get('entryUtil').onlyNumbers(this.get('SignatoryPhoneNumber')));
		}),
		watchPhoneNumberRequest: _ember['default'].observer('PhoneNumberRequest', function () {

			this.set('PhoneNumberRequest', this.get('entryUtil').onlyNumbers(this.get('PhoneNumberRequest')));
		}),
		watchPayments: _ember['default'].observer('Payments', function () {

			this.set('Payments', this.get('entryUtil').onlyNumbers(this.get('Payments')));
		}),
		wachValueAmount: _ember['default'].observer('amount2', function () {

			var isZeroAppend = false;
			var amount = this.get('amount2');
			if (amount) {
				var myElement = this.$("input")[3];

				if (myElement) {
					var start = myElement.selectionStart;
					var end = myElement.selectionEnd;

					var amount2 = amount;
					if (amount.replace()) {
						amount2 = amount.replace(/[^\d.]+/g, '');
						amount2 = Math.floor(parseFloat(amount2));
					}
					var split = amount.split(".");
					var ending = split[1];

					var amount3 = undefined;

					var ending2 = null;
					if (ending && ending.length && ending.length > 2) {

						ending2 = ending.substring(0, 2);
					} else {
						ending2 = ending;
					}

					if (ending2 || ending2 === 0) {
						amount3 = amount2.toLocaleString(undefined) + "." + ending2;
					} else if (split[0] == "") {

						if (ending2 || ending2 === 0) {
							amount3 = "0." + ending2;
							isZeroAppend = true;
						} else {
							amount3 = "0.";
							isZeroAppend = true;
						}
					} else if (!ending && split.length == 2) {
						amount3 = amount2.toLocaleString(undefined) + ".";
					} else {
						if (amount2) {
							amount3 = amount2.toLocaleString(undefined);
						} else {
							amount3 = "";
						}
					}

					this.$(myElement).val(amount3);

					if (myElement.setSelectionRange) {
						if (isZeroAppend) {
							myElement.setSelectionRange(start + 2, end + 2);
						} else if (amount3.length > amount.length && amount3.length > 4) {
							myElement.setSelectionRange(start + 1, end + 1);
						} else if (amount3.length < amount.length) {
							myElement.setSelectionRange(start - 1, end - 1);
						} else {
							myElement.setSelectionRange(start, end);
						}
					} else if (myElement.createTextRange) {
						var range = myElement.createTextRange();
						range.collapse(true);
						range.moveEnd('character', start);
						range.moveStart('character', end);
						range.select();
					}
				}
			}
		}),
		wachValue: _ember['default'].observer('amount', function () {

			var isZeroAppend = false;
			var amount = this.get('amount');
			if (amount) {
				var myElement = this.$("input")[2];
				if (myElement) {
					var start = myElement.selectionStart;
					var end = myElement.selectionEnd;

					var amount2 = amount;
					if (amount.replace()) {
						amount2 = amount.replace(/[^\d.]+/g, '');
						amount2 = Math.floor(parseFloat(amount2));
					}
					var split = amount.split(".");
					var ending = split[1];

					var amount3 = undefined;

					var ending2 = null;
					if (ending && ending.length && ending.length > 2) {

						ending2 = ending.substring(0, 2);
					} else {
						ending2 = ending;
					}

					if (ending2 || ending2 === 0) {
						amount3 = amount2.toLocaleString(undefined) + "." + ending2;
					} else if (split[0] == "") {

						if (ending2 || ending2 === 0) {
							amount3 = "0." + ending2;
							isZeroAppend = true;
						} else {
							amount3 = "0.";
							isZeroAppend = true;
						}
					} else if (!ending && split.length == 2) {
						amount3 = amount2.toLocaleString(undefined) + ".";
					} else {
						if (amount2) {
							amount3 = amount2.toLocaleString(undefined);
						} else {
							amount3 = "";
						}
					}

					this.$(myElement).val(amount3);

					if (myElement.setSelectionRange) {
						if (isZeroAppend) {
							myElement.setSelectionRange(start + 2, end + 2);
						} else if (amount3.length > amount.length && amount3.length > 4) {
							myElement.setSelectionRange(start + 1, end + 1);
						} else if (amount3.length < amount.length) {
							myElement.setSelectionRange(start - 1, end - 1);
						} else {
							myElement.setSelectionRange(start, end);
						}
					} else if (myElement.createTextRange) {
						var range = myElement.createTextRange();
						range.collapse(true);
						range.moveEnd('character', start);
						range.moveStart('character', end);
						range.select();
					}
				}
			}
		}),

		init: function init() {
			var _this = this;

			this._super.apply(this, arguments);

			if (this.get("isCollect") === "true") {
				this.set("stageName", "Balance Transfer Details");
			} else {
				this.set("stageName", "Balance Transfer Details");
			}
			if (this.get("isCollect") === "true") {

				var bankAccounts = this.get("bankAccounts.BankAccounts");
				var myAccounts = Object.values(bankAccounts);
				myAccounts = myAccounts.map(function (bankAccount) {
					return _extends({}, bankAccount, { Type: 'Bank Account', Country: 'CA' });
				});

				var firebaseAccounts = $.extend(true, {}, this.get("firebaseBeneficiaries"), this.get("firebaseLinkedAccounts"));
				var nonBankAccounts = Object.values(firebaseAccounts);
				nonBankAccounts = nonBankAccounts.filter(function (account) {
					return account.Type != "Bank Account";
				});

				var data = myAccounts.concat(nonBankAccounts);

				this.set("accounts", data);
				var type = "Bank Account";
				if (sessionStorage.getItem("Benefiricary")) {
					var value2 = sessionStorage.getItem("Benefiricary");
					var data4 = this.get("accounts");
					var paymentToAdd2 = data4["" + value2];
					type = paymentToAdd2.Type;
					if (type == "Bank Account") {
						this.set("defaultPaymentType", type);
					} else {
						this.set("defaultPaymentType", "Interac E-Transfer Money Request");
						this.set("isInteracEtransfer", true);
						this.set("formatBank", false);
					}
				}
				var filteredAccounts = {};
				for (var key in data) {
					if (data[key]["Currency"] === "CAD" && data[key]["Type"] === type) {
						filteredAccounts[key] = data[key];
					}
				}

				this.set("accounts2", filteredAccounts);
				if (sessionStorage.getItem("Benefiricary")) {
					var value;
					var data3;
					var paymentToAdd;

					(function () {
						value = sessionStorage.getItem("Benefiricary");
						data3 = _this.get("accounts2");
						paymentToAdd = data3["" + value];

						paymentToAdd.DollarAmount = 0;
						_this.set("paymentToAdd", paymentToAdd);
						var self = _this;
						setTimeout(function () {
							self.set("selected", true);
						}, 100);
						if (paymentToAdd.CompanyName) {
							_this.set("currentSelection", paymentToAdd.CompanyName);
						} else if (paymentToAdd.RecipientName) {
							_this.set("currentSelection", paymentToAdd.RecipientName);
						} else {
							_this.set("currentSelection", paymentToAdd.FirstName + " " + paymentToAdd.LastName);
						}

						sessionStorage.removeItem("Benefiricary");
					})();
				}
			} else {
				var keys;
				var type;
				var value2;
				var data4;
				var paymentToAdd2;
				var filteredAccounts;
				var key;
				var value;
				var data3;
				var paymentToAdd;

				(function () {
					var currencyList = _this.get("currencies2");
					if (sessionStorage.getItem("currency")) {
						_this.set("currency2", sessionStorage.getItem("currency"));

						_this.set("currencyName", currencyList[sessionStorage.getItem("currency")].name);
						sessionStorage.removeItem("currency");
					}
					var bankAccounts = _this.get("bankAccounts.BankAccounts");
					var myAccounts = Object.values(bankAccounts);
					myAccounts = myAccounts.map(function (bankAccount) {
						return _extends({}, bankAccount, { Type: 'Bank Account', Country: 'CA' });
					});

					var firebaseAccounts = $.extend(true, {}, _this.get("firebaseBeneficiaries"), _this.get("firebaseLinkedAccounts"));
					var nonBankAccounts = Object.values(firebaseAccounts);
					nonBankAccounts = nonBankAccounts.filter(function (account) {
						return account.Type != "Bank Account";
					});

					var data = myAccounts.concat(nonBankAccounts);

					keys = Object.keys(data).sort(function (a, b) {
						var aName = data[a].timestamp;
						var bName = data[b].timestamp;
						if (!aName) {
							aName = 0;
						}
						if (!bName) {
							bName = 0;
						}
						return aName < bName ? -1 : aName > bName ? 1 : 0;
					});

					var ordered = {};
					keys.forEach(function (key) {
						ordered[key] = data[key];
					});
					data = ordered;
					_this.set("accounts", data);
					type = "Bank Account";

					if (sessionStorage.getItem("Benefiricary")) {
						value2 = sessionStorage.getItem("Benefiricary");
						data4 = _this.get("accounts");
						paymentToAdd2 = data4["" + value2];

						type = paymentToAdd2.Type;
						_this.set("currency2", paymentToAdd2.Currency);
						_this.set("currencyName", currencyList[paymentToAdd2.Currency].name);
						if (type == "Bank Account") {
							_this.set("defaultPaymentType", type);
						} else {
							_this.set("defaultPaymentType", "Interac E-Transfer Money Request");
							_this.set("isInteracEtransfer", true);
							_this.set("formatBank", false);
						}
					}
					filteredAccounts = {};

					for (key in data) {
						if (data[key]["Currency"] === "CAD" && data[key]["Type"] === type) {
							filteredAccounts[key] = data[key];
						}
					}
					_this.set("accounts2", filteredAccounts);
					if (sessionStorage.getItem("Benefiricary")) {
						(function () {
							value = sessionStorage.getItem("Benefiricary");
							data3 = _this.get("accounts2");
							paymentToAdd = data3["" + value];

							var self = _this;
							setTimeout(function () {
								self.set("selected", true);
							}, 100);
							paymentToAdd.DollarAmount = 0;
							_this.set("paymentToAdd", paymentToAdd);
							if (paymentToAdd.CompanyName) {
								_this.set("currentSelection", paymentToAdd.CompanyName);
							} else if (paymentToAdd.RecipientName) {
								_this.set("currentSelection", paymentToAdd.RecipientName);
							} else {
								_this.set("currentSelection", paymentToAdd.FirstName + " " + paymentToAdd.LastName);
							}
							sessionStorage.removeItem("Benefiricary");
						})();
					}
				})();
			}

			var currencies2 = this.get("currencies2");
			var currencies = this.get("currencies.CurrenciesList");
			var currencies3 = {};
			for (var key in currencies) {
				if (currencies2[currencies[key]]) {
					currencies3[currencies[key]] = {};
					currencies3[currencies[key]]["name"] = currencies2[currencies[key]]["name"];
					currencies3[currencies[key]]["iso3"] = currencies2[currencies[key]]["iso3"];
				}
			}
			this.get("api").getAutoBankTransfer().then(function (data) {
				if (data.Status == "cancelled") {
					_this.set("NoTransfer", true);
					_this.set("amount", "");
				} else {
					_this.set("transaction", data);

					_this.set("amount", "");
					//this.set("amount",""+data.AutoBalanceTransferAmount);
				}
			}, function (error) {
				_this.set("NoTransfer", true);
			});

			this.set("currencies3", currencies3);

			var name = this.get("userInfo.FirstName") + " " + this.get("userInfo.LastName");
			this.set("userName", name);

			if (this.get("isDeposit") === "true") {
				this.set("defaultType", "Deposit");
			} else {
				this.set("defaultType", "Withdraw");
			}
			this.get('api').getCountries().then(function (data) {

				var canada = {
					"CountryID": "1",
					"Country": "Canada",
					"Abbreviation": "CA",
					"Airtime": "0",
					"MoneyTransfer": "0",
					"BillPayment": "0",
					"Voucher": "0",
					"DefaultCurrency": "CAD",
					"DeliveryMethods": {
						"0": "bank"
					}
				};
				data.push(canada);
				_this.set('countries', data);
				_this.filterCountryList(_this.get("currency2"));
			});
		},
		SetIq11URL: function SetIq11URL() {
			var _this2 = this;

			var options = null;
			var AccountSelectionMethod = "any";

			if (location.hostname == "localhost") {
				options = {
					RedirectURL: "https://" + location.hostname + ":4200/",
					customerName: this.get("LongName"),
					AccountSelectionMethod: AccountSelectionMethod,
					ClientControlled: 'true',
					ClientReferenceNumber: this.get("ClientReferenceNumber")
				};
			} else if (location.hostname == "bs-local.com") {

				options = {
					RedirectURL: "https://" + location.hostname + ":4200/",
					customerName: this.get("LongName"),
					AccountSelectionMethod: AccountSelectionMethod,
					ClientControlled: 'true',
					ClientReferenceNumber: this.get("ClientReferenceNumber")
				};
			} else {
				options = {
					RedirectURL: "https://" + location.hostname + "/",
					customerName: this.get("LongName"),
					AccountSelectionMethod: AccountSelectionMethod,
					ClientControlled: 'true',
					ClientReferenceNumber: this.get("ClientReferenceNumber")
				};
			}
			this.get('api').getApiURL(options).then(function (data) {
				_this2.set("FlinksURL", data.EmbedURL);
			});
		},
		didInsertElement: function didInsertElement() {

			this._super.apply(this, arguments);

			_ember['default'].$("#paymentForm").parsley().destroy();
			_ember['default'].$('form').parsley({
				successClass: " ",
				errorClass: "form__input--error",
				errorsWrapper: "<p></p>",
				errorTemplate: "<span class='form__message'></span>"
			});
			_ember['default'].$('#month').keypress(function () {

				if (_ember['default'].$(this).val().length > 0) {
					_ember['default'].$('#year').focus();
				}
			});
			_ember['default'].$('#financialInstitutionNumber').focusout(function () {
				_ember['default'].$(".newPaymentMethod__institution").slideDown();
			});
			_ember['default'].$("#beneficiary-address").focusout(function () {
				setTimeout(function () {
					var address = _ember['default'].$("#beneficiary-address").val();
					var city = _ember['default'].$("#beneficiary-city").val();
					var state = _ember['default'].$("#beneficiary-state").val();
					var postcode = _ember['default'].$("#beneficiary-postcode").val();
					if (address) {
						_ember['default'].$("#beneficiary-address").parsley().reset();
					}
					if (city) {
						_ember['default'].$("#beneficiary-city").parsley().reset();
					}
					if (state) {
						_ember['default'].$("#beneficiary-state").parsley().reset();
					}
					if (postcode) {
						_ember['default'].$("#beneficiary-postcode").parsley().reset();
					}
				}, 500);
			});
			_ember['default'].$("#beneficiary-address").keyup(function () {
				if (!_ember['default'].$("#beneficiary-address").val()) {
					_ember['default'].$("#beneficiary-city").val("");
					_ember['default'].$("#beneficiary-postcode").val("");
					_ember['default'].$("#beneficiary-address").val("");
					_ember['default'].$("#beneficiary-state").val("");
				}
			});
			_ember['default'].$("#beneficiary-city").keyup(function () {
				if (!_ember['default'].$("#beneficiary-city").val()) {
					_ember['default'].$("#beneficiary-city").val("");
					_ember['default'].$("#beneficiary-postcode").val("");
					_ember['default'].$("#beneficiary-address").val("");
					_ember['default'].$("#beneficiary-state").val("");
				}
			});
			_ember['default'].$("#beneficiary-postcode").keyup(function () {
				if (!_ember['default'].$("#beneficiary-postcode").val()) {
					_ember['default'].$("#beneficiary-city").val("");
					_ember['default'].$("#beneficiary-postcode").val("");
					_ember['default'].$("#beneficiary-address").val("");
					_ember['default'].$("#beneficiary-state").val("");
				}
			});
			var self = this;
			_ember['default'].$("#beneficiary-address").on('input', function () {

				_ember['default'].$("#beneficiary-address").val(self.get('entryUtil').noSpecialCharacter(_ember['default'].$("#beneficiary-address").val()));
			});
			_ember['default'].$("#beneficiary-city").on('input', function () {

				_ember['default'].$("#beneficiary-city").val(self.get('entryUtil').noSpecialCharacter(_ember['default'].$("#beneficiary-city").val()));
			});
			_ember['default'].$("#beneficiary-postcode").on('input', function () {

				_ember['default'].$("#beneficiary-postcode").val(self.get('entryUtil').onlyAlphaNumeric(_ember['default'].$("#beneficiary-postcode").val()));
			});
			window.addEventListener('message', function (e) {
				if (e.data.step == "ACCOUNT_SELECTED") {
					localStorage.setItem("accountId", e.data.accountId);
				}
				if (e.data.step == "REDIRECT") {
					localStorage.setItem("loginId", e.data.loginId);
					localStorage.setItem("institution", e.data.institution);
					//location.href = e.data.url;
				}
				if (e.data.Step == "LINK") {
					localStorage.setItem("institution", e.data.Institution);
					localStorage.setItem("AccountNumber", e.data.AccountNumber);
					localStorage.setItem("BankId", e.data.BankId);
					localStorage.setItem("InstitutionNumber", e.data.InstitutionNumber);
				}
			});
		},
		MoveToOverview: function MoveToOverview(accountId, institution, loginId) {},
		initialiseAutoAddress: function initialiseAutoAddress() {
			//let countries = Constants.countries;
			/** 
   let fields = [{
   	element: "beneficiary-address",
   	field: "Line1"
   }, {
   	element: "beneficiary-state",
   	field: "ProvinceCode",
   	mode: window.pca.fieldMode.POPULATE
   }, {
   	element: "beneficiary-city",
   	field: "City",
   	mode: window.pca.fieldMode.POPULATE
   }, {
   	element: "beneficiary-postcode",
   	field: "PostalCode",
   	mode: window.pca.fieldMode.POPULATE
   },
   { element: "Country", field: "CountryName", mode: window.pca.fieldMode.COUNTRY }],
   options = {
   	key: "fp61-gw79-xc88-xy19"
   },
   control = new window.pca.Address(fields, options); // jshint ignore:line
   **/
			window.initializeLocationIQ("beneficiary-address", "beneficiary-address2", "Country", "beneficiary-state", "beneficiary-city", "beneficiary-postcode", this.get("isoCountry"), "Address1");
		},
		validateForm: function validateForm(formId) {

			var form = _ember['default'].$(formId);

			form.parsley({
				excluded: 'input[type=button], input[type=submit], input[type=reset]',
				inputs: 'input, textarea, select, input[type=hidden], :hidden'
			}).validate();

			return form.parsley().isValid();
		},
		resetForm: function resetForm(formId) {

			var form = _ember['default'].$(formId);

			form.parsley().reset();
		},
		addPaymentMethod: function addPaymentMethod(formObj) {
			var arrLength = formObj.length;
			var payment = {};

			for (var i = 0; i < arrLength; i++) {
				payment[formObj[i].name] = formObj[i].value;
			}

			delete payment.InstitutionName;

			return payment;
		},
		getIsoCountry: function getIsoCountry(name) {
			var ctr = this.get('countries').filter(function (f) {
				return f.Country.toUpperCase() === name.toUpperCase();
			}).map(function (c) {
				return c.Abbreviation;
			});
			return ctr[0];
		},
		getCurrency: function getCurrency(name) {
			var ctr = this.get('countries').filter(function (f) {
				return f.Country.toUpperCase() === name.toUpperCase();
			}).map(function (c) {
				return c.DefaultCurrency;
			});
			return ctr[0];
		},
		getCountryID: function getCountryID(name) {
			var ctr = this.get('countries').filter(function (f) {
				return f.Country.toUpperCase() === name.toUpperCase();
			}).map(function (c) {
				return c.CountryID;
			});
			return ctr[0];
		},
		getCurrencyName: function getCurrencyName(name) {
			var ctr = this.get('currencies2');
			for (var key in ctr) {
				if (ctr[key].iso3 === name) {
					return ctr[key].name;
				}
			}
		},
		getCountryName: function getCountryName(name) {
			var ctr = this.get('countries').filter(function (f) {
				return f.DefaultCurrency.toUpperCase() === name.toUpperCase();
			}).map(function (c) {
				return c.Country;
			});
			if (ctr[0] == null) {
				return "Canada";
			} else {
				return ctr[0];
			}
		},
		filterCountryList: function filterCountryList(currency) {
			//var filteredCountires = {};

			var ctr = this.get('countries').filter(function (f) {
				return f.DefaultCurrency.toUpperCase() === currency.toUpperCase();
			});
			this.set("countries2", ctr);
		},
		filterList: function filterList(currency, type) {
			this.set("PaymentType", type);
			var type2 = "Bank Account";
			if (this.get("isCredit")) {
				type2 = "Credit Card";
			}
			if (this.get("isInteracEtransfer")) {
				type2 = "Interac E-Transfer";
			}
			if (this.get("isRequest")) {
				type2 = "Money Request";
			}
			var filteredAccounts = {};
			var data = this.get("accounts");
			for (var key in data) {
				if (data[key]["Currency"] === currency && data[key]["Type"] === type2) {
					filteredAccounts[key] = data[key];
				}
			}

			this.set("accounts2", filteredAccounts);
		},
		checkIsAllowed: function checkIsAllowed() {
			return true;
		},
		errorMessage: function errorMessage(Message) {
			this.set("ErrorMessage", Message);
			this.set("isError", true);
		},
		isSchedule: function isSchedule() {
			if (this.get("isOneTime") == true) {

				var startDate = Date($('#ScheduleStartDate').val());
				$('#ScheduleStartDate').val();
				if ($('#ScheduleStartDate').val()) {

					if (moment().format("YYYY-MM-DD") >= $('#ScheduleStartDate').val()) {
						// Date equals today's date
						return false;
					} else {
						return true;
					}
				} else {
					return false;
				}
			} else {
				return true;
			}
		},
		actions: {

			afterSelectPurposes: function afterSelectPurposes(purpose) {
				_ember['default'].$("#purpose").parsley().reset();
				this.set('purpose', purpose);
			},
			closeModal: function closeModal() {
				this.set("isShowingModal", false);
				return false;
			},
			closeModalFlinks: function closeModalFlinks() {
				this.set("isFlinks", false);
				clearInterval(window.flinksInterval);
			},
			closeModalError: function closeModalError() {
				this.set("isError", false);
			},
			recipientCountryChanged: function recipientCountryChanged(country) {

				this.set("showRecipient", false);
				this.set('country', country);
				this.set("isoCountry", this.getIsoCountry(country));
				this.set('countryID', this.getCountryID(country));
			},
			recipientCurrencyChanged: function recipientCurrencyChanged(currency) {
				var _this3 = this;

				this.set("currency2", currency);
				var country = this.get("country");
				var optionsBalance = {};
				optionsBalance.Currency = currency;
				var country = this.getCountryName(currency);
				this.set('country', country);
				this.set("isoCountry", this.getIsoCountry(country));
				this.set('countryID', this.getCountryID(country));
				this.filterCountryList(currency);
				this.set("isInteracEtransfer", false);
				this.set("isCredit", true);
				this.set("isCredit", false);
				this.set("isRequest", false);
				this.set("currentSelection", "");
				this.filterList(currency);

				this.get('api').getAccountBalance(optionsBalance).then(function (data) {
					if (Number(data.AvailableFunds) <= 0) {
						_this3.set("max", 0);
						_this3.set("amount", "0.00");
						_this3.set("amount2", "0.00");
					} else {
						_this3.set("max", data.AvailableFunds);
						_this3.set("amount", "0.00");
						_this3.set("amount2", "0.00");
					}
				});

				this.set("currencyName", this.getCurrencyName(currency));
			},
			addSignature: function addSignature() {
				this.set("signatureCurrNum", this.get("signatureCurrNum") + 1);
				this.get('signatureNumbers').pushObject(this.get("signatureCurrNum"));
			},
			removeSignature: function removeSignature() {
				this.set('signatureNumbers', this.get('signatureNumbers').slice(0, -1));
				this.set("signatureCurrNum", this.get("signatureCurrNum") - 1);
			},
			showTerms: function showTerms() {
				_ember['default'].$("#termsConditions").show();
				_ember['default'].$("#overlay").show();
				this.set("isAgree", false);
				_ember['default'].$("#isAgree").parsley().reset();
			},
			backTerms: function backTerms() {
				_ember['default'].$("#termsConditions").hide();
				_ember['default'].$("#overlay").hide();
				this.set("isAgree", false);
				_ember['default'].$("#isAgree").parsley().reset();
			},
			AgreeToTerms: function AgreeToTerms() {

				_ember['default'].$("#overlay").hide();
				_ember['default'].$("#termsConditions").hide();
				this.set("agreedToTerms", true);
				this.set("isAgree", true);
				_ember['default'].$("#isAgree").parsley().reset();
			},
			selectAccount: function selectAccount(value) {
				var _this4 = this;

				if (value === "New") {
					var myVar;

					(function () {
						_this4.set("isNew", true);
						var self = _this4;
						myVar = setTimeout(function () {
							self.initialiseAutoAddress();
							clearTimeout(myVar);
						}, 1000);
					})();
				} else {
					this.set("isNew", false);
					var data = this.get("accounts");
					var paymentToAdd = data[value];
					//paymentToAdd.DollarAmount = Ember.$("#amount").val().replace(/\,/g,"");
					this.set("paymentToAdd", paymentToAdd);
				}
			},
			moveToEtransfer: function moveToEtransfer() {
				$("#amount").parsley().validate();

				if (this.checkIsAllowed() && $("#amount").parsley().isValid({ force: true })) {
					_ember['default'].$("#Overview").hide();
					this.set("isOverview", false);
					_ember['default'].$("#paymentInfo").hide();
					_ember['default'].$("#benifeciaryInfo").hide();
					_ember['default'].$("#etransfer").show();
					this.set("stageNumber", 2);
					this.set("stageName", "Account Details");
				}
			},
			moveToBank: function moveToBank() {
				var _this5 = this;

				if (this.validateForm('#paymentForm') || this.get("isFlinks2")) {
					(function () {
						_this5.SetIq11URL();
						_this5.set("isFlinks", true);
						var self = _this5;
						window.flinksInterval = setInterval(function () {
							if (localStorage.getItem("FlinksToken") != null) {
								var paymentFormArr = _ember['default'].$('#paymentForm').serializeArray();
								self.set('paymentToAdd', self.addPaymentMethod(paymentFormArr));
								self.set('paymentToAdd.AutoBalanceTransferAmount', self.get("amount").replace(/,/g, ''));
								if (localStorage.getItem("institution") && localStorage.getItem("institution") != "undefined") {
									self.set('paymentToAdd.InstitutionName', localStorage.getItem("institution"));
									self.set("paymentToAdd.FinancialInstitutionNumber", "");
								} else {
									var finNumber = localStorage.getItem("InstitutionNumber");
									var bank = _vopay_appConstants['default'].canadaBanks.filter(function (b) {
										return parseInt(b.code) === parseInt(finNumber);
									});

									if (bank[0]) {
										self.set('paymentToAdd.InstitutionName', bank[0].name);
									} else {
										self.set('paymentToAdd.InstitutionName', "");
									}
									self.set("paymentToAdd.FinancialInstitutionNumber", finNumber);
								}
								if (self.get("country") === "Canada") {
									self.set("paymentToAdd.Country", "CA");
								} else {
									self.set("paymentToAdd.Country", self.get("isoCountry"));
								}
								if (self.get("paymentToAdd.Iban")) {
									self.set("paymentToAdd.Iban", self.get("isoCountry") + "" + self.get("paymentToAdd.Iban"));
								}

								self.set("paymentToAdd.Currency", self.get("currency2"));
								self.set("paymentToAdd.Notes", _ember['default'].$("#Notes").val());

								self.set("paymentToAdd.Token", localStorage.getItem("FlinksToken"));
								if (localStorage.getItem("AccountNumber")) {
									self.set('paymentToAdd.MaskedAccount', localStorage.getItem("AccountNumber"));
								} else {
									self.set("paymentToAdd.MaskedAccount", localStorage.getItem("MaskedAccount"));
								}
								if (localStorage.getItem("BankId")) {
									self.set('paymentToAdd.BankId', localStorage.getItem("BankId"));
								}

								self.set('paymentToAdd.TypeOfFrequency', self.get("NameOfFrequency"));
								self.set("paymentToAdd.Frequency", self.get("Frequency"));
								if (self.get("currentDate") != null) {

									self.set('paymentToAdd.ScheduleStartDate', self.get("currentDate"));
								}
								localStorage.removeItem("InstitutionNumber");
								self.set("paymentToAdd.BranchTransitNumber", "");
								self.set("paymentToAdd.AccountNumber", "");
								localStorage.removeItem("BankId");
								localStorage.removeItem("AccountNumber");
								self.set("paymentToAdd.FullName", localStorage.getItem("FullName"));
								localStorage.removeItem("TokenLocation");
								localStorage.removeItem("FlinksToken");
								localStorage.removeItem("MaskedAccount");
								localStorage.removeItem("FullName");
								localStorage.removeItem("accountId");
								localStorage.removeItem("loginId");
								localStorage.removeItem("institution");
								_ember['default'].$("#paymentInfo").hide();
								_ember['default'].$("#benifeciaryInfo").hide();
								_ember['default'].$("#bankInfo").hide();
								_ember['default'].$("#Overview").show();
								self.set("isOverview", true);
								_ember['default'].$("#etransfer").hide();
								self.set("isFlinks2", true);
								self.set("isFlinks", false);
								clearInterval(window.flinksInterval);
							}
						}, 100);
						/**
       		this.resetForm('#paymentForm');
       		this.set("isBank", true);
       		this.get("isFlinks2", false)
      Ember.$("#Overview").hide();
      this.set("isOverview", false);
        	Ember.$("#paymentInfo").hide();
        	Ember.$("#benifeciaryInfo").hide();
        	Ember.$("#bankInfo").show();
        	this.set("stageNumber", 3);
        	this.set("stageName", "Bank Details");
        	Ember.$("#accountInfo").hide();**/
					})();
				}
			},
			moveToAccount: function moveToAccount() {
				$("#MinAmountBalance").parsley().validate();
				//$("#MinAmountToTransfer").parsley().isValid({force: true})
				if (this.get("isCollect") === "true") {
					$("#purpose").parsley().validate();
				}

				if (this.checkIsAllowed() && $("#MinAmountBalance").parsley().isValid({ force: true })) {
					if (Number(this.get("amount").replace(/,/g, '')) <= Number(this.get("max")) || this.get("isWithdraw") !== "true") {
						if (this.get("isCollect") === "true") {
							if ($("#purpose").parsley().isValid({ force: true })) {
								_ember['default'].$("#Overview").hide();
								this.set("isOverview", false);
								_ember['default'].$("#benifeciaryInfo").hide();
								_ember['default'].$("#accountInfo").show();
								_ember['default'].$("#paymentInfo").hide();
								_ember['default'].$("#bankInfo").hide();
								this.set("isBank", false);
								this.set("stageNumber", 2);
								this.set("stageName", "Account Holder Details");
							}
						} else {
							_ember['default'].$("#Overview").hide();
							this.set("isOverview", false);
							_ember['default'].$("#benifeciaryInfo").hide();
							_ember['default'].$("#accountInfo").show();
							_ember['default'].$("#paymentInfo").hide();
							_ember['default'].$("#bankInfo").hide();
							this.set("isBank", false);
							this.set("stageNumber", 2);
							this.set("stageName", "Account Holder Details");
						}
					}
				}
			},
			moveToRequest: function moveToRequest() {
				_ember['default'].$("#Overview").hide();
				this.set("isOverview", false);
				_ember['default'].$("#benifeciaryInfo").hide();
				_ember['default'].$("#accountInfo").hide();
				_ember['default'].$("#paymentInfo").hide();
				_ember['default'].$("#bankInfo").hide();
				_ember['default'].$("#etransfer").hide();
				_ember['default'].$("#request").show();
				this.set("stageNumber", 2);
				this.set("stageName", "Account Details");
			},
			moveToPayment: function moveToPayment() {

				_ember['default'].$("#Overview").hide();
				this.set("isOverview", false);
				_ember['default'].$("#benifeciaryInfo").hide();
				_ember['default'].$("#paymentInfo").show();
				_ember['default'].$("#bankInfo").hide();
				_ember['default'].$("#etransfer").hide();
				_ember['default'].$("#request").hide();
				this.set("isBank", false);
				this.set("stageNumber", 1);

				if (this.get("isCollect") === "true") {
					this.set("stageName", "Balance Transfer Details");
				} else {
					this.set("stageName", "Balance Transfer Details");
				}

				_ember['default'].$("#accountInfo").hide();
			},
			moveToBenifeciary: function moveToBenifeciary() {
				_ember['default'].$("#beneficiary-address").focusout(function () {

					setTimeout(function () {
						var address = _ember['default'].$("#beneficiary-address").val();
						var city = _ember['default'].$("#beneficiary-city").val();
						var state = _ember['default'].$("#beneficiary-state").val();
						var postcode = _ember['default'].$("#beneficiary-postcode").val();

						if (address) {
							_ember['default'].$("#beneficiary-address").parsley().reset();
						}
						if (city) {
							_ember['default'].$("#beneficiary-city").parsley().reset();
						}
						if (state) {
							_ember['default'].$("#beneficiary-state").parsley().reset();
						}
						if (postcode) {
							_ember['default'].$("#beneficiary-postcode").parsley().reset();
						}
					}, 500);
				});
				_ember['default'].$("#beneficiary-address").keyup(function () {
					if (!_ember['default'].$("#beneficiary-address").val()) {
						_ember['default'].$("#beneficiary-city").val("");
						_ember['default'].$("#beneficiary-postcode").val("");
						_ember['default'].$("#beneficiary-address").val("");
						_ember['default'].$("#beneficiary-state").val("");
					}
				});
				_ember['default'].$("#beneficiary-city").keyup(function () {
					if (!_ember['default'].$("#beneficiary-city").val()) {
						_ember['default'].$("#beneficiary-city").val("");
						_ember['default'].$("#beneficiary-postcode").val("");
						_ember['default'].$("#beneficiary-address").val("");
						_ember['default'].$("#beneficiary-state").val("");
					}
				});
				_ember['default'].$("#beneficiary-postcode").keyup(function () {
					if (!_ember['default'].$("#beneficiary-postcode").val()) {
						_ember['default'].$("#beneficiary-city").val("");
						_ember['default'].$("#beneficiary-postcode").val("");
						_ember['default'].$("#beneficiary-address").val("");
						_ember['default'].$("#beneficiary-state").val("");
					}
				});
				var self = this;
				_ember['default'].$("#beneficiary-address").on('input', function () {

					_ember['default'].$("#beneficiary-address").val(self.get('entryUtil').noSpecialCharacter(_ember['default'].$("#beneficiary-address").val()));
				});
				_ember['default'].$("#beneficiary-city").on('input', function () {

					_ember['default'].$("#beneficiary-city").val(self.get('entryUtil').noSpecialCharacter(_ember['default'].$("#beneficiary-city").val()));
				});
				_ember['default'].$("#beneficiary-postcode").on('input', function () {

					_ember['default'].$("#beneficiary-postcode").val(self.get('entryUtil').onlyAlphaNumeric(_ember['default'].$("#beneficiary-postcode").val()));
				});
				if (Number(this.get("amount").replace(/,/g, '')) <= Number(this.get("max")) || this.get("isWithdraw") !== "true") {

					_ember['default'].$("#MinAmountBalance").parsley().validate();
					//Ember.$("#MinAmountToTransfer").parsley().isValid({force: true})

					if (_ember['default'].$("#MinAmountBalance").parsley().isValid({ force: true })) {
						_ember['default'].$("#Overview").hide();
						this.set("isOverview", false);
						_ember['default'].$("#paymentInfo").hide();
						_ember['default'].$("#benifeciaryInfo").show();
						_ember['default'].$("#bankInfo").hide();
						this.set("isBank", false);
						this.get("isFlinks2", false);
						this.set("stageNumber", 2);
						if (this.get("isCollect") === "true") {
							this.set("stageName", "Account Holder Details");
						} else {
							this.set("stageName", "Account Holder Details");
						}

						_ember['default'].$("#accountInfo").hide();
					}
				}
			},
			handleAccountOptions: function handleAccountOptions(value) {

				if (value === "Withdraw") {
					this.set("isDeposit", "false");
					while (this.get("signatureCurrNum") > 0) {
						this.set('signatureNumbers', this.get('signatureNumbers').slice(0, -1));
						this.set("signatureCurrNum", this.get("signatureCurrNum") - 1);
					}
					this.set("signatureCurrNum", this.get("signatureCurrNum") + 1);
					this.get('signatureNumbers').pushObject(this.get("signatureCurrNum"));
					this.set('signatureNumDel', 1);
				} else if (value === "Both") {
					this.set("isDeposit", true);
					this.set('signatureNumDel', 0);
					while (this.get("signatureCurrNum") > 0) {
						this.set('signatureNumbers', this.get('signatureNumbers').slice(0, -1));
						this.set("signatureCurrNum", this.get("signatureCurrNum") - 1);
					}
					this.set('signatureNumDel', 1);
					this.set("signatureCurrNum", this.get("signatureCurrNum") + 1);
					this.get('signatureNumbers').pushObject(this.get("signatureCurrNum"));
				} else {
					this.set("isDeposit", "true");
				}
			},
			handleIfFlinks: function handleIfFlinks(value) {
				var _this6 = this;

				if (value === "Online Banking") {
					(function () {
						_this6.SetIq11URL();
						_this6.set("isFlinks", true);
						var self = _this6;
						window.flinksInterval = setInterval(function () {
							if (localStorage.getItem("FlinksToken") != null) {
								var paymentFormArr = _ember['default'].$('#paymentForm').serializeArray();
								self.set('paymentToAdd', self.addPaymentMethod(paymentFormArr));
								self.set('paymentToAdd.AutoBalanceTransferAmount', self.get("amount").replace(/,/g, ''));
								if (localStorage.getItem("institution") && localStorage.getItem("institution") != "undefined") {
									self.set('paymentToAdd.InstitutionName', localStorage.getItem("institution"));
									self.set("paymentToAdd.FinancialInstitutionNumber", "");
								} else {
									var finNumber = localStorage.getItem("InstitutionNumber");
									var bank = _vopay_appConstants['default'].canadaBanks.filter(function (b) {
										return parseInt(b.code) === parseInt(finNumber);
									});

									if (bank[0]) {
										self.set('paymentToAdd.InstitutionName', bank[0].name);
									} else {
										self.set('paymentToAdd.InstitutionName', "");
									}
									self.set("paymentToAdd.FinancialInstitutionNumber", finNumber);
								}
								if (self.get("country") === "Canada") {
									self.set("paymentToAdd.Country", "CA");
								} else {
									self.set("paymentToAdd.Country", self.get("isoCountry"));
								}
								if (self.get("paymentToAdd.Iban")) {
									self.set("paymentToAdd.Iban", self.get("isoCountry") + "" + self.get("paymentToAdd.Iban"));
								}

								self.set("paymentToAdd.Currency", self.get("currency2"));
								self.set("paymentToAdd.Notes", _ember['default'].$("#Notes").val());

								self.set("paymentToAdd.Token", localStorage.getItem("FlinksToken"));
								if (localStorage.getItem("AccountNumber")) {
									self.set('paymentToAdd.MaskedAccount', localStorage.getItem("AccountNumber"));
								} else {
									self.set("paymentToAdd.MaskedAccount", localStorage.getItem("MaskedAccount"));
								}
								if (localStorage.getItem("BankId")) {
									self.set('paymentToAdd.BankId', localStorage.getItem("BankId"));
								}

								self.set('paymentToAdd.TypeOfFrequency', self.get("NameOfFrequency"));
								self.set("paymentToAdd.Frequency", self.get("Frequency"));
								if (self.get("currentDate") != null) {

									self.set('paymentToAdd.ScheduleStartDate', self.get("currentDate"));
								}
								localStorage.removeItem("InstitutionNumber");
								self.set("paymentToAdd.BranchTransitNumber", "");
								self.set("paymentToAdd.AccountNumber", "");
								localStorage.removeItem("BankId");
								localStorage.removeItem("AccountNumber");
								self.set("paymentToAdd.FullName", localStorage.getItem("FullName"));
								localStorage.removeItem("TokenLocation");
								localStorage.removeItem("FlinksToken");
								localStorage.removeItem("MaskedAccount");
								localStorage.removeItem("FullName");
								localStorage.removeItem("accountId");
								localStorage.removeItem("loginId");
								localStorage.removeItem("institution");
								_ember['default'].$("#paymentInfo").hide();
								_ember['default'].$("#benifeciaryInfo").hide();
								_ember['default'].$("#bankInfo").hide();
								_ember['default'].$("#Overview").show();
								self.set("isOverview", true);
								_ember['default'].$("#etransfer").hide();
								self.set("isFlinks2", true);
								self.set("isFlinks", false);
								clearInterval(window.flinksInterval);
							}
						}, 100);
					})();
				} else {
					this.set("isFlinks", false);
					clearInterval(window.flinksInterval);
				}
			},
			handleAccountType: function handleAccountType(value) {
				if (value === "Business") {
					this.set("isBusiness", true);
				} else {
					this.set("isBusiness", false);
				}
			},
			handleFrequencyType: function handleFrequencyType(value) {

				if (value === "One Time") {
					this.set("isOneTime", true);
					this.set("Frequency", "single");
					this.set("currentEndDateValid", true);
				} else {
					this.set("isOneTime", false);
					this.set("Frequency", "recurring");
				}
			},
			handleFrequencyNameType: function handleFrequencyNameType(value) {

				if (value == "Daily") {
					this.set("NameOfFrequency", "daily");
				} else if (value == "Monthly") {
					this.set("NameOfFrequency", "monthly");
				} else if (value == "Weekly") {
					this.set("NameOfFrequency", "weekly");
				} else if (value == "Bi-Weekly") {
					this.set("NameOfFrequency", "biweekly");
				} else if (value == "Quarterly") {
					this.set("NameOfFrequency", "3 months");
				} else if (value == "Semi-Annually") {
					this.set("NameOfFrequency", "6 months");
				} else if (value == "Annually") {
					this.set("NameOfFrequency", "yearly");
				} else if (value == "Bi-Monthly") {
					this.set("NameOfFrequency", "bimonthly");
				} else {
					this.set("NameOfFrequency", "monthly");
				}
			},
			handleEndDate: function handleEndDate(value) {
				if (value == "End Date") {
					this.set("isEndDate", true);
					this.set("isPayments", false);
				} else {
					this.set("isEndDate", false);
					this.set("isPayments", true);
				}
			},
			handlePaymentType: function handlePaymentType(value) {
				if (value === "Interac E-Transfer" || value == "Interac E-Transfer Money Request") {
					this.set("isInteracEtransfer", true);
				} else {
					this.set("isInteracEtransfer", false);
				}
				if (value === "Credit Card") {

					this.set("isCredit", true);
				} else {
					this.set("isCredit", false);
				}
				if (value == "Money Request") {
					this.set("isRequest", true);
				} else {
					this.set("isRequest", false);
				}
				if (value == "Bank Account") {
					this.set("formatBank", true);
				} else {
					this.set("formatBank", false);
				}
				if (this.get("country") === "Canada") {
					this.filterList("CAD");
				} else {
					this.filterList(this.get("currency2"));
				}
				this.set("currentSelection", "");
			},

			updateInstitutionName: function updateInstitutionName() {

				var finNumber = this.get('financialInstitutionNumber');
				this.set('financialInstitutionNumber', (0, _vopay_appHelpersCompleteWithZeros.completeWithZeros)(["l", finNumber, 3]));

				var bank = _vopay_appConstants['default'].canadaBanks.filter(function (b) {
					return parseInt(b.code) === parseInt(finNumber);
				});

				var eClass = _ember['default'].$(".form__bankFlagIcon").attr("class").match(/b[0-9]{3}/);

				if (eClass) {
					_ember['default'].$(".form__bankFlagIcon").removeClass('' + eClass[0]);
				}

				if (bank[0]) {

					_ember['default'].$(".form__bankFlagIcon").addClass('b' + (0, _vopay_appHelpersCompleteWithZeros.completeWithZeros)(["l", finNumber, 3]));
					this.set('institutionName', bank[0].name);
					_ember['default'].$("#institutionName").parsley().reset();
					_ember['default'].$("#financialInstitutionNumber").parsley().reset();
					_ember['default'].$(".newPaymentMethod__institution").slideDown();
				} else {
					_ember['default'].$("#paymentForm").parsley().destroy();
					_ember['default'].$('form').parsley({
						successClass: " ",
						errorClass: "form__input--error",
						errorsWrapper: "<p></p>",
						errorTemplate: "<span class='form__message'></span>"
					});
					_ember['default'].$(".newPaymentMethod__institution").slideUp();
					_ember['default'].$(".form__bankFlagIcon").addClass('b0');
					this.set('financialInstitutionNumber', '');
					this.set('institutionName', '');
					_ember['default'].$("#financialInstitutionNumber").val('');
					if (!finNumber) {
						_ember['default'].$("#financialInstitutionNumber").parsley().reset();
					} else {
						_ember['default'].$("#financialInstitutionNumber").parsley().validate();
					}
					// Ember.$("#financialInstitutionNumber").focus();
				}
			},
			cancel: function cancel() {
				if (sessionStorage.getItem("returnUrl")) {
					var url = sessionStorage.getItem("returnUrl");
					sessionStorage.removeItem("returnUrl");
					window.location.href = url;
				} else {
					this.get("routing").transitionTo("dashboard.info");
				}
			},
			moveToOverview: function moveToOverview() {
				//
				if (this.checkIsAllowed() && this.get("isWithdraw") !== "true") {

					if (this.get("isNew")) {
						if (this.validateForm('#paymentForm')) {
							if ((this.get("isAgree") || this.get("isWithdraw") === "true") && this.checkIsAllowed()) {

								var paymentFormArr = _ember['default'].$('#paymentForm').serializeArray();
								this.set('paymentToAdd', this.addPaymentMethod(paymentFormArr));
								this.set('paymentToAdd.AutoBalanceTransferAmount', this.get("amount").replace(/,/g, '').replace(/[\u00a0]/g, "").replace(/[\u202f]/g, ""));

								if (this.get("country") === "Canada") {
									this.set("paymentToAdd.Country", "CA");
								} else {
									this.set("paymentToAdd.Country", this.get("isoCountry"));
								}
								if (this.get("paymentToAdd.Iban")) {
									this.set("paymentToAdd.Iban", this.get("isoCountry") + "" + this.get("paymentToAdd.Iban"));
								}

								this.set("paymentToAdd.Currency", this.get("currency2"));
								this.set('paymentToAdd.TypeOfFrequency', this.get("NameOfFrequency"));
								this.set("paymentToAdd.Frequency", this.get("Frequency"));
								if (this.get("currentDate") != null) {

									this.set('paymentToAdd.ScheduleStartDate', this.get("currentDate"));
								}
								if (this.get("currentDateValid")) {
									_ember['default'].$("#paymentInfo").hide();
									_ember['default'].$("#benifeciaryInfo").hide();
									_ember['default'].$("#bankInfo").hide();
									_ember['default'].$("#Overview").show();
									this.set("isOverview", true);
									_ember['default'].$("#etransfer").hide();
									_ember['default'].$("#request").hide();
								}
							}
						}
					} else {
						if (this.validateForm('#paymentForm')) {
							if ((this.get("isAgree") || this.get("isWithdraw") === "true") && this.checkIsAllowed()) {

								var paymentFormArr = _ember['default'].$('#paymentForm').serializeArray();
								var addPaymentMethod = this.addPaymentMethod(paymentFormArr);
								if (this.get("country") === "Canada") {
									this.set("paymentToAdd.Country", "CA");
								} else {
									this.set("paymentToAdd.Country", this.get("isoCountry"));
								}
								if (this.get("paymentToAdd.Iban")) {
									this.set("paymentToAdd.Iban", this.get("isoCountry") + "" + this.get("paymentToAdd.Iban"));
								}
								this.set('paymentToAdd.AutoBalanceTransferAmount', this.get("amount").replace(/,/g, '').replace(/[\u00a0]/g, "").replace(/[\u202f]/g, ""));
								//this.set('paymentToAdd.MinAmountToTransfer', this.get("amount2").replace(/,/g, '').replace(/[\u00a0]/g, "").replace(/[\u202f]/g,""));
								this.set("paymentToAdd.Frequency", this.get("Frequency"));
								this.set("paymentToAdd.Currency", this.get("currency2"));
								this.set('paymentToAdd.NameOfFrequency', this.get("NameOfFrequency"));
								if (this.get("currentDate") != null) {

									this.set('paymentToAdd.ScheduleStartDate', this.get("currentDate"));
								}
								this.set('paymentToAdd.TypeOfFrequency', this.get("NameOfFrequency"));

								if (this.get("currentDateValid")) {
									_ember['default'].$("#paymentInfo").hide();
									_ember['default'].$("#benifeciaryInfo").hide();
									_ember['default'].$("#bankInfo").hide();
									_ember['default'].$("#Overview").show();
									this.set("isOverview", true);
									_ember['default'].$("#etransfer").hide();
									_ember['default'].$("#request").hide();
								}
							}
						}
					}
				}
			},
			dateChanged: function dateChanged(value, valid) {
				this.set("currentDate", value);
				this.set("currentDateValid", valid);
			},
			dateChangedEnd: function dateChangedEnd(value, valid) {
				this.set("currentEndDate", value);
				this.set("currentEndDateValid", valid);
			},
			create: function create() {
				var _this7 = this;

				if (this.get("isAgree") || this.get("isWithdraw") === "true" || this.get("isFlinks2")) {

					if (this.validateForm('#paymentForm') || this.get("isFlinks2")) {
						this.get("api").setAutoBankTransfer(this.get("paymentToAdd")).then(function (transaction) {
							_ember['default'].$('#paymentForm button').removeAttr('disabled');
							if (_this7.get("isStore")) {
								var data = _this7.get("paymentToAdd");
								if (_this7.get("isCredit")) {
									data.Type = "Credit Card";
								} else {
									data.Type = "Bank Account";
								}

								//this.set("paymentToAdd.ScheduledTransactionID", transaction.ScheduledTransactionID);
								//var key = this.get("firebase").createRecordUser("reoccuringTransactions", this.get("paymentToAdd"));
								delete data.DollarAmount;
								if (data.ScheduleStartDate) {
									delete data.ScheduleStartDate;
								}
								if (data.NameOfFrequency) {
									delete data.NameOfFrequency;
								}
								if (data.EndingAfterPayments) {
									delete data.EndingAfterPayments;
								}
								if (data.ScheduleEndDate) {
									delete data.ScheduleEndDate;
								}
								if (data.Frequency) {
									delete data.Frequency;
								}

								if (_this7.get("isCollect") === "true") {
									if (_this7.get("isNew")) {
										_this7.get("firebase").createRecordUser("beneficiary", data);
									}
								} else {
									if (_this7.get("isNew")) {
										if (_this7.get("isFlinks2")) {
											data.OuthType = "iQ11";
											_this7.get("firebase").createRecordUser("linkedAccount", data);
										} else {
											var id = _this7.get("firebase").createRecordUser("accounts", data);
											if (_this7.get("isBusiness")) {
												/**
                                                var input = document.getElementById('uploadFile');
                                                for(var i = 0; i<input.files.length; i++){
                                                    var file = input.files[i];
                                                    this.get("firebase").addFileUser("accounts",id, file);
            }
            **/
											}
										}
									}
								}
							}
							localStorage.setItem("link", window.location.href);

							window.location.href = "#/dashboard/balance-transfer";
						}, function (error) {
							var data2 = _this7.get("paymentToAdd");
							if (_this7.get("isCollect") === "true") {
								_this7.errorMessage(error);
								_ember['default'].$('.complete').removeAttr('disabled');
							} else {
								if ((_this7.get("isFlinks2") || data2.OuthType == "Flinks" || data2.OuthType === "iQ11") && _this7.get("fundCount") != 3 && (error === "Operation is still pending" || error === "Operation had been dispatched to background process" || error === "Connection could not be established to the Bank" || error === "Online Banking Authentication Failed")) {
									_this7.set("fundCount", _this7.get("fundCount") + 1);

									var self = _this7;
									setTimeout(function () {
										self.send('create');
									}, 15000);
								} else {
									_this7.errorMessage(error);
									_this7.set("fundCount", 0);
									_ember['default'].$('.complete').removeAttr('disabled');
								}
							}
						});
					}
				}
			}

		}
	});
});