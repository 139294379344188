define('vopay_app/components/uploaded-file-details-list', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		routing: _ember['default'].inject.service('-routing'),
		api: _ember['default'].inject.service('api'),
		pageSize: 20,
		pageNumber: 1,
		recordCount: null,

		init: function init() {
			this._super.apply(this, arguments);
			this.set("filteredList", this.get("details"));

			var id_label = this.get("file")[0].transaction_type == 'Paylink' ? 'Paylink Request ID' : 'Transaction ID';
			this.set("id_label", id_label);

			this.addScheduleTransactionId();

			this.set("header", this.generateHeader());
		},

		didInsertElement: function didInsertElement() {
			var autoRefresh = sessionStorage.getItem("autoRefresh");
			autoRefresh ? this.actions.setRefresh(autoRefresh) : this.actions.setRefresh("0");
		},

		addScheduleTransactionId: function addScheduleTransactionId() {
			var details = this.get("filteredList");
			var submit_dt = new Date(this.get("file")[0].created_at);
			this.set("addScheduleTransactionID", false);

			for (var key in details) {
				if (!details[key].transaction_payload) continue;

				var transaction_dt = new Date(details[key].transaction_payload.ScheduleStartDate);

				if (transaction_dt > submit_dt) {
					details[key].schedule_transaction_id = details[key].transaction_id;
					details[key].transaction_id = '';
					this.set("addScheduleTransactionID", true);
				} else {
					details[key].schedule_transaction_id = '';
				}
			}
		},

		generateHeader: function generateHeader() {
			var header = {};
			var details = this.get("filteredList");

			details.forEach(function (detail) {
				var payload = detail.transaction_payload;
				for (var key in payload) {
					if (key in header) continue;
					header[key] = key;
				}
			});
			return header;
		},

		actions: {

			sortTable: function sortTable(column) {
				var table,
				    header,
				    columnHeader,
				    rows,
				    currentSortIcon,
				    sortIcons,
				    switching,
				    i,
				    x,
				    y,
				    shouldSwitch,
				    dir,
				    switchcount = 0;
				table = document.getElementById("fileUploadDetails");
				switching = true;

				// Set the sorting direction to ascending:
				dir = "asc";
				header = table.rows[0];
				sortIcons = header.getElementsByTagName('i');
				columnHeader = header.getElementsByClassName(column)[0];
				currentSortIcon = columnHeader.getElementsByTagName('i')[0];

				/* Make a loop that will continue until
    no switching has been done: */
				while (switching) {
					// Start by saying: no switching is done:
					switching = false;
					rows = table.rows;

					/* Loop through all table rows (except the
     first, which contains table headers): */
					for (i = 1; i < rows.length - 1; i++) {
						// Start by saying there should be no switching:
						shouldSwitch = false;

						/* Get the two elements you want to compare,
      one from current row and one from the next: */
						x = rows[i].getElementsByClassName(column)[0];
						y = rows[i + 1].getElementsByClassName(column)[0];

						/* Check if the two rows should switch place,
      based on the direction, asc or desc: */
						if (dir == "asc") {
							if (x.innerHTML > y.innerHTML) {
								// If so, mark as a switch and break the loop:
								shouldSwitch = true;
								break;
							}
						} else if (dir == "desc") {
							if (x.innerHTML < y.innerHTML) {
								// If so, mark as a switch and break the loop:
								shouldSwitch = true;
								break;
							}
						}
					}

					if (shouldSwitch) {
						/* If a switch has been marked, make the switch
      and mark that a switch has been done: */
						rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
						switching = true;
						// Each time a switch is done, increase this count by 1:
						switchcount++;
					} else {
						/* If no switching has been done AND the direction is "asc",
      set the direction to "desc" and run the while loop again. */
						if (switchcount == 0 && dir == "asc") {
							dir = "desc";
							switching = true;
						}
					}
				}

				// update sort icons
				var _iteratorNormalCompletion = true;
				var _didIteratorError = false;
				var _iteratorError = undefined;

				try {
					for (var _iterator = sortIcons[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
						var icon = _step.value;

						icon.classList.add('fa-sort');
						icon.classList.remove('fa-sort-up');
						icon.classList.remove('fa-sort-down');
					}
				} catch (err) {
					_didIteratorError = true;
					_iteratorError = err;
				} finally {
					try {
						if (!_iteratorNormalCompletion && _iterator['return']) {
							_iterator['return']();
						}
					} finally {
						if (_didIteratorError) {
							throw _iteratorError;
						}
					}
				}

				dir == 'asc' ? currentSortIcon.classList.add('fa-sort-up') : currentSortIcon.classList.add('fa-sort-down');
			},

			setRefresh: function setRefresh(refreshTime) {

				clearTimeout(sessionStorage.getItem("timeout"));
				sessionStorage.removeItem("autoRefresh");

				if (refreshTime != 0) {
					var autoRefresh = setTimeout(function () {
						location.reload();
					}, 1000 * refreshTime);
					sessionStorage.setItem("timeout", autoRefresh);
					sessionStorage.setItem("autoRefresh", refreshTime);
				}

				var previousLink = document.getElementsByClassName("refresh__link__unclickable");
				if (previousLink.length) {
					previousLink[0].classList.add("refresh__link");
					previousLink[0].classList.remove("refresh__link__unclickable");
				}

				var link = document.getElementById("refresh-" + refreshTime);
				if (link) {
					link.classList.remove("refresh__link");
					link.classList.add("refresh__link__unclickable");
				}
			},

			returnToFileHistory: function returnToFileHistory() {
				clearTimeout(sessionStorage.getItem("timeout"));
				sessionStorage.removeItem("autoRefresh");
				window.location.replace("#/dashboard/file-upload/file-history");
			},

			csv: function csv() {
				_ember['default'].$('#fileUploadDetails').addClass("importantShow");
				_ember['default'].$('#fileUploadDetails').tableExport({ type: 'csv', escape: 'false', consoleLog: 'true' });
				_ember['default'].$('#fileUploadDetails').removeClass("importantShow");
				return false;
			}
		}

	});
});