define('vopay_app/initializers/parsley-attributes', ['exports', 'ember'], function (exports, _ember) {
				exports.initialize = initialize;

				function initialize() {
								// application.inject('route', 'foo', 'service:foo');
								_ember['default'].TextField.reopen({
												attributeBindings: ['data-parsley-required', 'data-parsley-type', 'data-parsley-minlength', 'data-parsley-maxlength', 'data-parsley-length', 'data-parsley-min', 'data-parsley-max', 'data-parsley-range', 'data-parsley-pattern', 'data-parsley-mincheck', 'data-parsley-maxcheck', 'data-parsley-check', 'data-parsley-equalto', 'data-parsley-trigger', 'data-parsley-trigger-after-failure', 'data-parsley-no-focus', 'data-parsley-validation-threshold', 'data-parsley-class-handler', 'data-parsley-errors-container', 'data-parsley-error-message', 'data-parsley-creditcard', 'data-parsley-iban', 'data-parsley-required', 'data-parsley-required-message', 'data-parsley-type-message', 'data-parsley-length-message', 'data-parsley-pattern-message', 'data-parsley-maxlength-message', 'data-parsley-minlength-message', 'data-parsley-iban-message', 'data-parsley-trigger-after-failure', 'data-parsley-birthdate', 'data-parsley-date', "data-parsley-mindate", 'data-parsley-validation-threshold', 'data-parsley-aba', 'data-parsley-minamount', 'data-parsley-maxamount', 'data-parsley-min-message', 'data-parsley-max-message', 'data-parsley-protocol', 'data-parsley-protocol-message', 'data-parsley-filemimetypes', 'data-parsley-filesize']
								});
								_ember['default'].Checkbox.reopen({
												attributeBindings: ['data-parsley-required', 'data-parsley-type', 'data-parsley-minlength', 'data-parsley-maxlength', 'data-parsley-length', 'data-parsley-min', 'data-parsley-max', 'data-parsley-range', 'data-parsley-pattern', 'data-parsley-mincheck', 'data-parsley-maxcheck', 'data-parsley-check', 'data-parsley-equalto', 'data-parsley-trigger', 'data-parsley-trigger-after-failure', 'data-parsley-no-focus', 'data-parsley-validation-threshold', 'data-parsley-class-handler', 'data-parsley-errors-container', 'data-parsley-error-message', 'data-parsley-creditcard', 'data-parsley-iban', 'data-parsley-required', 'data-parsley-required-message', 'data-parsley-type-message', 'data-parsley-length-message', 'data-parsley-pattern-message', 'data-parsley-maxlength-message', 'data-parsley-minlength-message', 'data-parsley-iban-message', 'data-parsley-trigger-after-failure', 'data-parsley-birthdate', 'data-parsley-date', "data-parsley-mindate", 'data-parsley-validation-threshold', 'data-parsley-aba', 'data-parsley-minamount', 'data-parsley-min-message', 'data-parsley-max-message']
								});
								_ember['default'].LinkComponent.reopen({
												attributeBindings: ['data-parsley-required', 'data-parsley-type', 'data-parsley-minlength', 'data-parsley-maxlength', 'data-parsley-length', 'data-parsley-min', 'data-parsley-max', 'data-parsley-range', 'data-parsley-pattern', 'data-parsley-mincheck', 'data-parsley-maxcheck', 'data-parsley-check', 'data-parsley-equalto', 'data-parsley-trigger', 'data-parsley-trigger-after-failure', 'data-parsley-no-focus', 'data-parsley-validation-threshold', 'data-parsley-class-handler', 'data-parsley-errors-container', 'data-parsley-error-message', 'data-parsley-creditcard', 'data-parsley-iban', 'data-parsley-required-message', 'data-parsley-type-message', 'data-parsley-length-message', 'data-parsley-maxlength-message', 'data-parsley-minlength-message', 'data-parsley-iban-message', 'data-parsley-birthdate', 'data-parsley-date', "data-parsley-mindate", 'data-parsley-validation-threshold', 'data-parsley-aba', 'data-parsley-minamount', 'data-parsley-min-message', 'data-parsley-max-message']
								});
				}

				exports['default'] = {
								name: 'parsley-attributes',
								initialize: initialize
				};
});