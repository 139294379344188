define('vopay_app/components/template-account', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		tagName: "tr",
		firebase: _ember['default'].inject.service('firebase'),
		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
			var name = "Amount-" + this.get("beneficiaryId");
			this.set("AmountName", name);
		},
		wachValue: _ember['default'].observer('Amount', function () {

			var isZeroAppend = false;
			var amount = this.get('Amount');
			if (amount) {

				var myElement = this.$("input")[1];

				if (myElement) {
					var start = myElement.selectionStart;
					var end = myElement.selectionEnd;

					var amount2 = amount;
					if (amount.replace()) {
						amount2 = amount.replace(/[^\d.]+/g, '');
						amount2 = Math.floor(parseFloat(amount2));
					}
					var split = amount.split(".");
					var ending = split[1];

					var amount3 = undefined;

					var ending2 = null;
					if (ending && ending.length && ending.length > 2) {

						ending2 = ending.substring(0, 2);
					} else {
						ending2 = ending;
					}

					if (ending2 || ending2 === 0) {
						amount3 = amount2.toLocaleString(undefined) + "." + ending2;
					} else if (split[0] == "") {

						if (ending2 || ending2 === 0) {
							amount3 = "0." + ending2;
							isZeroAppend = true;
						} else {
							amount3 = "0.";
							isZeroAppend = true;
						}
					} else if (!ending && split.length == 2) {
						amount3 = amount2.toLocaleString(undefined) + ".";
					} else {
						if (amount2) {
							amount3 = amount2.toLocaleString(undefined);
						} else {
							amount3 = "";
						}
					}

					this.$(myElement).val(amount3);

					if (myElement.setSelectionRange) {
						if (isZeroAppend) {
							myElement.setSelectionRange(start + 2, end + 2);
						} else if (amount3.length > amount.length && amount3.length > 4) {
							myElement.setSelectionRange(start + 1, end + 1);
						} else if (amount3.length < amount.length) {
							myElement.setSelectionRange(start - 1, end - 1);
						} else {
							myElement.setSelectionRange(start, end);
						}
					} else if (myElement.createTextRange) {
						var range = myElement.createTextRange();
						range.collapse(true);
						range.moveEnd('character', start);
						range.moveStart('character', end);
						range.select();
					}
				}
			}
		}),
		actions: {
			removeItem: function removeItem() {
				var path = "beneficiary/" + this.get("beneficiaryId") + "/templates";
				this.get("firebase").deleteRecordUser(path, this.get("key"));
				this.sendAction("refreshbeneficiaries", true);
			},
			ActivateUser: function ActivateUser() {
				var data2 = {};
				if (this.get("templateId")) {
					data2.key = this.get("templateId");
				}
				data2.IsActive = this.get("isActive");

				data2.Amount = this.get("Amount");
				var path = "beneficiary/" + this.get("beneficiaryId") + "/templates";
				this.get("firebase").updateRecordUser(path, this.get("key"), data2);
				this.sendAction("refreshbeneficiaries", true);
			},
			focusouttrigger: function focusouttrigger() {
				var data2 = {};
				if (this.get("templateId")) {
					data2.key = this.get("templateId");
				}
				data2.IsActive = this.get("isActive");
				data2.Amount = this.get("Amount");
				var path = "beneficiary/" + this.get("beneficiaryId") + "/templates";
				this.get("firebase").updateRecordUser(path, this.get("key"), data2);
				this.sendAction("refreshbeneficiaries", true);
			}
		}
	});
});