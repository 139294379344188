define('vopay_app/routes/dashboard/file-upload/file-history', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service('session'),
    api: _ember['default'].inject.service('api'),
    moveToTop: (function () {
      window.scrollTo(0, 0);
    }).on('activate'),
    model: function model() {

      var authed = true;
      if (authed) {
        return _ember['default'].RSVP.hash({
          fileUploads: this.get('api').getUploadedFiles()
        });
      } else {
        this.transitionTo('login');
      }
    }

  });
});