define('vopay_app/routes/dashboard/client-accounts/view', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        api: _ember['default'].inject.service('api'),
        model: function model(_, _ref) {
            var queryParams = _ref.queryParams;

            return _ember['default'].RSVP.hash({
                data: queryParams,
                bankAccounts: this.get("api").getBankAccount(queryParams)
            });
        }
    });
});