define('vopay_app/routes/dashboard/users/index', ['exports', 'ember'], function (exports, _ember) {
				exports['default'] = _ember['default'].Route.extend({
								api: _ember['default'].inject.service('api'),
								firebase: _ember['default'].inject.service('firebase'),
								model: function model() {
												console.log("here");
												return _ember['default'].RSVP.hash({
																UserList: this.get('api').getUserAccounts()
												});
								}

				});
});