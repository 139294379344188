define('vopay_app/components/change-password', ['exports', 'ember', 'vopay_app/mixins/error-handler'], function (exports, _ember, _vopay_appMixinsErrorHandler) {
  exports['default'] = _ember['default'].Component.extend(_vopay_appMixinsErrorHandler['default'], {
    routing: _ember['default'].inject.service('-routing'),
    session: _ember['default'].inject.service('session'),
    api: _ember['default'].inject.service('api'),
    passwordType: "password",
    passwordState: "Show",
    validPassword: false,

    togglePassword: function togglePassword() {
      if (this.get("passwordState") === "Show") {
        this.set("passwordState", "Hide");
        this.set("passwordType", "text");
      } else {
        this.set("passwordState", "Show");
        this.set("passwordType", "password");
      }
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      _ember['default'].$('form').parsley({
        successClass: " ",
        errorClass: "form__input--error",
        errorsWrapper: "<p></p>",
        errorTemplate: "<span class='form__message'></span>"
      });
      _ember['default'].$("input").keyup(function () {
        _ember['default'].$(this).addClass("form__input--blueActive");
      });
      _ember['default'].$("input").focusout(function () {
        _ember['default'].$(this).removeClass("form__input--blueActive");
      });
    },

    actions: {

      showPassword: function showPassword() {

        this.togglePassword();
      },

      changePassword: function changePassword() {
        var _this = this;

        var self = this,
            form = _ember['default'].$('#passwordForm'),
            formArr = form.serializeArray(),
            options = [];

        for (var i = 0; i < formArr.length; i++) {
          options[formArr[i].name] = formArr[i].value;
        }
        options.Username = localStorage.getItem("Username");
        form.parsley().validate();

        if (form.parsley().isValid()) {
          this.get('api').portalChangePassword(options).then(function () {
            var options2 = {};
            options2.Username = localStorage.getItem("Username");
            options2.Password = options.NewPassword;
            _this.get('session').authenticate('authenticator:custom', options2).then(function () {
              //if we're logged in, send the user to the transfer

              if (self.get('session.isAuthenticated')) {
                clearTimeout(window.sessionTimeout);
                localStorage.removeItem("key");
                localStorage.removeItem("accountID");
                localStorage.removeItem("signature");
                localStorage.setItem("reset", true);
                location.reload(true);
              }
            })['catch'](function (result) {

              self.set('errorMsg', result);
            });
          }, function (err) {

            self.set('errorMsg', "Invalid Current Password");
            _this.handleErrors(form, err);
          });
        }

        return false;
      },
      isValidHandler: function isValidHandler(isValid) {
        this.set("validPassword", isValid);
      }
    }

  });
});