define('vopay_app/routes/dashboard/beneficiaries/create', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		session: _ember['default'].inject.service('session'),
		firebase: _ember['default'].inject.service('firebase'),
		api: _ember['default'].inject.service('api'),
		model: function model() {

			var authed = this.get('session.isAuthenticated');

			if (authed) {

				return _ember['default'].RSVP.hash({
					PaylinkSettings: this.get('api').GetPaylinkSettings()

				});
			} else {
				this.transitionTo('login');
			}
		}
	});
});