define("vopay_app/mixins/manage-cookies", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Mixin.create({

        /***
         * getCookie
         * @param name (String) - Name of the target cookie
         *
         * Returns a cookie based on the name
         */
        getCookie: function getCookie(name) {

            var value = "; " + document.cookie;
            var parts = value.split("; " + name + "=");
            if (parts.length === 2) {

                return parts.pop().split(";").shift();
            }
        },

        /***
         * setCookie
         * @param name (String) - name key for the cookie
         * @param value (String) - value to store in the cookie
         *
         * Sets the token cookie
         */
        setCookie: function setCookie(name, value) {
            // TODO - Include security HTTPS, path, domain, etc.

            if (this.getCookie(name)) {

                this.removeCookie(name);
            }

            var cookie = document.cookie;
            cookie = name + "=" + value + ";" + cookie;
            document.cookie = cookie;
        },

        /***
         * removeCookie
         * @param name (String) - name key for the cookie
         *
         * Remove cookie
         */
        removeCookie: function removeCookie(name) {

            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }
    });
});