define('vopay_app/components/uploaded-file-list', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		routing: _ember['default'].inject.service('-routing'),
		api: _ember['default'].inject.service('api'),
		transactionTypes: ["All", "EFT", "Interac", "Paylink"],
		paymentTypes: ["All", "Debit", "Credit"],
		startDate: "",
		endDate: "",

		watchdate: _ember['default'].observer('date', function () {
			var start = localStorage.getItem("startDate");
			var end = localStorage.getItem("endDate");
			if (start && end && start != "" && end != "") {
				this.set("startDate", start);
				this.set("endDate", end);

				localStorage.setItem("startDate", "");
				localStorage.setItem("endDate", "");
				this.filterFiles(this.get("transactionType"), this.get("paymentType"), this.get("startDate"), this.get("endDate"));
			}
		}),

		init: function init() {
			this._super.apply(this, arguments);

			this.initializeDates();
			this.filterFiles(this.get("transactionType"), this.get("paymentType"), this.get("startDate"), this.get("endDate"));
		},

		didInsertElement: function didInsertElement() {
			this.initializeDates();
		},

		getSortedFiles: function getSortedFiles() {
			var files = this.get("files");
			var sortedFiles = {};

			for (var k = 0; k < Object.keys(files).length; k++) {
				files[k]["key"] = k;
				sortedFiles[Object.keys(files).length - 1 - k] = files[k];
			}

			return sortedFiles;
		},

		filterFiles: function filterFiles(transactionType, paymentType, startDate, endDate) {
			if (transactionType === undefined) transactionType = 'all';
			if (paymentType === undefined) paymentType = 'all';

			var sortedFiles = {};
			var filteredList = {};
			sortedFiles = Object.values(this.getSortedFiles());

			startDate = new Date(startDate + ' 00:00:00');
			endDate = new Date(endDate + ' 23:59:59');

			filteredList = sortedFiles.filter(function (file) {
				var submissionDate = new Date(file.created_at);
				// convert to UTC
				submissionDate = new Date(submissionDate.getUTCFullYear(), submissionDate.getUTCMonth(), submissionDate.getUTCDate(), submissionDate.getUTCHours(), submissionDate.getUTCMinutes(), submissionDate.getUTCSeconds());
				return (transactionType === 'all' || file.transaction_type.toLowerCase() === transactionType) && (paymentType === 'all' || file.payment_type.toLowerCase() === paymentType) && submissionDate >= startDate && submissionDate <= endDate;
			});

			this.set("filteredList", filteredList);
		},

		initializeDates: function initializeDates() {
			this.set("startDate", window.moment().utc().subtract(6, 'days').format('MM/DD/YYYY'));
			this.set("endDate", window.moment().utc().format('MM/DD/YYYY'));
			_ember['default'].$('input[name="daterange"]').daterangepicker({
				autoUpdateInput: false,
				locale: {
					cancelLabel: 'Clear'
				},
				startDate: window.moment().utc().subtract(6, 'days'),
				endDate: window.moment().utc(),
				ranges: {
					'Today': [window.moment().utc(), window.moment().utc()],
					'Yesterday': [window.moment().utc().subtract(1, 'days'), window.moment().utc().subtract(1, 'days')],
					'Last 7 Days': [window.moment().utc().subtract(6, 'days'), window.moment().utc()],
					'Last 30 Days': [window.moment().utc().subtract(29, 'days'), window.moment().utc()],
					'This Month': [window.moment().utc().startOf('month'), window.moment().utc().endOf('month')],
					'Last Month': [window.moment().utc().subtract(1, 'month').startOf('month'), window.moment().utc().subtract(1, 'month').endOf('month')]
				},
				alwaysShowCalendars: true
			});

			localStorage.setItem("startDate", "");
			localStorage.setItem("endDate", "");
			_ember['default'].$('input[name="daterange"]').val(window.moment().utc().subtract(6, 'days').format('MM/DD/YYYY') + " - " + window.moment().utc().format('MM/DD/YYYY'));

			_ember['default'].$('input[name="daterange"]').on('apply.daterangepicker', function (ev, picker) {
				localStorage.setItem("startDate", picker.startDate.format('MM/DD/YYYY'));
				localStorage.setItem("endDate", picker.endDate.format('MM/DD/YYYY'));
				_ember['default'].$('input[name="daterange"]').val(picker.startDate.format('MM/DD/YYYY') + " - " + picker.endDate.format('MM/DD/YYYY'));
				_ember['default'].$('input[name="daterange"]').trigger("change");
			});
		},

		actions: {
			filterTransactionType: function filterTransactionType(transactionType) {
				this.set("transactionType", transactionType.toLowerCase());
				this.filterFiles(this.get("transactionType"), this.get("paymentType"), this.get("startDate"), this.get("endDate"));
			},

			filterPaymentType: function filterPaymentType(paymentType) {
				this.set("paymentType", paymentType.toLowerCase());
				this.filterFiles(this.get("transactionType"), this.get("paymentType"), this.get("startDate"), this.get("endDate"));
			}
		}

	});
});