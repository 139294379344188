define('vopay_app/components/beneficiary-details', ['exports', 'ember', 'vopay_app/helpers/mask-account'], function (exports, _ember, _vopay_appHelpersMaskAccount) {
    var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

    exports['default'] = _ember['default'].Component.extend({
        firebase: _ember['default'].inject.service('firebase'),
        api: _ember['default'].inject.service('api'),
        routing: _ember['default'].inject.service('-routing'),
        init: function init() {
            this._super.apply(this, arguments);
            var bankAccounts = this.get("model.beneficiary.BankAccounts");
            var emails = this.get("model.beneficiary");
            var creditCards = this.get("model.beneficiary.CreditCards");
            if (bankAccounts) {
                var myAccounts = Object.values(bankAccounts);
                myAccounts = myAccounts.map(function (bankAccount) {
                    return _extends({}, bankAccount, { Type: 'Bank Account', Country: 'CA' });
                });
                this.set("bankAccount", myAccounts[0]);
            } else if (creditCards) {
                var myCards = Object.values(creditCards);
                myCards = myCards.map(function (creditCard) {
                    return _extends({}, creditCard, { Type: 'Credit Card', Country: 'CA', Currency: 'CAD' });
                });
                this.set("bankAccount", myCards[0]);
            } else if (emails) {
                this.set("bankAccount", emails);
            }
        },
        didInsertElement: function didInsertElement() {
            window.addEventListener('message', function (e) {
                if (e.data.step == "ACCOUNT_SELECTED") {
                    localStorage.setItem("accountId", e.data.accountId);
                }
                if (e.data.step == "REDIRECT") {
                    localStorage.setItem("loginId", e.data.loginId);
                    localStorage.setItem("institution", e.data.institution);
                    //location.href = e.data.url;
                }
                if (e.data.Step == "LINK") {
                    console.log(e.data);

                    localStorage.setItem("FlinksToken", e.data.Token);
                    localStorage.setItem("CreditCardBrand", e.data.CreditCardBrand);
                    localStorage.setItem("CreditCardExpiryMonth", e.data.CreditCardExpiryMonth);
                    localStorage.setItem("CreditCardExpiryYear", e.data.CreditCardExpiryYear);
                    localStorage.setItem("CreditCardNumber", e.data.CreditCardNumber);
                    localStorage.setItem("CreditCardHolderName", e.data.CreditCardHolderName);
                }
            });
        },
        routeToBeneficiaries: function routeToBeneficiaries() {
            if (this.get("isBeneficiary") === "true") {
                this.get("routing").transitionTo("dashboard.beneficiaries");
            } else {
                this.get("routing").transitionTo("dashboard.accounts");
            }
        },
        actions: {
            deleteItem: function deleteItem() {
                this.set("isDeleteModal", true);
            },
            moveToEdit: function moveToEdit(key) {
                if (this.get("isBeneficiary") === "true") {
                    window.location.href = "/#/dashboard/beneficiaries/edit/" + key;
                }
                if (this.get("isLinked") === "true") {
                    window.location.href = "/#/dashboard/link-accounts/update/" + key;
                }
                if (this.get("isAccounts") === "true") {
                    window.location.href = "/#/dashboard/accounts/edit/" + key;
                }
            },
            enterCreditCard: function enterCreditCard() {
                var _this = this;

                console.log("here");
                var options = null;

                if (location.hostname == "localhost") {
                    options = {
                        RedirectURL: "https://" + location.hostname + ":4200/",
                        ClientControlled: false
                    };
                } else if (location.hostname == "bs-local.com") {

                    options = {
                        RedirectURL: "https://" + location.hostname + ":4200/",
                        ClientControlled: false
                    };
                } else {
                    options = {
                        RedirectURL: "https://" + location.hostname + "/",
                        ClientControlled: false
                    };
                }
                this.get('api').GenerateCreditCardURL(options).then(function (data2) {
                    _this.set("CreditCardURL", data2.EmbedURL);
                    _this.set("isCreditCardFrame", true);
                    var self = _this;
                    window.CreditCardInterval = setInterval(function () {
                        if (localStorage.getItem("FlinksToken") != null) {

                            self.set("model.beneficiary.CreditCardToken", localStorage.getItem("FlinksToken"));

                            self.set("model.beneficiary.CreditCardBrand", localStorage.getItem("CreditCardBrand"));
                            self.set("model.beneficiary.CreditCardExpiryMonth", localStorage.getItem("CreditCardExpiryMonth"));
                            self.set("model.beneficiary.CreditCardExpiryYear", localStorage.getItem("CreditCardExpiryYear"));
                            self.set("model.beneficiary.CreditCardNumber", localStorage.getItem("CreditCardNumber"));
                            self.set("model.beneficiary.CreditCardHolderName", localStorage.getItem("CreditCardHolderName"));

                            if (self.get("isAccounts") === "true") {
                                self.get("firebase").updateRecordUser("accounts", self.get("model.key"), self.get("model.beneficiary"));
                            } else {

                                self.get("firebase").updateRecordUser("beneficiary", self.get("model.key"), self.get("model.beneficiary"));
                            }
                            localStorage.removeItem("CreditCardBrand");
                            localStorage.removeItem("CreditCardExpiryMonth");
                            localStorage.removeItem("CreditCardExpiryYear");
                            localStorage.removeItem("CreditCardNumber");
                            localStorage.removeItem("CreditCardHolderName");
                            localStorage.removeItem("FlinksToken");
                            self.set("isCreditCardFrame", false);
                            clearInterval(window.CreditCardInterval);
                        }
                    }, 100);
                }, function (error) {
                    _this.errorMessage(error);
                });
            },
            closeCreditCard: function closeCreditCard() {
                this.set("isCreditCardFrame", false);
                clearInterval(window.CreditCardInterval);
            },

            closeVisaDIrect: function closeVisaDIrect() {
                this.set("isVisaDirectCard", false);
                clearInterval(window.VisaDirectInterval);
            },

            enterVisaDirectCard: function enterVisaDirectCard() {
                var _this2 = this;

                var RedirectURL = "";
                if (location.hostname == "localhost") {

                    RedirectURL = "https://" + location.hostname + ":4200/";
                } else if (location.hostname == "bs-local.com") {

                    RedirectURL = "https://" + location.hostname + "/";
                } else {

                    RedirectURL = "https://" + location.hostname + "/";
                }
                var options = {
                    RedirectURL: RedirectURL
                };
                var self = this;
                this.get("api").VisaDirectURL(options).then(function (data) {
                    _this2.set("VisaDirectURL", data.EmbedURL);
                    _this2.set("isVisaDirectCard", true);

                    window.VisaDirectInterval = setInterval(function () {

                        if (localStorage.getItem("FlinksToken") != null) {

                            var Options = {
                                Token: localStorage.getItem("FlinksToken")
                            };
                            self.get("api").GetCreditCardIfo(Options).then(function (data) {

                                self.set("model.beneficiary.FullName", data.Name);
                                self.set("model.beneficiary.CardNumber", data.LastFourDigits);
                                self.set("model.beneficiary.Token", localStorage.getItem("FlinksToken"));
                                localStorage.removeItem("FlinksToken");

                                self.set("isVisaDirectCard", false);
                                if (self.get("isAccounts") === "true") {
                                    self.get("firebase").updateRecordUser("accounts", self.get("model.key"), self.get("model.beneficiary"));
                                } else {

                                    self.get("firebase").updateRecordUser("beneficiary", self.get("model.key"), self.get("model.beneficiary"));
                                }
                            }, function (error) {});

                            clearInterval(window.VisaDirectInterval);
                        }
                    }, 100);
                }, function (error) {
                    _this2.errorMessage(error);
                });
            },
            deleteAccount: function deleteAccount() {
                var key = this.get("model.key");
                var bankAccounts = this.get("model.beneficiary.BankAccounts");

                if (bankAccounts) {
                    var token = bankAccounts[0].Token;
                    this.get("api").deleteVoPayBankAccount(token);
                } else {
                    if (this.get("isBeneficiary") === "true") {
                        this.get("firebase").deleteRecordUser("beneficiary", key);
                    } else if (this.get("isLinked") === "true") {
                        this.get("firebase").deleteRecordUser("linkedAccount", key);
                    } else {
                        this.get("firebase").deleteRecordUser("accounts", key);
                    }
                }

                this.routeToBeneficiaries();
            },
            closeDeleteModal: function closeDeleteModal() {
                this.set("isDeleteModal", false);
            }

        }
    });
});