define('vopay_app/components/edit-recipient-faild-trans', ['exports', 'ember', 'vopay_app/mixins/error-handler'], function (exports, _ember, _vopay_appMixinsErrorHandler) {
	exports['default'] = _ember['default'].Component.extend(_vopay_appMixinsErrorHandler['default'], {
		api: _ember['default'].inject.service('api'),
		routing: _ember['default'].inject.service('-routing'),
		_addSwiftToRecipient: function _addSwiftToRecipient(recipient) {
			var _this = this;

			return new _ember['default'].RSVP.Promise(function (resolve, reject) {

				_this.get('api').ibanChecker(recipient.Iban).then(function (data) {

					recipient.BicSwift = data['bank_data'].bic;
					resolve(recipient);
				}, function (error) {

					reject(error);
				});
			});
		},
		addRecipient: function addRecipient(formObj, country, currency, IsoCountry) {
			var recipient = {};

			formObj.map(function (d) {
				recipient[d['name']] = d['value'];
			});

			recipient.country = IsoCountry;
			recipient.currency = currency;

			if (recipient.Iban) {

				// restore country into iban number
				recipient.Iban = '' + recipient.Iban;
			}
			recipient.accountHolderName = recipient.accountHolderName.trim();
			if (recipient.country === "CN") {
				recipient.BeneficiaryFirstName = recipient.accountHolderName;
				recipient.BeneficiaryLastName = " ";
			} else {
				var arrNames = recipient.accountHolderName.split(' ');
				recipient.BeneficiaryFirstName = arrNames.shift();
				recipient.BeneficiaryLastName = arrNames.pop();
			}

			return recipient;
		},
		actions: {
			update: function update() {
				var _this2 = this;

				_ember['default'].$('#stepTwoBtn').attr('disabled', 'disabled');
				var country = this.get("model.model.countryName");
				var currency = this.get("model.model.ReceiveCurrency");
				var IsoCountry = this.get("model.model.countryAbbv");
				var recipientId = this.get("model.model.RemittanceRecipientID");

				var recipientFormArr = _ember['default'].$('#recipientForm').serializeArray();
				var recipientAdd = this.addRecipient(recipientFormArr, country, currency, IsoCountry);
				if (recipientAdd.hasOwnProperty('BicSwift')) {
					if (country === "Singapore") {
						recipientAdd.BicSwift = recipientAdd.BicSwiftShort;
						this.get('api').updateRecipient(recipientAdd, recipientId).then(function () {
							_this2.get('api').retryTransaction(_this2.get("model.model.RemittanceTransactionID")).then(function () {
								_ember['default'].$('html,body').scrollTop(0);
								_ember['default'].$('#stepTwoBtn').removeAttr('disabled');
								_this2.get('routing').transitionTo('dashboard.confirmation', [_this2.get("model.model.RemittanceTransactionID")]);
							}, function () {
								_this2.get('routing').transitionTo('error');
								_ember['default'].$('#stepTwoBtn').removeAttr('disabled');
							});
						}, function (err) {

							_this2.handleErrors(recipientFormArr, err);
							_ember['default'].$('#stepTwoBtn').removeAttr('disabled');
						});
					} else {
						// Run promise IBAN to retrieve SWIFT code
						this._addSwiftToRecipient(recipientAdd).then(function (rec) {

							// Run promise AddRecipient
							_this2.get('api').updateRecipient(rec, recipientId).then(function () {
								_this2.get('api').retryTransaction(_this2.get("model.model.RemittanceTransactionID")).then(function () {
									_ember['default'].$('html,body').scrollTop(0);
									_ember['default'].$('#stepTwoBtn').removeAttr('disabled');
									_this2.get('routing').transitionTo('dashboard.confirmation', [_this2.get("model.model.RemittanceTransactionID")]);
								}, function () {
									_this2.get('routing').transitionTo('error');
									_ember['default'].$('#stepTwoBtn').removeAttr('disabled');
								});
							}, function (err) {

								_ember['default'].$('#stepTwoBtn').removeAttr('disabled');
								_this2.handleErrors(recipientFormArr, err);
							});
						}, function (err) {

							_this2.handleErrors(recipientFormArr, err);
						});
					}
				} else {

					// Run promise AddRecipient
					this.get('api').updateRecipient(recipientAdd, recipientId).then(function () {
						_this2.get('api').retryTransaction(_this2.get("model.model.RemittanceTransactionID")).then(function () {
							_ember['default'].$('html,body').scrollTop(0);
							_ember['default'].$('#stepTwoBtn').removeAttr('disabled');
							_this2.get('routing').transitionTo('dashboard.confirmation', [_this2.get("model.model.RemittanceTransactionID")]);
						}, function () {
							_this2.get('routing').transitionTo('error');
							_ember['default'].$('#stepTwoBtn').removeAttr('disabled');
						});
					}, function (err) {

						_this2.handleErrors(recipientFormArr, err);
						_ember['default'].$('#stepTwoBtn').removeAttr('disabled');
					});
				}
			}
		}
	});
});