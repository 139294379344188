define("vopay_app/utils/base64encoder", ["exports"], function (exports) {
    exports["default"] = base64encoder;

    function base64encoder(element) {
        console.log(element);
        return new Promise(function (resolve, reject) {
            var file = element.files[0];
            var reader = new FileReader();
            reader.onloadend = function () {
                resolve(reader.result);
            };
            reader.readAsDataURL(file);
        });
    }
});