define('vopay_app/components/template-accounts', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		beneficiary: "",
		firebase: _ember['default'].inject.service('firebase'),
		api: _ember['default'].inject.service('api'),
		routing: _ember['default'].inject.service('-routing'),
		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);
			$(window).off("resize");
		},

		handleResize: function handleResize(e) {
			if (window.innerWidth <= 768) {
				this.set("desktop", false);
				this.set("mobile", true);
			} else {

				this.set("desktop", true);
				this.set("mobile", false);
			}
		},
		didInsertElement: function didInsertElement() {
			var self = this;
			this.handleResize(this);
			$(window).on('resize', this.handleResize.bind(this));
		},
		payment: function payment(listing, index, length, PaymentType, Service) {
			var _this = this;

			var key = Object.keys(listing)[index];
			var beneficiary = listing[key];
			/**need to change */
			beneficiary.DollarAmount = beneficiary.DollarAmount.replace(/,/g, '');
			if (Service == "EFT" || Service == "Electronic Funds Transfer") {
				if (PaymentType == "Collect") {
					this.get("api").fundTransaction(beneficiary).then(function (transaction) {
						listing[key].Message = "Successfully Submitted";
						if (index >= length - 1) {
							_this.finish(listing);
							//this.get("routing").transitionTo("dashboard.transactions");
						} else {

								_this.payment(listing, index + 1, length, PaymentType, Service);
							}
					}, function (error) {
						listing[key].Message = error;
						if (index >= length - 1) {
							_this.finish(listing);
							//this.get("routing").transitionTo("dashboard.transactions");
						} else {
								_this.payment(listing, index + 1, length, PaymentType, Service);
							}
					});
				}
				if (PaymentType == "Payment") {
					this.get("api").withdrawTransaction(beneficiary).then(function (transaction) {
						listing[key].Message = "Successfully Submitted";
						if (index >= length - 1) {
							_this.finish(listing);
							//this.get("routing").transitionTo("dashboard.transactions");
						} else {
								_this.payment(listing, index + 1, length, PaymentType, Service);
							}
					}, function (error) {
						listing[key].Message = error;
						if (index >= length - 1) {
							_this.finish(listing);
							//this.get("routing").transitionTo("dashboard.transactions");
						} else {
								_this.payment(listing, index + 1, length, PaymentType, Service);
							}
					});
				}
			} else {
				if (PaymentType == "Collect") {
					this.get("api").ClaimMoneyRequest(beneficiary).then(function (transaction) {
						listing[key].Message = "Successfully Submitted";
						if (index == length - 1) {
							_this.finish(listing);
							//this.get("routing").transitionTo("dashboard.transactions");
						} else {
								_this.payment(listing, index + 1, length, PaymentType, Service);
							}
					}, function (error) {
						listing[key].Message = error;
						if (index == length - 1) {
							_this.finish(listing);
							//this.get("routing").transitionTo("dashboard.transactions");
						} else {
								_this.payment(listing, index + 1, length, PaymentType, Service);
							}
					});
				}
				if (PaymentType == "Payment") {
					this.get("api").BulkPayout(beneficiary).then(function (transaction) {
						listing[key].Message = "Successfully Submitted";
						if (index == length - 1) {
							_this.finish(listing);
							//this.get("routing").transitionTo("dashboard.transactions");
						} else {
								_this.payment(listing, index + 1, length, PaymentType, Service);
							}
					}, function (error) {
						listing[key].Message = error;
						if (index == length - 1) {
							_this.finish(listing);
							//this.get("routing").transitionTo("dashboard.transactions");
						} else {
								_this.payment(listing, index + 1, length, PaymentType, Service);
							}
					});
				}
			}
		},
		finish: function finish(listing) {

			this.set("listing2", listing);
			_ember['default'].$("#FileSummary").show();
			_ember['default'].$("#Overview").hide();
			this.sendAction('FinishHandler', "");
			_ember['default'].$('.complete').prop('disabled', false);
		},
		errorMessage: function errorMessage(message) {
			this.set("ErrorMessage", message);
			this.set("isError", true);
		},
		actions: {
			selectBeneficiary: function selectBeneficiary(value) {
				var PaymentType = this.get("PaymentType");
				if (value == "New") {
					localStorage.setItem('location', window.location.href);
					if (PaymentType == "Payment") {
						localStorage.setItem('isPayment', true);
					}
					this.get("routing").transitionTo("dashboard.beneficiaries.create");
				} else {
					this.set("beneficiary", value);
				}
			},
			addPayee: function addPayee() {
				var _this2 = this;

				if (this.get("beneficiary") != '') {
					var data2 = {};
					data2.IsActive = false;
					data2.key = this.get("key");
					var path = "beneficiary/" + this.get("beneficiary") + "/templates";
					this.get("firebase").createRecordUser(path, data2);
					this.get("firebase").getRecordUser("beneficiary").then(function (data) {

						_this2.set('beneficiaries', data);
					});
				}
			},
			refreshbeneficiaries: function refreshbeneficiaries(value) {
				var _this3 = this;

				this.get("firebase").getRecordUser("beneficiary").then(function (data) {
					_this3.set('beneficiaries', data);
				});
			},
			review: function review() {
				var formArray = $("#bulk").serializeArray();
				var listing = [];
				var beneficiaries = this.get("beneficiaries");
				var amount = 0;
				var number = 0;
				var CurrencyAmount = [];

				for (var i = 0; i < formArray.length; i++) {
					if (formArray[i]['name'] == "Key") {
						var key = formArray[i]['value'];
						if (beneficiaries[key]) {
							var listingObject = Object.assign({}, beneficiaries[key]);
							var IsActive = false;
							var IsAmount = false;
							for (var j = i; j < formArray.length; j++) {
								if (IsActive && IsAmount) {
									break;
								}
								if (formArray[j]['name'] == "Activated-" + key) {
									listingObject.IsActive = formArray[j]['value'];
									IsActive = true;
								}
								if (formArray[j]['name'] == "Amount-" + key) {
									listingObject.DollarAmount = formArray[j]['value'].replace(/,/g, '');
									listingObject.DollarAmount2 = formArray[j]['value'].replace(/,/g, '');
									IsAmount = true;
								}
							}

							if (listingObject.DollarAmount2 == "" || listingObject.DollarAmount2 == null || listingObject.IsActive == false || listingObject.IsActive == "false") {
								listingObject.DollarAmount = 0;
								listingObject.DollarAmount2 = "0";
							}
							if (!CurrencyAmount[listingObject.Currency]) {
								CurrencyAmount[listingObject.Currency] = 0;
							}

							CurrencyAmount[listingObject.Currency] = parseFloat(CurrencyAmount[listingObject.Currency]) + parseFloat(listingObject.DollarAmount2.replace(/,/g, ''));
							if (parseFloat(listingObject.DollarAmount2.replace(/,/g, '')) > 0 && listingObject.DollarAmount != 0) {
								listing.push(listingObject);
								number = number + 1;
							}
						}
					}
				}
				var listing2 = Object.assign({}, listing);
				this.set("listing", listing2);
				var CurrencyAmount2 = Object.assign({}, CurrencyAmount);
				this.set("CurrencyAmount", CurrencyAmount2);

				this.set("number", number);

				_ember['default'].$("#Overview").show();
				_ember['default'].$("#bulk").hide();
			},
			moveToBulk: function moveToBulk() {
				_ember['default'].$("#Overview").hide();
				_ember['default'].$("#bulk").show();
			},
			closeModalError: function closeModalError() {
				this.set("isError", false);
			},
			pdf: function pdf() {
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').addClass("importantShow");
					_ember['default'].$('#tableID').tableExport({ type: 'pdf', escape: 'false', consoleLog: 'true' });
					_ember['default'].$('#tableID').removeClass("importantShow");
				}, 200);
				return false;
			},
			csv: function csv() {
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').addClass("importantShow");
					_ember['default'].$('#tableID').tableExport({ type: 'csv', escape: 'false', consoleLog: 'true' });
					_ember['default'].$('#tableID').removeClass("importantShow");
				}, 200);
				return false;
			},
			xls: function xls() {
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').addClass("importantShow");
					_ember['default'].$('#tableID').tableExport({ type: 'csv', escape: 'false', consoleLog: 'true' });
					_ember['default'].$('#tableID').removeClass("importantShow");
				}, 200);
				return false;
			},
			doc: function doc() {
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').addClass("importantShow");
					_ember['default'].$('#tableID').tableExport({ type: 'word', escape: 'false', consoleLog: 'true' });
					_ember['default'].$('#tableID').removeClass("importantShow");
				}, 200);
				return false;
			},
			text: function text() {

				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').addClass("importantShow");
					_ember['default'].$('#tableID').tableExport({ type: 'txt', escape: 'false', consoleLog: 'true' });
					_ember['default'].$('#tableID').removeClass("importantShow");
				}, 200);
				return false;
			},
			json: function json() {
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').addClass("importantShow");
					_ember['default'].$('#tableID').tableExport({ type: 'json', escape: 'false', consoleLog: 'true' });
					_ember['default'].$('#tableID').removeClass("importantShow");
				}, 200);
				return false;
			},
			xml: function xml() {
				setTimeout(function () {
					_ember['default'].$('#tableID').addClass("importantShow");
					_ember['default'].$('#tableID').tableExport({ type: 'xml', escape: 'false', consoleLog: 'true' });
					_ember['default'].$('#tableID').removeClass("importantShow");
				}, 200);
				return false;
			},
			printPage: function printPage() {
				setTimeout(function () {
					window.print();
				}, 200);
			},
			pay: function pay() {
				var _this4 = this;

				var listing = this.get("listing");
				var template = this.get("template");
				var PaymentType = this.get("PaymentType");

				_ember['default'].$('.complete').prop('disabled', true);

				if (Object.keys(listing).length == 0) {
					this.errorMessage("No transactions found");
					_ember['default'].$('.complete').prop('disabled', false);
				} else {

					if (PaymentType == "Payment") {
						var currency = null;
						var options = {};
						options.Currency = template.Currency;
						this.get("api").getAccountBalance(options).then(function (data) {
							if (parseFloat(data.AvailableFunds) >= _this4.get("CurrencyAmount")[template.Currency]) {
								_this4.payment(listing, 0, Object.keys(listing).length, PaymentType, template.Service);
							} else {
								_this4.errorMessage("Insufficient Funds");
								_ember['default'].$('.complete').prop('disabled', false);
							}
						}, function (error) {
							_this4.errorMessage("Unsupported Currency");
							_ember['default'].$('.complete').prop('disabled', false);
						});
					} else {
						this.payment(listing, 0, Object.keys(listing).length, PaymentType, template.Service);
					}
				}

				//    	for (var key in listing) {
				//    		var beneficiary = listing[key];
				// 		if(PaymentType == "Collect"){
				// 			this.get("api").fundTransaction(beneficiary).then(
				// 				transaction => {

				// 				},
				// 				() =>{

				// 		  		}
				// 			);
				// 		}
				// 		if(PaymentType == "Payment"){
				// 			this.get("api").withdrawTransaction(beneficiary).then(
				// 				transaction => {

				// 				},
				// 				() =>{

				// 		  		}
				// 			);
				// 		}
				// 	}
				// 	let self = this;
				//    	setTimeout(function(){ self.get("routing").transitionTo("dashboard.transactions"); }, 3000);
			}
		}
	});
});