define('vopay_app/routes/dashboard/link-accounts/details', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		api: _ember['default'].inject.service('api'),
		firebase: _ember['default'].inject.service('firebase'),
		model: function model(id) {
			if (id["token"]) {
				var options = {
					Token: id["token"]
				};
				if (id["type"] == 'CreditCard') {
					return _ember['default'].RSVP.hash({
						currencies: this.get('api').getGCMCurriencies(),
						beneficiary: this.get("api").getCreditCards(true, id["token"]),
						key: id["user"]
					});
				} else {
					return _ember['default'].RSVP.hash({
						currencies: this.get('api').getGCMCurriencies(),
						beneficiary: this.get("api").getBankAccounts(true, id["token"]),
						key: id["user"]
					});
				}
			} else {
				return _ember['default'].RSVP.hash({
					currencies: this.get('api').getGCMCurriencies(),
					beneficiary: this.get("firebase").getSingleRecordUser("linkedAccount", id["user"]),
					key: id["user"]
				});
			}
		}
	});
});