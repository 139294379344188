define('vopay_app/components/bank-account-list', ['exports', 'ember', 'vopay_app/config/environment'], function (exports, _ember, _vopay_appConfigEnvironment) {
    var businessShortName = _vopay_appConfigEnvironment['default'].businessShortName;
    var businessLongName = _vopay_appConfigEnvironment['default'].businessLongName;

    exports['default'] = _ember['default'].Component.extend({
        isError: false,
        SupportEmail: _vopay_appConfigEnvironment['default'].businessEmail,
        LongName: businessLongName,
        ShortName: businessShortName,
        isDeleteModal: false,
        Token: null,
        isiQ11: false,
        api: _ember['default'].inject.service('api'),
        Currency: "CAD",
        currency2: "CAD",
        currencies4: {
            CAD: {
                name: 'Canadian Dollar',
                iso3: 'CAD'
            }
        },
        country: "Canada",
        currency: "CAD",
        errorMessage: function errorMessage(Message) {
            this.set("ErrorMessage", Message);
            this.set("isError", true);
        },
        didInsertElement: function didInsertElement() {

            this._super.apply(this, arguments);

            window.addEventListener('message', function (e) {
                if (e.data.step == "ACCOUNT_SELECTED") {
                    localStorage.setItem("accountId", e.data.accountId);
                }
                if (e.data.step == "REDIRECT") {
                    localStorage.setItem("loginId", e.data.loginId);
                    localStorage.setItem("institution", e.data.institution);
                    //location.href = e.data.url;
                }
                if (e.data.Step == "LINK") {
                    localStorage.setItem("institution", e.data.Institution);
                    localStorage.setItem("AccountNumber", e.data.AccountNumber);
                    localStorage.setItem("BankId", e.data.BankId);
                    localStorage.setItem("InstitutionNumber", e.data.InstitutionNumber);
                }
            });
        },
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            var options = null;

            if (location.hostname == "localhost") {
                options = {
                    RedirectURL: "https://" + location.hostname + ":4200/",
                    customerName: this.get("LongName"),
                    AccountSelectionMethod: "manual",
                    ClientAccountID: this.get("ClientAccountID")
                };
            } else if (location.hostname == "bs-local.com") {

                options = {
                    RedirectURL: "https://" + location.hostname + ":4200/",
                    ClientAccountID: this.get("ClientAccountID"),
                    AccountSelectionMethod: "manual",
                    customerName: this.get("LongName")
                };
            } else {
                options = {
                    RedirectURL: "https://" + location.hostname + "/",
                    ClientAccountID: this.get("ClientAccountID"),
                    AccountSelectionMethod: "manual",
                    customerName: this.get("LongName")
                };
            }
            this.get('api').getApiURL(options).then(function (data) {
                _this.set("iQ11URL", data.EmbedURL);
            });
        },
        updateBanks: function updateBanks() {
            var _this2 = this;

            var options = {
                ClientAccountID: this.get("ClientAccountID")
            };
            this.get("api").getBankAccount(options).then(function (data) {
                _this2.set("BankAccounts", data.BankAccounts);
                _this2.set("isiQ11", false);
            }, function (error) {
                _this2.errorMessage(error);
            });
        },
        actions: {
            closeModalError: function closeModalError() {
                this.set("isError", false);
            },
            deleteAccount: function deleteAccount() {
                var _this3 = this;

                var options = {
                    Token: this.get("Token")

                };
                this.get("api").deleteBankAccountIq11(options).then(function (data) {
                    _this3.set("isDeleteModal", false);
                    _this3.updateBanks();
                }, function (error) {
                    _this3.errorMessage(error);
                });
            },
            closeDeleteModal: function closeDeleteModal() {
                this.set("isDeleteModal", false);
                this.set("Token", null);
            },
            DeleteAccount: function DeleteAccount(token) {
                this.set("isDeleteModal", true);

                this.set("Token", token);
            },
            SetAsDefault: function SetAsDefault(token) {
                var _this4 = this;

                var options = {
                    Token: token,
                    ClientAccountID: this.get("ClientAccountID"),
                    SetAsDefault: true
                };
                this.get("api").setDefaultBankAccount(options).then(function (data) {
                    _this4.updateBanks();
                }, function (error) {
                    _this4.errorMessage(error);
                });
            },
            AddBankAccount: function AddBankAccount() {

                this.set("isiQ11", true);
                var self = this;
                window.Iq11Interval = setInterval(function () {
                    if (localStorage.getItem("FlinksToken") != null) {
                        localStorage.removeItem("FlinksToken");

                        self.updateBanks();
                        clearInterval(window.Iq11Interval);
                    }
                }, 100);
            },
            closeModalIQ11: function closeModalIQ11() {

                this.set("isiQ11", false);
                clearInterval(window.Iq11Interval);
            }

        }
    });
});