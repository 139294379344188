define('vopay_app/routes/dashboard/schedule/details', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		api: _ember['default'].inject.service('api'),
		firebase: _ember['default'].inject.service('firebase'),
		model: function model(id) {
			var authed = true;
			if (authed) {
				var link = window.localStorage.getItem("link");
				window.localStorage.removeItem("link");
				var options = {
					ScheduledTransactionID: id["scheduleId"]
				};
				var options2 = {};
				return _ember['default'].RSVP.hash({
					LinkPage: link,
					Id: id["scheduleId"],
					transactions: this.get('api').getScheduleTransactions(options)

				});
			} else {
				this.transitionTo('login');
			}
		}
	});
});