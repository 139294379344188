define('vopay_app/components/wallet-info', ['exports', 'ember', 'vopay_app/constants'], function (exports, _ember, _vopay_appConstants) {
	exports['default'] = _ember['default'].Component.extend({
		routing: _ember['default'].inject.service('-routing'),
		isAvailable: false,
		options: ["Fund", "Withdraw", "Convert", "Collect", "Pay"],
		init: function init() {

			this._super.apply(this, arguments);

			var accountBalances = this.get("accountBalances");
			var currencies3 = {};
			var currencies4 = {};
			var currencies2 = this.get("currencies2");
			for (var key in accountBalances) {
				if (!accountBalances[key].isActive) {
					currencies3[key] = currencies2[key];
					this.set("isAvailable", true);
				} else {
					currencies4[key] = accountBalances[key];
				}
			}
			this.set("currencies3", currencies3);
			this.set("accountBalances", currencies4);
			if (localStorage.getItem("GCMEnabled") == "1") {
				this.set("isGMC", true);
			} else {
				this.set("isGMC", false);
			}
			if (localStorage.getItem("USDEFTCollectEnabled") == "1") {
				this.set("isUSDEFTCollect", true);
			} else {
				this.set("isUSDEFTCollect", false);
			}
			if (localStorage.getItem("USDEFTSendEnabled") == "1") {
				this.set("isUSDEFTSend", true);
			} else {
				this.set("isUSDEFTSend", false);
			}
		},
		currencyName: "Add Wallet",
		currencies2: _vopay_appConstants['default'].currencies,
		fund: function fund(currency) {
			sessionStorage.setItem("currency", currency);
			sessionStorage.setItem("isDeposit", "true");
			this.get("routing").transitionTo("dashboard.fund");
		},
		convert: function convert(currency) {
			sessionStorage.setItem("currency", currency);
			this.get("routing").transitionTo("dashboard.convert-pay");
		},
		collect: function collect(currency) {
			sessionStorage.setItem("currency", currency);
			sessionStorage.setItem("isDeposit", "false");
			this.get("routing").transitionTo("dashboard.collect");
		},
		withdraw: function withdraw(currency) {
			sessionStorage.setItem("currency", currency);
			sessionStorage.setItem("isDeposit", "false");
			this.get("routing").transitionTo("dashboard.withdraw");
		},
		pay: function pay(currency) {
			sessionStorage.setItem("currency", currency);
			sessionStorage.setItem("isDeposit", "false");
			this.get("routing").transitionTo("dashboard.add-account");
		},
		actions: {
			convert: function convert(currency) {
				sessionStorage.setItem("currency", currency);
				this.get("routing").transitionTo("dashboard.convert-pay");
			},

			fund: function fund(currency) {
				sessionStorage.setItem("currency", currency);
				sessionStorage.setItem("isDeposit", "true");
				this.get("routing").transitionTo("dashboard.fund");
			},
			collect: function collect(currency) {
				sessionStorage.setItem("currency", currency);
				sessionStorage.setItem("isDeposit", "false");
				this.get("routing").transitionTo("dashboard.collect");
			},
			withdraw: function withdraw(currency) {
				sessionStorage.setItem("currency", currency);
				sessionStorage.setItem("isDeposit", "false");
				this.get("routing").transitionTo("dashboard.withdraw");
			},
			pay: function pay(currency) {
				sessionStorage.setItem("currency", currency);
				sessionStorage.setItem("isDeposit", "false");
				this.get("routing").transitionTo("dashboard.add-account");
			},

			changedItem: function changedItem(type, currency) {

				if (type == "Fund") {
					this.fund(currency);
				} else if (type == "Withdraw") {
					this.withdraw(currency);
				} else if (type == "Convert") {
					this.convert(currency);
				} else if (type == "Collect") {
					this.collect(currency);
				} else if (type == "Pay") {
					this.pay(currency);
				}
			},
			CurrencyChanged: function CurrencyChanged(currency) {
				if (currency === "CAD") {
					this.fund(currency);
				} else {
					this.convert(currency);
				}
			}
		}
	});
});