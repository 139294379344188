define('vopay_app/components/create-subaccount', ['exports', 'ember', 'vopay_app/utils/entry-filter', 'vopay_app/config/environment'], function (exports, _ember, _vopay_appUtilsEntryFilter, _vopay_appConfigEnvironment) {
	exports['default'] = _ember['default'].Component.extend({
		api: _ember['default'].inject.service('api'),
		routing: _ember['default'].inject.service('-routing'),
		entryUtil: _vopay_appUtilsEntryFilter['default'].create(),
		isWhitelabel: _vopay_appConfigEnvironment['default'].isWhitelabel,
		SendWelcomeEmail: true,
		didInsertElement: function didInsertElement() {
			_ember['default'].$("#subaccount").parsley().destroy();
			_ember['default'].$('form').parsley({
				successClass: " ",
				errorClass: "form__input--error",
				errorsWrapper: "<p></p>",
				errorTemplate: "<span class='form__message'></span>"
			});
		},
		addPaymentMethod: function addPaymentMethod(formObj) {

			var arrLength = formObj.length;
			var payment = {};

			for (var i = 0; i < arrLength; i++) {
				payment[formObj[i].name] = formObj[i].value;
			}

			return payment;
		},
		watchSendWelcomeEmail: _ember['default'].observer('SendWelcomeEmail', function () {
			if (this.get("SendWelcomeEmail") === false) {
				SendWelcomeEmail = false;
			} else {
				SendWelcomeEmail = true;
			}
		}),
		errorMessage: function errorMessage(Message) {
			this.set("ErrorMessage", Message);
			this.set("isError", true);
		},
		validateForm: function validateForm(formId) {
			var form = _ember['default'].$(formId);

			form.parsley({
				excluded: 'input[type=button], input[type=submit], input[type=reset]',
				inputs: 'input, textarea, select, input[type=hidden], :hidden'
			}).validate();

			return form.parsley().isValid();
		},
		watchUserName: _ember['default'].observer('userName', function () {
			this.set('userName', this.get('entryUtil').noSpaces(this.get('entryUtil').onlyAlphaNumeric(this.get('userName'))));
		}),
		actions: {
			closeModalError: function closeModalError() {
				this.set("isError", false);
			},
			moveToOverview: function moveToOverview() {
				if (this.validateForm('#subaccount')) {
					var paymentFormArr = _ember['default'].$('#subaccount').serializeArray();
					this.set('paymentToAdd', this.addPaymentMethod(paymentFormArr));
					_ember['default'].$("#businessInfo").hide();
					_ember['default'].$("#Overview").show();
				}
			},
			moveCompanyInfo: function moveCompanyInfo() {
				_ember['default'].$("#businessInfo").show();
				_ember['default'].$("#Overview").hide();
			},
			create: function create() {
				var _this = this;

				$('.complete').prop('disabled', true);
				var options = this.get("paymentToAdd");
				options.SendWelcomeEmail = this.get("SendWelcomeEmail");
				this.get("api").createSubAccount(options).then(function (data) {
					_this.set("link", data.Link);
					//Ember.$("#email").show();
					//Ember.$("#Overview").hide();
					if (_vopay_appConfigEnvironment['default'].isWhitelabel) {
						_this.get("routing").transitionTo("dashboard.sub-accounts");
					} else {
						_ember['default'].$("#email").show();
						_ember['default'].$("#Overview").hide();
					}
				}, function (error) {
					_this.errorMessage(error);
					_ember['default'].$('.complete').removeAttr('disabled');
				});
			},
			SubAccountMove: function SubAccountMove() {
				this.get("routing").transitionTo("dashboard.sub-accounts");
			},
			CopyLink: function CopyLink() {
				/* Get the text field */
				var copyText = document.getElementById("link");

				/* Select the text field */
				copyText.select();
				copyText.setSelectionRange(0, 99999); /*For mobile devices*/

				/* Copy the text inside the text field */
				document.execCommand("copy");
			}
		}
	});
});