define('vopay_app/components/profile-dropdown', ['exports', 'ember', 'vopay_app/mixins/manage-cookies'], function (exports, _ember, _vopay_appMixinsManageCookies) {
	exports['default'] = _ember['default'].Component.extend(_vopay_appMixinsManageCookies['default'], {
		routing: _ember['default'].inject.service('-routing'),
		session: _ember['default'].inject.service('session'),
		api: _ember['default'].inject.service('api'),
		init: function init() {
			this._super.apply(this, arguments);
			this.set("accountID", localStorage.getItem("accountID"));
			this.set("AccountName", localStorage.getItem("AccountName"));
			this.set('isUserManagement', localStorage.getItem("UserManagementEnabled") === '1');
		},
		actions: {
			logout: function logout() {
				clearTimeout(window.sessionTimeout);
				localStorage.removeItem("key");
				localStorage.removeItem("accountID");
				localStorage.removeItem("signature");
				location.reload(true);
			}
		}
	});
});