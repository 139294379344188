define('vopay_app/routes/dashboard/bulk-payment/templates/index', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		firebase: _ember['default'].inject.service('firebase'),
		model: function model() {
			var authed = true;
			if (authed) {

				return _ember['default'].RSVP.hash({
					templates: this.get("firebase").getRecordUser("templates"),
					beneficiaries: this.get("firebase").getRecordUser("beneficiary")
				});
			} else {
				this.transitionTo('login');
			}
		}
	});
});