define('vopay_app/routes/dashboard/file-upload/details', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service('session'),
    api: _ember['default'].inject.service('api'),
    moveToTop: (function () {
      window.scrollTo(0, 0);
    }).on('activate'),
    model: function model(id) {

      var authed = true;
      if (authed) {
        return _ember['default'].RSVP.hash({
          fileUploadDetails: this.get('api').getUploadedFileDetails(id["FileUploadID"]),
          file: this.get('api').getUploadedFiles(id["FileUploadID"])
        });
      } else {
        this.transitionTo('login');
      }
    }

  });
});