define('vopay_app/components/password-widget', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		password: null,
		hasCharcters: false,
		hasNumber: false,
		watchPassword: _ember['default'].observer('password', function () {
			var password = this.get("password");
			if (password.length >= 8) {
				this.set("hasCharcters", true);
			} else {
				this.set("hasCharcters", false);
			}
			if (/\d/g.test(password)) {
				this.set("hasNumber", true);
			} else {
				this.set("hasNumber", false);
			}
			if (/\d/g.test(password) && password.length >= 8) {
				this.sendAction("isValidHandler", true);
			} else {
				this.sendAction("isValidHandler", false);
			}
		})
	});
});