define("vopay_app/components/client-transfer-funds", ["exports", "ember"], function (exports, _ember) {
	exports["default"] = _ember["default"].Component.extend({
		Currency: "CAD",
		currency2: "CAD",
		CurrencyName: "Canadian Dollar",
		api: _ember["default"].inject.service('api'),
		currencies3: {
			CAD: {
				name: 'Canadian Dollar',
				iso3: 'CAD'
			}
		},
		options: ["Fund and Transfer", "Transfer and Withdraw", "Fund, Transfer and Withdraw"],
		country: "Canada",
		wachValue: _ember["default"].observer('amount', function () {

			var isZeroAppend = false;
			var amount = this.get('amount');
			if (amount) {
				var myElement = this.$("#amount")[0];
				if (myElement) {
					var start = myElement.selectionStart;
					var end = myElement.selectionEnd;

					var amount2 = amount;
					if (amount.replace()) {
						amount2 = amount.replace(/[^\d.]+/g, '');
						amount2 = Math.floor(parseFloat(amount2));
					}
					var split = amount.split(".");
					var ending = split[1];

					var amount3 = undefined;

					var ending2 = null;
					if (ending && ending.length && ending.length > 2) {

						ending2 = ending.substring(0, 2);
					} else {
						ending2 = ending;
					}

					if (ending2 || ending2 === 0) {
						amount3 = amount2.toLocaleString(undefined) + "." + ending2;
					} else if (split[0] == "") {

						if (ending2 || ending2 === 0) {
							amount3 = "0." + ending2;
							isZeroAppend = true;
						} else {
							amount3 = "0.";
							isZeroAppend = true;
						}
					} else if (!ending && split.length == 2) {
						amount3 = amount2.toLocaleString(undefined) + ".";
					} else {
						if (amount2) {
							amount3 = amount2.toLocaleString(undefined);
						} else {
							amount3 = "";
						}
					}

					this.$(myElement).val(amount3);

					if (myElement.setSelectionRange) {
						if (isZeroAppend) {
							myElement.setSelectionRange(start + 2, end + 2);
						} else if (amount3.length > amount.length && amount3.length > 4) {
							myElement.setSelectionRange(start + 1, end + 1);
						} else if (amount3.length < amount.length) {
							myElement.setSelectionRange(start - 1, end - 1);
						} else {
							myElement.setSelectionRange(start, end);
						}
					} else if (myElement.createTextRange) {
						var range = myElement.createTextRange();
						range.collapse(true);
						range.moveEnd('character', start);
						range.moveStart('character', end);
						range.select();
					}
				}
			}
		}),
		didInsertElement: function didInsertElement() {

			this._super.apply(this, arguments);
			_ember["default"].$("#paymentForm").parsley().destroy();
			_ember["default"].$('form').parsley({
				successClass: " ",
				errorClass: "form__input--error",
				errorsWrapper: "<p></p>",
				errorTemplate: "<span class='form__message'></span>"
			});
		},
		getFormObject: function getFormObject(formObj) {
			var arrLength = formObj.length;
			var payment = {};

			for (var i = 0; i < arrLength; i++) {
				payment[formObj[i].name] = formObj[i].value;
			}

			return payment;
		},

		errorMessage: function errorMessage(Message) {
			this.set("ErrorMessage", Message);
			this.set("isError", true);
		},
		validateForm: function validateForm(formId) {

			var form = _ember["default"].$(formId);

			form.parsley({
				excluded: 'input[type=button], input[type=submit], input[type=reset]',
				inputs: 'input, textarea, select, input[type=hidden], :hidden'
			}).validate();

			return form.parsley().isValid();
		},
		actions: {
			moveToPayment: function moveToPayment() {
				$("#Overview").hide();
				$("#paymentInfo").show();
			},

			overview: function overview() {
				var formData = this.getFormObject(_ember["default"].$('#paymentForm').serializeArray());
				formData.Amount = _ember["default"].$("#amount").val().replace(/\,/g, "");

				if (this.validateForm("#paymentForm")) {

					this.set("paymentToAdd", formData);
					this.set("paymentToAdd.TransferType", this.get("transferType"));
					$("#Overview").show();
					$("#paymentInfo").hide();
				}
			},
			handleType: function handleType(type) {
				this.set("transferType", type);
			},
			DebitorChange: function DebitorChange() {},
			CreditorChange: function CreditorChange() {},
			closeModalError: function closeModalError() {
				this.set("isError", false);
			},
			transfer: function transfer() {
				var _this = this;

				var paymentForm = this.get("paymentToAdd");

				if (paymentForm.TransferType === "Fund and Transfer") {

					this.get("api").fundTransfer(paymentForm).then(function (data) {

						window.location.href = "#/dashboard/transaction/Fund/" + data.FundingTransactionID + "/" + _this.get("Currency");
					}, function (error) {

						_this.errorMessage(error);
					});
				} else if (paymentForm.TransferType === "Transfer and Withdraw") {

					this.get("api").transferWithdraw(paymentForm).then(function (data) {
						window.location.href = "#/dashboard/transaction/Withdraw/" + data.WithdrawTransactionID + "/" + _this.get("Currency");
					}, function (error) {
						_this.errorMessage(error);
					});
				} else {
					this.get("api").fundTransferWithdraw(paymentForm).then(function (data) {
						window.location.href = "#/dashboard/transaction/Withdraw/" + data.WithdrawTransactionID + "/" + _this.get("Currency");
					}, function (error) {
						_this.errorMessage(error);
					});
				}
			}
		}

	});
});