define('vopay_app/components/subaccount-details', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		api: _ember['default'].inject.service('api'),
		init: function init() {
			this._super.apply(this, arguments);
			var data = this.get("data");
			this.set("SubAccounts", data.Subaccounts);
			var subaaccount = this.findSubAccount(data.Subaccounts, this.get("accountID"));
			this.set("SubAccount", subaaccount);
			var Balance = this.findSubAccountBalance(subaaccount, this.get("currency"));
			this.set("Balance", Balance);
		},
		findSubAccount: function findSubAccount(subAccountList, name) {

			for (var key in subAccountList) {
				if (subAccountList[key].AccountID == name) {
					return subAccountList[key];
				}
			}
		},
		findSubAccountBalance: function findSubAccountBalance(subAccount, currency) {

			for (var key in subAccount.Balances) {
				if (subAccount.Balances[key].currency == currency) {
					return subAccount.Balances[key];
				}
			}
		}
	});
});