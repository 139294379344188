define('vopay_app/components/create-client-account', ['exports', 'ember', 'vopay_app/constants', 'vopay_app/utils/initializeLocationIq', 'vopay_app/config/environment'], function (exports, _ember, _vopay_appConstants, _vopay_appUtilsInitializeLocationIq, _vopay_appConfigEnvironment) {
	var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

	function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

	var businessShortName = _vopay_appConfigEnvironment['default'].businessShortName;
	var businessLongName = _vopay_appConfigEnvironment['default'].businessLongName;
	exports['default'] = _ember['default'].Component.extend({
		api: _ember['default'].inject.service('api'),
		routing: _ember['default'].inject.service('-routing'),
		SupportEmail: _vopay_appConfigEnvironment['default'].businessEmail,
		LongName: businessLongName,
		ShortName: businessShortName,
		Token: null,
		Currency: "CAD",
		CurrencyName: "Canadian Dollar",
		CurrencyList: {
			CAD: {
				name: 'Canadian Dollar',
				iso3: 'CAD'
			},
			USD: {
				name: 'United States Dollar',
				iso3: 'USD'
			}
		},
		Currencies: _vopay_appConstants['default'].currencies,
		init: function init() {
			var _this = this;

			this._super.apply(this, arguments);

			this.set('businessTypes', this.get('businessTypes.BusinessTypes'));
			this.set('businessTypeMap', this.restructureBusinessTypes(Object.values(this.get('businessTypes'))));

			this.set('stage', 'clientInfo');
			this.set('formId', 'create-client-account');

			this.set('clientTypeOptions', ['Individual', 'Business']);
			this.set('clientType', 'Individual');

			this.set('countryOptions', this.createCountryOptions());
			this.set('isoMap', this.createIsoMap(Object.values(_vopay_appConstants['default'].countriesFull.result)));
			this.set('country', 'Canada');

			this.set('businessTypeOptions', this.createBusinessTypeOptions());
			this.set('businessType', this.get('businessTypeOptions')[0]);

			this.set('businessCategoryOptions', this.createBusinessCategoryOptions(this.get('businessType')));
			this.set('businessCategory', this.get('businessCategoryOptions')[0]);

			var addressIds = {
				addressLine1: 'addressLine1',
				city: 'city',
				province: 'province',
				postalcode: 'postalcode'
			};

			this.set('addressIds', addressIds);
			var options = null;
			var AccountSelectionMethod = "any";

			if (location.hostname == "localhost") {
				options = {
					RedirectURL: "https://" + location.hostname + ":4200/",
					customerName: this.get("LongName"),
					AccountSelectionMethod: AccountSelectionMethod
				};
			} else if (location.hostname == "bs-local.com") {

				options = {
					RedirectURL: "https://" + location.hostname + ":4200/",
					AccountSelectionMethod: AccountSelectionMethod,
					customerName: this.get("LongName")
				};
			} else {
				options = {
					RedirectURL: "https://" + location.hostname + "/",
					AccountSelectionMethod: AccountSelectionMethod,
					customerName: this.get("LongName")
				};
			}
			this.get('api').getApiURL(options).then(function (data) {
				_this.set("iQ11URL", data.EmbedURL);
			});
		},
		didInsertElement: function didInsertElement() {

			this._super.apply(this, arguments);

			var self = this;
			window.addEventListener('message', function (e) {
				if (e.data.step == "ACCOUNT_SELECTED") {
					self.set("accountId", e.data.accountId);
				}
				if (e.data.step == "REDIRECT") {
					self.set("loginId", e.data.loginId);
					self.set("institution", e.data.institution);
					//location.href = e.data.url;
				}
				if (e.data.Step == "LINK") {
					self.set("institution", e.data.Institution);
					self.set("AccountNumber", e.data.AccountNumber);
					self.set("BankId", e.data.BankId);
					self.set("InstitutionNumber", e.data.InstitutionNumber);
				}
			});
		},
		initAutoAddress: function initAutoAddress() {
			var isoCode = this.get('isoMap')[this.get('country')];
			var ids = this.get('addressIds');

			(0, _vopay_appUtilsInitializeLocationIq['default'])(ids.addressLine1, ids.city, ids.province, ids.postalcode, isoCode);
		},

		restructureBusinessTypes: function restructureBusinessTypes(businessTypeList) {
			var initTypes = {};
			var initCategories = {};

			return businessTypeList.reduce(function (types, type) {
				return _extends({}, types, _defineProperty({}, type.Industry, {
					id: type.BusinessTypeID,
					categories: Object.values(type.Categories).reduce(function (categories, category) {
						return _extends({}, categories, _defineProperty({}, category.Category, { id: category.BusinessTypeCategoryID }));
					}, initCategories)
				}));
			}, initTypes);
		},
		createIsoMap: function createIsoMap(countries) {
			return countries.reduce(function (map, curr) {
				return _extends({}, map, _defineProperty({}, curr.Country, curr.Abbreviation));
			}, {});
		},
		createCountryOptions: function createCountryOptions() {
			return ['Canada', 'United States'];
		},
		createBusinessTypeOptions: function createBusinessTypeOptions() {
			return Object.keys(this.get('businessTypeMap')).sort();
		},
		createBusinessCategoryOptions: function createBusinessCategoryOptions(businessType) {
			return Object.keys(this.get('businessTypeMap')[businessType].categories).sort();
		},
		createPayload: function createPayload() {
			var clientType = this.get('clientType').toLowerCase();

			var clientDetails = {
				common: {
					ClientType: clientType,
					FirstName: this.get('firstName') && this.get('firstName').trim(),
					LastName: this.get('lastName') && this.get('lastName').trim(),
					EmailAddress: this.get('email') && this.get('email').trim(),
					Address1: this.get('address1') && this.get('address1').trim(),
					Address2: this.get('address2') && this.get('address2').trim(),
					City: this.get('city') && this.get('city').trim(),
					Province: this.get('province') && this.get('province').trim(),
					Country: this.get('country'),
					PostalCode: this.get('postalCode') && this.get('postalCode').trim(),
					Token: this.get('Token'),
					Currency: this.get('Currency')
				},
				individual: {
					DOB: this.get('dateOfBirth') && this.get('dateOfBirth').trim(),
					SINLastDigits: this.get('sinLastDigits') && this.get('sinLastDigits').trim()
				},
				business: {
					BusinessName: this.get('businessName') && this.get('businessName').trim(),
					BusinessNumber: this.get('businessNumber') && this.get('businessNumber').trim(),
					BusinessWebsite: this.get('businessWebsite') && this.get('businessWebsite').trim(),
					BusinessPhone: this.get('businessPhone') && this.get('businessPhone').trim(),
					BusinessTypeID: this.get('businessTypeId'),
					BusinessTypeCategoryID: this.get('businessCategoryId')
				}
			};

			return _extends({}, clientDetails.common, clientDetails[clientType]);
		},
		updateErrorMessage: function updateErrorMessage(Message) {
			this.set("ErrorMessage", Message);
			this.set("isError", true);
		},
		validateForm: function validateForm(formId) {
			var form = _ember['default'].$('#' + formId);

			form.parsley({
				excluded: 'input[type=button], input[type=submit], input[type=reset]',
				inputs: 'input, textarea, select, input[type=hidden], :hidden'
			}).validate();

			return form.parsley().isValid();
		},
		updateAddressFields: function updateAddressFields() {
			var ids = this.get('addressIds');

			this.set('address1', $("#" + ids.addressLine1).val());
			this.set('city', $("#" + ids.city).val());
			this.set('province', $("#" + ids.province).val());
			this.set('postalCode', $("#" + ids.postalcode).val());
		},
		getCurrencyName: function getCurrencyName(name) {
			var ctr = this.get('Currencies');
			for (var key in ctr) {
				if (ctr[key].iso3 === name) {
					return ctr[key].name;
				}
			}
		},
		actions: {
			accountCurrencyChanged: function accountCurrencyChanged(currency) {
				this.set("Currency", currency);
				this.set("CurrencyName", this.getCurrencyName(currency));
			},

			showiq11Frame: function showiq11Frame() {
				this.set("isiQ11", true);
				var self = this;
				window.Iq11Interval = setInterval(function () {
					if (localStorage.getItem("FlinksToken") != null) {

						self.set("Token", localStorage.getItem("FlinksToken"));

						localStorage.removeItem("FlinksToken");

						self.set("isiQ11", false);
					}
				}, 100);
			},
			removeBank: function removeBank() {
				this.set("institution", null);
				this.set("AccountNumber", null);
				this.set("BankId", null);
				this.set("InstitutionNumber", null);
				this.set("Token", null);
			},
			closeModalIQ11: function closeModalIQ11() {
				this.set("isiQ11", false);
				clearInterval(window.Iq11Interval);
			},

			updateClientType: function updateClientType(input) {
				this.set('clientType', input);
			},
			updateFirstName: function updateFirstName(input) {
				this.set('firstName', input.replace(/[^A-Za-z0-9 ]/g, ''));
			},
			updateLastName: function updateLastName(input) {
				this.set('lastName', input.replace(/[^A-Za-z0-9 ]/g, ''));
			},
			updateEmail: function updateEmail(input) {
				this.set('email', input);
			},
			updateAddress1: function updateAddress1(input) {
				this.set('address1', input);
				this.initAutoAddress();
			},
			updateAddress2: function updateAddress2(input) {
				this.set('address2', input);
			},
			updateCity: function updateCity(input) {
				this.set('city', input);
			},
			updateProvince: function updateProvince(input) {
				this.set('province', input.toUpperCase().replace(/[^A-Z]/g, '').substring(0, 2));
			},
			updatePostalCode: function updatePostalCode(input) {
				this.set('postalCode', input.toUpperCase().replace(/[^A-Z0-9]/g, ''));
			},
			updateCountry: function updateCountry(input) {
				this.set('country', input);
			},
			updateDateOfBirth: function updateDateOfBirth(input) {
				this.set('dateOfBirth', input.replace(/[^0-9-]/g, '').substring(0, 10));
			},
			updateSinLastDigits: function updateSinLastDigits(input) {
				this.set('sinLastDigits', input.replace(/\D/g, '').substring(0, 4));
			},
			updateBusinessName: function updateBusinessName(input) {
				this.set('businessName', input);
			},
			updateBusinessNumber: function updateBusinessNumber(input) {
				this.set('businessNumber', input);
			},
			updateBusinessWebsite: function updateBusinessWebsite(input) {
				this.set('businessWebsite', input);
			},
			updateBusinessPhone: function updateBusinessPhone(input) {
				this.set('businessPhone', input.replace(/\D/g, ''));
			},
			updateBusinessType: function updateBusinessType(input) {
				this.set('businessType', input);
				this.set('businessTypeId', this.get('businessTypeMap')[input].id);
				this.set('businessCategoryOptions', this.createBusinessCategoryOptions(input));
				this.set('businessCategory', this.get('businessCategoryOptions')[0]);
			},
			updateBusinessCategory: function updateBusinessCategory(input) {
				var map = this.get('businessTypeMap');
				var type = this.get('businessType');

				var categoryId = map[type].categories[input].id;

				this.set('businessCategory', input);
				this.set('businessCategoryId', categoryId);
			},
			createClientAccount: function createClientAccount() {
				var _this2 = this;

				this.get('api').postClientAccount(this.createPayload()).then(function () {
					_this2.get("routing").transitionTo('dashboard.client-accounts');
				}, function (error) {
					_this2.updateErrorMessage(error);
				});
			},
			moveToOverview: function moveToOverview() {
				this.updateAddressFields();

				var isValid = this.validateForm(this.get('formId'));

				if (isValid) {
					this.set('payload', this.createPayload());

					this.set('stage', 'overview');
				}
			},
			moveToClientInfo: function moveToClientInfo() {
				this.set('stage', 'clientInfo');
			},
			closeModalError: function closeModalError() {
				this.set("isError", false);
			}
		}
	});
});