define('vopay_app/components/template-list', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({

		firebase: _ember['default'].inject.service('firebase'),
		routing: _ember['default'].inject.service('-routing'),
		download: function download(filename, text) {
			var element = document.createElement('a');
			element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(text));
			element.setAttribute('download', filename);

			element.style.display = 'none';
			document.body.appendChild(element);

			element.click();

			document.body.removeChild(element);
		},
		init: function init() {
			this._super.apply(this, arguments);
			var data = this.get("templates");
			if (this.get("isPayment") == "true") {

				for (var key in data) {
					if (data.hasOwnProperty(key)) {
						if (data[key].PaymentType == "Collect") {
							delete data[key];
						}
					}
				}
			} else {
				for (var key in data) {
					if (data.hasOwnProperty(key)) {
						if (data[key].PaymentType == "Payment") {
							delete data[key];
						}
					}
				}
			}
			this.set("templates", data);
		},
		actions: {
			deleteItem: function deleteItem(key) {
				var _this = this;

				this.get("firebase").deleteRecordUser("templates", key);
				this.get("firebase").getRecordUser("templates").then(function (data) {
					if (_this.get("isPayment") == "true") {

						for (var key in data) {
							if (data.hasOwnProperty(key)) {
								if (data[key].PaymentType == "Collect") {
									delete data[key];
								}
							}
						}
					} else {
						for (var key in data) {
							if (data.hasOwnProperty(key)) {
								if (data[key].PaymentType == "Payment") {
									delete data[key];
								}
							}
						}
					}
					_this.set("templates", data);
				});
			},
			ExportTemplate: function ExportTemplate(keyTemp) {
				// generate file
				var beneficiaries = this.get("beneficiaries");
				var listing = [];
				var templates = this.get("templates");
				var template = templates[keyTemp];
				for (var key in beneficiaries) {
					var beneficiary = beneficiaries[key];
					for (var key2 in beneficiary.templates) {
						if (key2 == keyTemp) {
							if (template.Service == "EFT") {
								var data = {
									FirstName: beneficiary.FirstName,
									LastName: beneficiary.LastName,
									RecipientName: beneficiary.RecipientName,
									CompanyName: beneficiary.CompanyName,
									DOB: beneficiary.DOB,
									PhoneNumber: beneficiary.PhoneNumber,
									Address1: beneficiary.Address1,
									Address2: beneficiary.Address2,
									City: beneficiary.City,
									Province: beneficiary.Province,
									Country: beneficiary.Country,
									PostalCode: beneficiary.PostalCode,
									FinancialInstitutionNumber: beneficiary.FinancialInstitutionNumber,
									BranchTransitNumber: beneficiary.BranchTransitNumber,
									AccountNumber: beneficiary.AccountNumber,
									ABARoutingNumber: beneficiary.Aba,
									IBAN: beneficiary.Iban,
									SortCode: beneficiary.SortCode,
									Email: beneficiary.Email,
									Currency: beneficiary.Currency,
									Amount: 0.00,
									ClientReferenceNumber: beneficiary.ClientReferenceNumber,
									Notes: "",
									KYCPerformed: beneficiary.KYCPerformed,
									KYCReferenceNumber: beneficiary.KYCReferenceNumber
								};
								listing.push(data);
							} else {
								var data = {
									FirstName: beneficiary.FirstName,
									LastName: beneficiary.LastName,
									RecipientName: beneficiary.RecipientName,
									CompanyName: beneficiary.CompanyName,
									DOB: beneficiary.DOB,
									PhoneNumber: beneficiary.PhoneNumber,
									Address1: beneficiary.Address1,
									Address2: beneficiary.Address2,
									City: beneficiary.City,
									Province: beneficiary.Province,
									Country: beneficiary.Country,
									PostalCode: beneficiary.PostalCode,
									FinancialInstitutionNumber: beneficiary.FinancialInstitutionNumber,
									BranchTransitNumber: beneficiary.BranchTransitNumber,
									AccountNumber: beneficiary.AccountNumber,
									ABARoutingNumber: beneficiary.Aba,
									IBAN: beneficiary.Iban,
									SortCode: beneficiary.SortCode,
									Email: beneficiary.Email,
									MessageForRecipient: "",
									Currency: beneficiary.Currency,
									Amount: 0.00,
									ClientReferenceNumber: beneficiary.ClientReferenceNumber,
									Notes: "",
									KYCPerformed: beneficiary.KYCPerformed,
									KYCReferenceNumber: beneficiary.KYCReferenceNumber
								};
								listing.push(data);
							}
						}
					}
				}
				var listingObject = {};

				listingObject.Name = template.Name;
				listingObject.Description = template.Description;
				listingObject.Service = template.Service;
				listingObject.PaymentType = template.PaymentType;
				listingObject.Payees = Object.assign({}, listing);
				this.download(template.Name + ".json", JSON.stringify(listingObject, null, "	"));
			},
			movetoDetails: function movetoDetails(key) {
				if (this.get("isPayment") == "true") {
					window.location.href = "/#/dashboard/bulk-payment/group/" + key;
				} else {
					window.location.href = "/#/dashboard/bulk-collect/group/" + key;
				}
			},
			movetoEdit: function movetoEdit(key) {
				if (this.get("isPayment") == "true") {
					window.location.href = "/#/dashboard/bulk-payment/edit/" + key;
				} else {
					window.location.href = "/#/dashboard/bulk-collect/edit/" + key;
				}
			}
		}
	});
});