define('vopay_app/routes/dashboard/link-accounts/index', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		api: _ember['default'].inject.service('api'),
		firebase: _ember['default'].inject.service('firebase'),
		model: function model() {
			return _ember['default'].RSVP.hash({
				linkedAccounts: this.get("firebase").getRecordUser("linkedAccount")
			});
		}
	});
});