define('vopay_app/utils/createCountryOptions', ['exports'], function (exports) {
    function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

    var createCountryOptions = function createCountryOptions(countries) {
        var countryList = countries.map(function (_ref) {
            var Country = _ref.Country;
            return Country;
        }).filter(function (value, idx, self) {
            return self.indexOf(value) === idx;
        }).sort();

        return ['Canada', 'United States'].concat(_toConsumableArray(countryList));
    };
    exports.createCountryOptions = createCountryOptions;
});