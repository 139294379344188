define('vopay_app/components/add-payment-method', ['exports', 'ember', 'vopay_app/constants', 'vopay_app/helpers/complete-with-zeros', 'vopay_app/utils/entry-filter', 'vopay_app/utils/base64encoder', 'vopay_app/mixins/error-handler', 'vopay_app/config/environment', 'vopay_app/utils/query-param-builder'], function (exports, _ember, _vopay_appConstants, _vopay_appHelpersCompleteWithZeros, _vopay_appUtilsEntryFilter, _vopay_appUtilsBase64encoder, _vopay_appMixinsErrorHandler, _vopay_appConfigEnvironment, _vopay_appUtilsQueryParamBuilder) {
	var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

	var businessShortName = _vopay_appConfigEnvironment['default'].businessShortName;
	var businessLongName = _vopay_appConfigEnvironment['default'].businessLongName;
	exports['default'] = _ember['default'].Component.extend(_vopay_appMixinsErrorHandler['default'], {
		SupportEmail: _vopay_appConfigEnvironment['default'].businessEmail,
		LongName: businessLongName,
		ShortName: businessShortName,
		api: _ember['default'].inject.service('api'),
		firebase: _ember['default'].inject.service('firebase'),
		routing: _ember['default'].inject.service('-routing'),
		institutionName: '',
		financialInstitutionNumber: null,
		accountNumber: null,
		branchTransitNumber: null,
		entryUtil: _vopay_appUtilsEntryFilter['default'].create(),
		isStore: true,
		isDefault: true,
		userName: null,
		signatureNumbers: [],
		signatureCurrNum: 0,
		signatureNumDel: 0,
		InformationOptions: ["Manual", "Online Banking"],
		frequencyOptions: ["One Time", "Reoccuring"],
		accountOptions: ["Deposit", "Withdraw"],
		accountTypeOptions: ["Business", "Individual"],
		defaultAccountType: "Business",
		paymentTypeOptions: ["Bank Account", "Interac E-Transfer"],
		paymentTypeOptionsCollect: ["Bank Account", "Interac E-Transfer", "Visa Direct"],
		paymentTypeOptionsClientAccount: ["Bank Account", "Interac E-Transfer"],
		paymentTypeOptionsSingle: ["Bank Account"],
		defaultPaymentType: "Bank Account",
		AccountSelection: ["Any", "Online", "Manual"],
		Language: ["English", "French"],
		isBusiness: true,
		isDeposit: false,
		isVisaDirect: false,
		isInteracEtransfer: false,
		purposes: ["Sending to Self", "Rent or Mortgage", "Family Support", "Family Emergency", "Sending to Friend", "Gift"],
		purpose: null,
		country: "Canada",
		country2: "Canada",
		currency: "CAD",
		currencyName: "Canadian Dollar",
		currency2: "CAD",
		countryID: 2,
		stageNumber: 1,
		ClientAccountsEnabled: false,
		stageName: "Payment Details",
		defaultType: "Deposit",
		amount: "",
		max: "0",
		FlinksURL: "",
		fundCount: 0,
		isNew: false,
		beneficiaries: null,
		isoCountry: "CA",
		currencies2: _vopay_appConstants['default'].currencies,
		currencies4: {
			CAD: {
				name: 'Canadian Dollar',
				iso3: 'CAD'
			},
			USD: {
				name: 'United States Dollar',
				iso3: 'USD'
			}
		},
		currencies5: {
			CAD: {
				name: 'Canadian Dollar',
				iso3: 'CAD'
			}
		},
		agreedToTerms: false,
		// Avoiding wrong entry data
		watchIsName: _ember['default'].observer("isAgree", function () {
			if (this.get("isAgree") === true) {
				_ember['default'].$("#termsConditions").show();
				_ember['default'].$("#overlay").show();
			}
		}),
		watchFirstName: _ember['default'].observer('firstname', function () {
			this.set('firstname', this.get('entryUtil').onlyNameCharacters(this.get('firstname')));
		}),

		watchLastName: _ember['default'].observer('lastname', function () {
			this.set('lastname', this.get('entryUtil').onlyNameCharacters(this.get('lastname')));
		}),

		watchRecipientName: _ember['default'].observer('RecipientName', function () {
			this.set('RecipientName', this.get('entryUtil').onlyNameCharacters(this.get('RecipientName')));
		}),
		watchUserName: _ember['default'].observer('userName', function () {

			this.set('userName', this.get('entryUtil').onlyCharacters(this.get('userName')));
		}),

		watchfInstitutionNumber: _ember['default'].observer('financialInstitutionNumber', function () {

			this.set('financialInstitutionNumber', this.get('entryUtil').onlyNumbers(this.get('financialInstitutionNumber')));
		}),

		watchAccountNumber: _ember['default'].observer('accountNumber', function () {

			this.set('accountNumber', this.get('entryUtil').onlyNumbers(this.get('accountNumber')));
		}),

		watchBranchTransitNumber: _ember['default'].observer('branchTransitNumber', function () {

			this.set('branchTransitNumber', this.get('entryUtil').onlyNumbers(this.get('branchTransitNumber')));
		}),
		watchPhoneNumber: _ember['default'].observer('PhoneNumber', function () {

			this.set('PhoneNumber', this.get('entryUtil').onlyNumbers(this.get('PhoneNumber')));
		}),
		watchDualPhoneNumber: _ember['default'].observer('DualPhoneNumber', function () {

			this.set('DualPhoneNumber', this.get('entryUtil').onlyNumbers(this.get('DualPhoneNumber')));
		}),
		watchSignatoryPhoneNumber: _ember['default'].observer('SignatoryPhoneNumber', function () {

			this.set('SignatoryPhoneNumber', this.get('entryUtil').onlyNumbers(this.get('SignatoryPhoneNumber')));
		}),
		watchPhoneNumberRequest: _ember['default'].observer('PhoneNumberRequest', function () {

			this.set('PhoneNumberRequest', this.get('entryUtil').onlyNumbers(this.get('PhoneNumberRequest')));
		}),
		wachValue: _ember['default'].observer('amount', function () {
			var isZeroAppend = false;
			var amount = this.get('amount');
			if (amount) {
				var myElement = this.$("#amount")[0];
				if (myElement) {
					var start = myElement.selectionStart;
					var end = myElement.selectionEnd;

					var amount2 = amount;
					if (amount.replace()) {
						amount2 = amount.replace(/[^\d.]+/g, '');
						amount2 = Math.floor(parseFloat(amount2));
					}
					var split = amount.split(".");
					var ending = split[1];

					var amount3 = undefined;

					var ending2 = null;
					if (ending && ending.length && ending.length > 2) {

						ending2 = ending.substring(0, 2);
					} else {
						ending2 = ending;
					}

					if (ending2 || ending2 === 0) {
						amount3 = amount2.toLocaleString(undefined) + "." + ending2;
					} else if (split[0] == "") {

						if (ending2 || ending2 === 0) {
							amount3 = "0." + ending2;
							isZeroAppend = true;
						} else {
							amount3 = "0.";
							isZeroAppend = true;
						}
					} else if (!ending && split.length == 2) {
						amount3 = amount2.toLocaleString(undefined) + ".";
					} else {
						if (amount2) {
							amount3 = amount2.toLocaleString(undefined);
						} else {
							amount3 = "";
						}
					}

					this.$(myElement).val(amount3);

					if (myElement.setSelectionRange) {
						if (isZeroAppend) {
							myElement.setSelectionRange(start + 2, end + 2);
						} else if (amount3.length > amount.length && amount3.length > 4) {
							myElement.setSelectionRange(start + 1, end + 1);
						} else if (amount3.length < amount.length) {
							myElement.setSelectionRange(start - 1, end - 1);
						} else {
							myElement.setSelectionRange(start, end);
						}
					} else if (myElement.createTextRange) {
						var range = myElement.createTextRange();
						range.collapse(true);
						range.moveEnd('character', start);
						range.moveStart('character', end);
						range.select();
					}
				}
			}
		}),
		init: function init() {
			var _this = this;

			this._super.apply(this, arguments);
			var currencyList = this.get("currencies2");
			if (localStorage.getItem("ClientAccountsEnabled") === "1") {
				this.set("ClientAccountsEnabled", true);
			}
			if (localStorage.getItem("GCMEnabled") === "1") {} else if (localStorage.getItem("USDEFTSendEnabled") === "1") {
				this.set("currencies2", this.get("currencies4"));
			} else {
				this.set("currencies2", this.get("currencies5"));
			}
			if (sessionStorage.getItem("currency")) {
				this.set("currency2", sessionStorage.getItem("currency"));

				this.set("currencyName", currencyList[sessionStorage.getItem("currency")].name);
				sessionStorage.removeItem("currency");
			}

			var bankAccounts = this.get("bankAccounts.BankAccounts");
			var myAccounts = Object.values(bankAccounts);
			myAccounts = myAccounts.map(function (bankAccount) {
				return _extends({}, bankAccount, { Type: 'Bank Account', Country: 'CA' });
			});

			var firebaseAccounts = $.extend(true, {}, this.get("firebaseBeneficiaries"), this.get("firebaseLinkedAccounts"));
			var nonBankAccounts = Object.values(firebaseAccounts);
			nonBankAccounts = nonBankAccounts.filter(function (account) {
				return account.Type != "Bank Account";
			});

			var data = myAccounts.concat(nonBankAccounts);

			if (data == null || Object.keys(data).length < 3000) {
				var keys;

				(function () {
					data = $.extend(true, {}, data, _this.get("accounts"));
					data = $.extend(true, {}, data, _this.get("linkedAccounts"));
					keys = Object.keys(data).sort(function (a, b) {
						var aName = data[a].timestamp;
						var bName = data[b].timestamp;
						if (!aName) {
							aName = 0;
						}
						if (!bName) {
							bName = 0;
						}
						return aName < bName ? -1 : aName > bName ? 1 : 0;
					});

					var ordered = {};
					keys.forEach(function (key) {
						ordered[key] = data[key];
					});
					data = ordered;
				})();
			}

			this.set("beneficiaries", data);
			var filteredAccounts = {};
			var type = "Bank Account";
			if (sessionStorage.getItem("Benefiricary")) {

				var value2 = sessionStorage.getItem("Benefiricary");
				var data4 = this.get("beneficiaries");
				var paymentToAdd2 = data4["" + value2];
				if (paymentToAdd2.AccountHolderName) {
					var payeeName = paymentToAdd2.AccountHolderName ? paymentToAdd2.AccountHolderName : paymentToAdd2.CreditCardHolderName;
					var nameParts = payeeName.split(" ");

					if (nameParts.length > 2 || nameParts.length < 2) {
						paymentToAdd2["CompanyName"] = payeeName;
					} else {
						paymentToAdd2["FirstName"] = nameParts[0];
						paymentToAdd2["LastName"] = nameParts[1];
					}
				}
				type = paymentToAdd2.Type ? paymentToAdd2.Type : 'Bank Account';

				this.set("currency2", paymentToAdd2.Currency);
				this.set("currencyName", currencyList[paymentToAdd2.Currency].name);
				if (type == "Bank Account") {
					this.set("defaultPaymentType", type);
				} else if (type == "Visa Direct") {
					this.set("defaultPaymentType", type);
					this.set("isVisaDirect", true);
				} else {
					this.set("defaultPaymentType", "Interac E-Transfer");
					this.set("isInteracEtransfer", true);
				}
			}

			var filteredAccounts = {};
			for (var key in data) {
				if (data[key]["Currency"] === this.get("currency2") && data[key]["Type"] === type) {
					filteredAccounts[key] = data[key];
				}
			}
			this.set("beneficiaries2", filteredAccounts);
			if (sessionStorage.getItem("Benefiricary")) {
				var value;
				var data3;
				var paymentToAdd;

				(function () {
					value = sessionStorage.getItem("Benefiricary");
					data3 = _this.get("beneficiaries2");

					var self = _this;
					setTimeout(function () {
						self.set("selected", true);
					}, 100);

					paymentToAdd = data3["" + value];

					if (paymentToAdd) {
						paymentToAdd.DollarAmount = 0;
						_this.set("isoCountry", paymentToAdd.Country);
						_this.set("paymentToAdd", paymentToAdd);

						if (paymentToAdd.CompanyName) {
							_this.set("currentSelection", paymentToAdd.CompanyName);
						} else if (paymentToAdd.AccountHolderName) {
							_this.set("currentSelection", paymentToAdd.AccountHolderName);
						} else if (paymentToAdd.RecipientName) {
							_this.set("currentSelection", paymentToAdd.RecipientName);
						} else if (paymentToAdd.FullName) {
							_this.set("currentSelection", paymentToAdd.FullName);
						} else {
							_this.set("currentSelection", paymentToAdd.FirstName + " " + paymentToAdd.LastName);
						}
					}

					sessionStorage.removeItem("Benefiricary");
				})();
			}

			var currencies2 = this.get("currencies2");
			var currencies = this.get("currencies.CurrenciesList");
			var currencies3 = {};
			for (var key in currencies) {
				if (currencies2[currencies[key]]) {
					currencies3[currencies[key]] = {};
					currencies3[currencies[key]]["name"] = currencies2[currencies[key]]["name"];
					currencies3[currencies[key]]["iso3"] = currencies2[currencies[key]]["iso3"];
				}
			}
			var optionsBalance = {};
			optionsBalance.Currency = this.get("currency2");
			this.get('api').getAccountBalance(optionsBalance).then(function (data) {

				if (Number(data.AvailableFunds) <= 0) {
					_this.set("max", 0);
					_this.set("amount", "");
				} else {
					_this.set("max", data.AvailableFunds);
					_this.set("amount", "");
				}
			});

			this.get('api').getCountries().then(function (data) {
				var canada = {
					"CountryID": "1",
					"Country": "Canada",
					"Abbreviation": "CA",
					"Airtime": "0",
					"MoneyTransfer": "0",
					"BillPayment": "0",
					"Voucher": "0",
					"DefaultCurrency": "CAD",
					"DeliveryMethods": {
						"0": "bank"
					}
				};
				data.push(canada);
				_this.set('countries', data);
				_this.filterCountryList(_this.get("currency2"));
			});

			var name = this.get("userInfo.FirstName") + " " + this.get("userInfo.LastName");
			this.set("userName", name);
			if (this.get("ClientAccountsEnabled")) {
				this.set("isNew", true);
				this.set("isStore", false);
			}
		},
		SetIq11URL: function SetIq11URL() {
			var _this2 = this;

			var options = null;
			var AccountSelectionMethod = "manual";

			if (location.hostname == "localhost") {
				options = {
					RedirectURL: "https://" + location.hostname + ":4200/",
					customerName: this.get("LongName"),
					AccountSelectionMethod: AccountSelectionMethod,
					ClientReferenceNumber: this.get("ClientReferenceNumber")
				};
			} else if (location.hostname == "bs-local.com") {

				options = {
					RedirectURL: "https://" + location.hostname + ":4200/",
					customerName: this.get("LongName"),
					AccountSelectionMethod: AccountSelectionMethod,

					ClientReferenceNumber: this.get("ClientReferenceNumber")
				};
			} else {
				options = {
					RedirectURL: "https://" + location.hostname + "/",
					customerName: this.get("LongName"),
					AccountSelectionMethod: AccountSelectionMethod,
					ClientReferenceNumber: this.get("ClientReferenceNumber")
				};
			}
			this.get('api').getApiURL(options).then(function (data) {
				_this2.set("FlinksURL", data.EmbedURL);
			});
		},
		GetDefaultBank: function GetDefaultBank(data) {
			var bankAccounts = data.BankAccounts;
			var keys = Object.keys(bankAccounts);
			var DefaultBank = null;
			keys.forEach(function (key, index) {
				var bank = bankAccounts[key];

				if (bank.IsDefault == 1) {

					DefaultBank = bank;
				}
			});
			return DefaultBank;
		},
		didInsertElement: function didInsertElement() {

			this._super.apply(this, arguments);
			_ember['default'].$("#paymentForm").parsley().destroy();
			_ember['default'].$('form').parsley({
				successClass: " ",
				errorClass: "form__input--error",
				errorsWrapper: "<p></p>",
				errorTemplate: "<span class='form__message'></span>"
			});
			_ember['default'].$('#month').keypress(function () {

				if (_ember['default'].$(this).val().length > 0) {
					_ember['default'].$('#year').focus();
				}
			});

			_ember['default'].$('#financialInstitutionNumber').focusout(function () {
				_ember['default'].$(".newPaymentMethod__institution").slideDown();
			});

			_ember['default'].$("#beneficiary-address").focusout(function () {
				setTimeout(function () {
					var address = _ember['default'].$("#beneficiary-address").val();
					var city = _ember['default'].$("#beneficiary-city").val();
					var state = _ember['default'].$("#beneficiary-state").val();
					var postcode = _ember['default'].$("#beneficiary-postcode").val();
					if (address) {
						_ember['default'].$("#beneficiary-address").parsley().reset();
					}
					if (city) {
						_ember['default'].$("#beneficiary-city").parsley().reset();
					}
					if (state) {
						_ember['default'].$("#beneficiary-state").parsley().reset();
					}
					if (postcode) {
						_ember['default'].$("#beneficiary-postcode").parsley().reset();
					}
				}, 500);
			});
			_ember['default'].$("#beneficiary-address").keyup(function () {
				if (!_ember['default'].$("#beneficiary-address").val()) {
					_ember['default'].$("#beneficiary-city").val("");
					_ember['default'].$("#beneficiary-postcode").val("");
					_ember['default'].$("#beneficiary-address").val("");
					_ember['default'].$("#beneficiary-state").val("");
				}
			});
			_ember['default'].$("#beneficiary-city").keyup(function () {
				if (!_ember['default'].$("#beneficiary-city").val()) {
					_ember['default'].$("#beneficiary-city").val("");
					_ember['default'].$("#beneficiary-postcode").val("");
					_ember['default'].$("#beneficiary-address").val("");
					_ember['default'].$("#beneficiary-state").val("");
				}
			});
			_ember['default'].$("#beneficiary-postcode").keyup(function () {
				if (!_ember['default'].$("#beneficiary-postcode").val()) {
					_ember['default'].$("#beneficiary-city").val("");
					_ember['default'].$("#beneficiary-postcode").val("");
					_ember['default'].$("#beneficiary-address").val("");
					_ember['default'].$("#beneficiary-state").val("");
				}
			});

			new ClipboardJS('.copy');
			var self = this;
			_ember['default'].$("#beneficiary-address").on('input', function () {

				_ember['default'].$("#beneficiary-address").val(self.get('entryUtil').noSpecialCharacter(_ember['default'].$("#beneficiary-address").val()));
			});
			_ember['default'].$("#beneficiary-city").on('input', function () {

				_ember['default'].$("#beneficiary-city").val(self.get('entryUtil').noSpecialCharacter(_ember['default'].$("#beneficiary-city").val()));
			});
			_ember['default'].$("#beneficiary-postcode").on('input', function () {

				_ember['default'].$("#beneficiary-postcode").val(self.get('entryUtil').onlyAlphaNumeric(_ember['default'].$("#beneficiary-postcode").val()));
			});
			window.addEventListener('message', function (e) {
				if (e.data.step === "ACCOUNT_SELECTED") {
					localStorage.setItem("accountId", e.data.accountId);
				}
				if (e.data.step === "REDIRECT") {
					localStorage.setItem("loginId", e.data.loginId);
					localStorage.setItem("institution", e.data.institution);
				}
				if (e.data.Step == "LINK") {
					localStorage.setItem("institution", e.data.Institution);
					localStorage.setItem("AccountNumber", e.data.AccountNumber);
					localStorage.setItem("BankId", e.data.BankId);
					localStorage.setItem("InstitutionNumber", e.data.InstitutionNumber);
				}
			});
		},
		MoveToOverview: function MoveToOverview(accountId, institution, loginId) {},
		initialiseAutoAddress: function initialiseAutoAddress() {
			//let countries = Constants.countries;
			/**
   let fields = [{
   	element: "beneficiary-address",
   	field: "Line1"
   }, {
   	element: "beneficiary-state",
   	field: "ProvinceCode",
   	mode: window.pca.fieldMode.POPULATE
   }, {
   	element: "beneficiary-city",
   	field: "City",
   	mode: window.pca.fieldMode.POPULATE
   }, {
   	element: "beneficiary-postcode",
   	field: "PostalCode",
   	mode: window.pca.fieldMode.POPULATE
   },
   { element: "Country", field: "CountryName", mode: window.pca.fieldMode.COUNTRY }],
   options = {
   	key: "fp61-gw79-xc88-xy19"
   },
     control = new window.pca.Address(fields, options); // jshint ignore:line
   * */
			window.initializeLocationIQ("beneficiary-address", "beneficiary-address2", "Country", "beneficiary-state", "beneficiary-city", "beneficiary-postcode", this.get("isoCountry"), "Address1");
		},
		resetForm: function resetForm(formId) {

			var form = _ember['default'].$(formId);

			form.parsley().reset();
		},
		validateForm: function validateForm(formId) {

			var form = _ember['default'].$(formId);

			form.parsley({
				excluded: 'input[type=button], input[type=submit], input[type=reset]',
				inputs: 'input, textarea, select, input[type=hidden], :hidden'
			}).validate();

			return form.parsley().isValid();
		},
		filterCountryList: function filterCountryList(currency) {
			//var filteredCountires = {};

			var ctr = this.get('countries').filter(function (f) {
				return f.DefaultCurrency.toUpperCase() === currency.toUpperCase();
			});

			if (localStorage.getItem("GCMEnabled") === "0") {
				ctr = [];
			}
			if (currency == "USD" && localStorage.getItem("USDEFTSendEnabled") === "1") {
				var obj = {
					Abbreviation: "CA",
					Airtime: "0",
					BillPayment: "0",
					Country: "Canada",
					CountryID: "1",
					DefaultCurrency: "CAD"
				};

				ctr.unshift(obj);
			}

			this.set("countries2", ctr);
		},
		filterList: function filterList(currency) {
			var filteredBeneficiaries = {};

			var type = "Bank Account";
			if (this.get("isCredit")) {
				type = "Credit Card";
			}
			if (this.get("isInteracEtransfer")) {
				type = "Interac E-Transfer";
			}
			if (this.get("isVisaDirect")) {
				type = "Visa Direct";
			}
			var data = this.get("beneficiaries");
			for (var key in data) {
				if (data[key].Currency === currency && data[key]["Type"] === type && localStorage.getItem("GCMEnabled") === "1" && currency != "CAD" && data[key].Country != "CA") {
					filteredBeneficiaries[key] = data[key];
				} else if (data[key].Currency === currency && data[key]["Type"] === type && localStorage.getItem("USDEFTSendEnabled") === "1" && currency == "USD" && data[key].Country == "CA") {
					filteredBeneficiaries[key] = data[key];
				} else if (data[key].Currency === currency && data[key]["Type"] === type && currency == "CAD") {
					if (type !== "Interac E-Transfer" || data[key].SecurityQuestion !== "" && data[key].SecurityResponse !== "") {

						filteredBeneficiaries[key] = data[key];
					}
				}
			}
			this.set("beneficiaries2", filteredBeneficiaries);
		},

		addPaymentMethod: function addPaymentMethod(formObj) {
			var arrLength = formObj.length;
			var payment = {};

			for (var i = 0; i < arrLength; i++) {
				payment[formObj[i].name] = formObj[i].value;
			}

			delete payment.InstitutionName;

			return payment;
		},
		getIsoCountry: function getIsoCountry(name) {
			var ctr = this.get('countries').filter(function (f) {
				return f.Country.toUpperCase() === name.toUpperCase();
			}).map(function (c) {
				return c.Abbreviation;
			});
			return ctr[0];
		},
		getCurrency: function getCurrency(name) {
			var ctr = this.get('countries').filter(function (f) {
				return f.Country.toUpperCase() === name.toUpperCase();
			}).map(function (c) {
				return c.DefaultCurrency;
			});
			return ctr[0];
		},
		getCountryID: function getCountryID(name) {
			var ctr = this.get('countries').filter(function (f) {
				return f.Country.toUpperCase() === name.toUpperCase();
			}).map(function (c) {
				return c.CountryID;
			});
			return ctr[0];
		},
		getCurrencyName: function getCurrencyName(name) {
			var ctr = this.get('currencies2');
			for (var key in ctr) {
				if (ctr[key].iso3 === name) {
					return ctr[key].name;
				}
			}
		},
		getCountryName: function getCountryName(name) {
			var ctr = this.get('countries').filter(function (f) {
				return f.DefaultCurrency.toUpperCase() === name.toUpperCase();
			}).map(function (c) {
				return c.Country;
			});
			if (ctr[0] == null) {
				return "Canada";
			} else {
				return ctr[0];
			}
		},
		checkIsAllowed: function checkIsAllowed() {

			if (localStorage.getItem("InteracBulkPayoutEnabled ") === "0" && this.get("isInteracEtransfer")) {
				this.set("isShowingModal", true);
				return false;
			} else if (localStorage.getItem("VisaDirectEnabled") === "0" && this.get("isVisaDirect")) {
				this.set("isShowingModal", true);
				return false;
			} else if (localStorage.getItem("PortalViewOnly") === "1") {

				this.set("isShowingModal", true);
				return false;
			} else if (localStorage.getItem("EFTCollectEnabled") === "0" && this.get("isDeposit") === "true" && !this.get("isInteracEtransfer") && !this.get("isVisaDirect")) {

				this.set("isShowingModal", true);
				return false;
			} else if (localStorage.getItem("EFTSendEnabled") === "0" && !this.get("isInteracEtransfer") && !this.get("isVisaDirect")) {

				this.set("isShowingModal", true);
				return false;
			} else if (this.get("isoCountry") !== "CA" && localStorage.getItem("GCMEnabled") === "0") {

				this.set("isShowingModal", true);
				return false;
			} else if (this.get("isoCountry") === "CA" && this.get("currency2") == "USD" && localStorage.getItem("USDEFTSendEnabled") === "0") {
				this.set("isShowingModal", true);
				return false;
			} else if (this.get("isCredit")) {
				this.set("isShowingModal", true);
				return false;
			} else {
				return true;
			}
		},
		errorMessage: function errorMessage(Message) {
			this.set("ErrorMessage", Message);
			this.set("isError", true);
		},
		sanitize: function sanitize() {
			var input = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];

			if (input != null) {
				return input.toLowerCase().replace(/\$/g, '').replace(/\,/g, '').replace(/\s|_/g, '', '');
			}
		},
		getClientAccountObject: function getClientAccountObject(value) {
			var clientAccounts = this.get("clientAccounts.ClientAccounts");
			var keys = Object.keys(clientAccounts);
			var ClientAccount = null;
			keys.forEach(function (key, index) {
				var account = clientAccounts[key];

				if (account.ClientAccountID == value) {

					ClientAccount = account;
				}
			});
			return ClientAccount;
		},
		createQueryParams: function createQueryParams(transaction) {
			var _this3 = this;

			var params = "";
			Object.values(transaction.Balances).map(function (_balance) {
				var balance = _extends({}, transaction, _balance);

				delete balance.Balances;

				balance.AccountBalance = _this3.sanitize(balance.AccountBalance) || 0;
				balance.PendingBalance = _this3.sanitize(balance.PendingBalance) || 0;
				balance.AvailableBalance = _this3.sanitize(balance.AvailableBalance) || 0;
				params = Object.entries(balance).reduce(_vopay_appUtilsQueryParamBuilder['default'], '');
				return balance;
			});

			return params;
		},
		actions: {
			ClientAccountChange: function ClientAccountChange(value) {
				if (value != "") {
					this.set("queryParams", this.createQueryParams(this.getClientAccountObject(value)));
					this.set("ClientAccountID", value);
				}
			},
			closeModal: function closeModal() {
				this.set("isShowingModal", false);
				return false;
			},
			openModal: function openModal() {
				this.set("isShowingModal", true);
				return false;
			},
			closeModalFlinks: function closeModalFlinks() {
				this.set("isFlinks", false);
				clearInterval(window.flinksInterval);
			},
			closeVisaDIrect: function closeVisaDIrect() {
				this.set("isVisaDirectCard", false);
				clearInterval(window.VisaDirectInterval);
			},
			closeModalError: function closeModalError() {
				this.set("isError", false);
			},
			afterSelectPurposes: function afterSelectPurposes(purpose) {
				_ember['default'].$("#purpose").parsley().reset();
				this.set('purpose', purpose);
			},
			recipientCountryChanged: function recipientCountryChanged(country) {
				//need to change
				this.set("showRecipient", false);
				this.set('country', country);
				this.set("isoCountry", this.getIsoCountry(country));
				this.set('countryID', this.getCountryID(country));
				var self = this;
				var myVar = setTimeout(function () {
					self.initialiseAutoAddress();
					clearTimeout(myVar);
				}, 1000);
			},
			showTerms: function showTerms() {
				_ember['default'].$("#termsConditions").show();
				_ember['default'].$("#overlay").show();
				this.set("isAgree", false);
				_ember['default'].$("#isAgree").parsley().reset();
			},
			backTerms: function backTerms() {
				_ember['default'].$("#termsConditions").hide();
				_ember['default'].$("#overlay").hide();
				this.set("isAgree", false);
				_ember['default'].$("#isAgree").parsley().reset();
			},
			AgreeToTerms: function AgreeToTerms() {
				_ember['default'].$("#overlay").hide();
				_ember['default'].$("#termsConditions").hide();
				this.set("agreedToTerms", true);
				this.set("isAgree", true);
				_ember['default'].$("#isAgree").parsley().reset();
			},
			recipientCurrencyChanged: function recipientCurrencyChanged(currency) {
				var _this4 = this;

				this.set("currency2", currency);
				var optionsBalance = {};
				optionsBalance.Currency = currency;
				var country = this.getCountryName(currency);
				this.set('country', country);
				this.set("isoCountry", this.getIsoCountry(country));
				this.set('countryID', this.getCountryID(country));

				if (currency == "USD" && localStorage.getItem("USDEFTSendEnabled") === "1") {
					this.set('country', "Canada");
					this.set("isoCountry", this.getIsoCountry("Canada"));
				}

				this.set("isInteracEtransfer", false);
				this.set("isCredit", true);
				this.set("isCredit", false);
				this.set("isRequest", false);
				this.filterList(currency);
				this.filterCountryList(currency);
				this.get('api').getAccountBalance(optionsBalance).then(function (data) {
					if (Number(data.AvailableFunds) <= 0) {
						_this4.set("max", 0);
						_this4.set("amount", "");
					} else {
						_this4.set("max", data.AvailableFunds);
						_this4.set("amount", "");
					}
				});
				this.set("currencyName", this.getCurrencyName(currency));
			},
			addSignature: function addSignature() {
				this.set("signatureCurrNum", this.get("signatureCurrNum") + 1);
				this.get('signatureNumbers').pushObject(this.get("signatureCurrNum"));
			},
			removeSignature: function removeSignature() {
				this.set('signatureNumbers', this.get('signatureNumbers').slice(0, -1));
				this.set("signatureCurrNum", this.get("signatureCurrNum") - 1);
			},
			selectBeneficiary: function selectBeneficiary(value) {
				if (value === "New") {
					this.set("isNew", true);
					var _self = this;
				} else {
					this.set("isNew", false);
					var data = this.get("beneficiaries");
					var paymentToAdd = data[value];
					if (paymentToAdd) {
						this.set("isoCountry", paymentToAdd.Country || '');

						if (paymentToAdd.AccountHolderName) {
							var name = paymentToAdd.AccountHolderName;
							var nameParts = name.split(" ");

							if (nameParts.length > 2 || nameParts.length < 2) {
								paymentToAdd["CompanyName"] = name;
							} else {
								paymentToAdd["FirstName"] = nameParts[0];
								paymentToAdd["LastName"] = nameParts[1];
							}
						}

						this.set("paymentToAdd", paymentToAdd);
					}
				}
			},
			enterCreditCard: function enterCreditCard() {
				var _this5 = this;

				$("#amount").parsley().validate();
				if (this.checkIsAllowed() && Number(this.get("amount").replace(/,/g, '')) <= Number(this.get("max")) && _ember['default'].$("#amount").parsley().isValid({ force: true })) {
					var paymentFormArr = _ember['default'].$('#paymentForm').serializeArray();
					this.set('paymentToAdd', this.addPaymentMethod(paymentFormArr));
					this.set('paymentToAdd.DollarAmount', this.get("amount").replace(/,/g, '').replace(/[\u00a0]/g, "").replace(/[\u202f]/g, ""));
					if (this.get("country") === "Canada") {
						this.set("paymentToAdd.Country", "CA");
					} else {
						this.set("paymentToAdd.Country", this.get("isoCountry"));
					}
					var RedirectURL = "";
					if (location.hostname == "localhost") {

						RedirectURL = "https://" + location.hostname + ":4200/";
					} else if (location.hostname == "bs-local.com") {

						options = {
							RedirectURL: "https://" + location.hostname + ":4200/",
							customerName: "Vopay"
						};
					} else {

						RedirectURL = "https://" + location.hostname + "/";
					}
					this.set('paymentToAdd.RedirectURL', RedirectURL);
					var options = this.get("paymentToAdd");
					var self = this;
					this.get("api").VisaDirectURL(options).then(function (data) {
						_this5.set("VisaDirectURL", data.EmbedURL);
						_this5.set("isVisaDirectCard", true);

						window.VisaDirectInterval = setInterval(function () {

							if (localStorage.getItem("FlinksToken") != null) {
								var _paymentFormArr = _ember['default'].$('#paymentForm').serializeArray();
								self.set('paymentToAdd', self.addPaymentMethod(_paymentFormArr));
								self.set('paymentToAdd.DollarAmount', self.get("amount").replace(/,/g, ''));
								self.set("paymentToAdd.Currency", self.get("currency2"));
								self.set("paymentToAdd.Country", "CA");
								var Options = {
									Token: localStorage.getItem("FlinksToken")
								};
								self.get("api").GetCreditCardIfo(Options).then(function (data) {
									self.set("paymentToAdd.FullName", data.Name);
									self.set("paymentToAdd.CardNumber", data.LastFourDigits);
									_ember['default'].$("#paymentInfo").hide();
									_ember['default'].$("#benifeciaryInfo").hide();
									_ember['default'].$("#bankInfo").hide();
									_ember['default'].$("#Overview").show();
									_ember['default'].$("#etransfer").hide();
									self.set("isVisaDirectCard", false);
								}, function (error) {});
								self.set("paymentToAdd.Token", localStorage.getItem("FlinksToken"));
								localStorage.removeItem("TokenLocation");
								localStorage.removeItem("FlinksToken");
								localStorage.removeItem("MaskedAccount");
								localStorage.removeItem("FullName");
								localStorage.removeItem("accountId");
								localStorage.removeItem("loginId");
								localStorage.removeItem("institution");
								clearInterval(window.VisaDirectInterval);
							}
						}, 100);
					}, function (error) {
						_this5.errorMessage(error);
					});
				}
			},
			moveToBank: function moveToBank() {
				var _this6 = this;

				if ((this.validateForm('#paymentForm') || this.get("isFlinks2")) && this.checkIsAllowed()) {
					(function () {
						_this6.SetIq11URL();
						_this6.set("isFlinks", true);
						var self = _this6;
						window.flinksInterval = setInterval(function () {

							if (localStorage.getItem("FlinksToken") != null) {
								var paymentFormArr = _ember['default'].$('#paymentForm').serializeArray();
								self.set('paymentToAdd', self.addPaymentMethod(paymentFormArr));
								self.set('paymentToAdd.DollarAmount', self.get("amount").replace(/,/g, ''));
								if (localStorage.getItem("institution") && localStorage.getItem("institution") != "undefined") {
									self.set('paymentToAdd.InstitutionName', localStorage.getItem("institution"));
									self.set("paymentToAdd.FinancialInstitutionNumber", "");
								} else {
									var finNumber = localStorage.getItem("InstitutionNumber");
									var bank = _vopay_appConstants['default'].canadaBanks.filter(function (b) {
										return parseInt(b.code) === parseInt(finNumber);
									});

									if (bank[0]) {
										self.set('paymentToAdd.InstitutionName', bank[0].name);
									} else {
										self.set('paymentToAdd.InstitutionName', "");
									}
									self.set("paymentToAdd.FinancialInstitutionNumber", finNumber);
								}
								if (self.get("country") === "Canada") {
									self.set("paymentToAdd.Country", "CA");
								} else {
									self.set("paymentToAdd.Country", self.get("isoCountry"));
								}
								if (self.get("paymentToAdd.Iban")) {
									self.set("paymentToAdd.Iban", self.get("isoCountry") + "" + self.get("paymentToAdd.Iban"));
								}

								self.set("paymentToAdd.Currency", self.get("currency2"));
								self.set("paymentToAdd.Notes", _ember['default'].$("#Notes").val());
								self.set("paymentToAdd.TransactionLabel", _ember['default'].$("#TransactionLabel").val());
								_ember['default'].$("#paymentInfo").hide();
								_ember['default'].$("#benifeciaryInfo").hide();
								_ember['default'].$("#bankInfo").hide();
								_ember['default'].$("#Overview").show();
								self.set("isOverview", true);
								_ember['default'].$("#etransfer").hide();
								self.set("isFlinks2", true);
								self.set("isFlinks", false);
								self.set("paymentToAdd.Token", localStorage.getItem("FlinksToken"));
								if (localStorage.getItem("AccountNumber")) {
									self.set('paymentToAdd.MaskedAccount', localStorage.getItem("AccountNumber"));
								} else {
									self.set("paymentToAdd.MaskedAccount", localStorage.getItem("MaskedAccount"));
								}
								if (localStorage.getItem("BankId")) {
									self.set('paymentToAdd.BankId', localStorage.getItem("BankId"));
								}
								localStorage.removeItem("BankId");
								localStorage.removeItem("InstitutionNumber");
								self.set("paymentToAdd.BranchTransitNumber", "");
								self.set("paymentToAdd.AccountNumber", "");
								self.set("paymentToAdd.FullName", localStorage.getItem("FullName"));
								localStorage.removeItem("TokenLocation");
								localStorage.removeItem("FlinksToken");
								localStorage.removeItem("MaskedAccount");
								localStorage.removeItem("FullName");
								localStorage.removeItem("accountId");
								localStorage.removeItem("loginId");
								localStorage.removeItem("institution");
								localStorage.removeItem("AccountNumber");
								clearInterval(window.flinksInterval);
							}
						}, 100);
						/**
       		this.resetForm('#paymentForm');
       		this.set("isBank", true);
      Ember.$("#Overview").hide();
      this.set("isOverview", false);
        	Ember.$("#paymentInfo").hide();
        	Ember.$("#benifeciaryInfo").hide();
        	Ember.$("#bankInfo").show();
        	Ember.$("#accountInfo").hide();
        	this.get("isFlinks2", false);
        	this.set("stageNumber", 3);
        	this.set("stageName", "Bank Details");
        	localStorage.removeItem("accountId");
            localStorage.removeItem("institution");
            localStorage.removeItem("loginId");* */
					})();
				}
			},
			moveToPayment: function moveToPayment() {
				_ember['default'].$("#Overview").hide();
				this.set("isOverview", false);
				_ember['default'].$("#benifeciaryInfo").hide();
				_ember['default'].$("#paymentInfo").show();
				_ember['default'].$("#bankInfo").hide();
				_ember['default'].$("#accountInfo").hide();
				_ember['default'].$("#etransfer").hide();
				this.set("isBank", false);
				this.set("stageNumber", 1);
				this.set("stageName", "Payment Details");
				this.set("isRequestInfo", false);
			},
			moveToAccount: function moveToAccount() {

				$("#amount").parsley().validate();

				if (this.checkIsAllowed() && $("#amount").parsley().isValid({ force: true })) {
					if (Number(this.get("amount").replace(/,/g, '')) <= Number(this.get("max"))) {
						_ember['default'].$("#Overview").hide();
						this.set("isOverview", false);
						_ember['default'].$("#benifeciaryInfo").hide();
						_ember['default'].$("#accountInfo").show();
						_ember['default'].$("#paymentInfo").hide();
						_ember['default'].$("#bankInfo").hide();
						_ember['default'].$("#etransfer").hide();
						this.set("isBank", false);
						this.set("stageNumber", 2);
						this.set("stageName", "Account Details");
						localStorage.removeItem("accountId");
						localStorage.removeItem("institution");
						localStorage.removeItem("loginId");
					}
				}
			},
			moveToRequestInfo: function moveToRequestInfo() {
				var _this7 = this;

				if (this.get("PaylinkSettings.PaylinkSettings.PayLinkSenderEmailAddress")) {

					_ember['default'].$("#paymentForm").parsley().destroy();
					_ember['default'].$('form').parsley({
						successClass: " ",
						errorClass: "form__input--error",
						errorsWrapper: "<p></p>",
						errorTemplate: "<span class='form__message'></span>"
					});
					_ember['default'].$("#amount").parsley().validate();
					if (Number(this.get("amount").replace(/,/g, '')) <= Number(this.get("max")) && _ember['default'].$("#amount").parsley().isValid({ force: true })) {
						var myVar;

						(function () {
							_this7.set("isRequestInfo", true);
							_ember['default'].$("#Overview").hide();
							_this7.set("isOverview", false);
							_ember['default'].$("#paymentInfo").hide();
							_ember['default'].$("#benifeciaryInfo").show();
							_ember['default'].$("#bankInfo").hide();
							_ember['default'].$("#accountInfo").hide();
							_ember['default'].$("#etransfer").hide();
							_this7.set("isBank", false);
							_this7.set("stageNumber", 2);
							_this7.set("stageName", "Account Holder Details");
							localStorage.removeItem("accountId");
							localStorage.removeItem("institution");
							localStorage.removeItem("loginId");
							var self = _this7;
							myVar = setTimeout(function () {
								self.initialiseAutoAddress();

								clearTimeout(myVar);
								_ember['default'].$("#beneficiary-address").focusout(function () {

									setTimeout(function () {
										var address = _ember['default'].$("#beneficiary-address").val();
										var city = _ember['default'].$("#beneficiary-city").val();
										var state = _ember['default'].$("#beneficiary-state").val();
										var postcode = _ember['default'].$("#beneficiary-postcode").val();

										if (address) {
											_ember['default'].$("#beneficiary-address").parsley().reset();
										}
										if (city) {
											_ember['default'].$("#beneficiary-city").parsley().reset();
										}
										if (state) {
											_ember['default'].$("#beneficiary-state").parsley().reset();
										}
										if (postcode) {
											_ember['default'].$("#beneficiary-postcode").parsley().reset();
										}
									}, 500);
								});
								_ember['default'].$("#beneficiary-address").keyup(function () {
									if (!_ember['default'].$("#beneficiary-address").val()) {
										_ember['default'].$("#beneficiary-city").val("");
										_ember['default'].$("#beneficiary-postcode").val("");
										_ember['default'].$("#beneficiary-address").val("");
										_ember['default'].$("#beneficiary-state").val("");
									}
								});
								_ember['default'].$("#beneficiary-city").keyup(function () {
									if (!_ember['default'].$("#beneficiary-city").val()) {
										_ember['default'].$("#beneficiary-city").val("");
										_ember['default'].$("#beneficiary-postcode").val("");
										_ember['default'].$("#beneficiary-address").val("");
										_ember['default'].$("#beneficiary-state").val("");
									}
								});
								_ember['default'].$("#beneficiary-postcode").keyup(function () {
									if (!_ember['default'].$("#beneficiary-postcode").val()) {
										_ember['default'].$("#beneficiary-city").val("");
										_ember['default'].$("#beneficiary-postcode").val("");
										_ember['default'].$("#beneficiary-address").val("");
										_ember['default'].$("#beneficiary-state").val("");
									}
								});
								_ember['default'].$("#beneficiary-address").on('input', function () {

									_ember['default'].$("#beneficiary-address").val(self.get('entryUtil').noSpecialCharacter(_ember['default'].$("#beneficiary-address").val()));
								});
								_ember['default'].$("#beneficiary-city").on('input', function () {

									_ember['default'].$("#beneficiary-city").val(self.get('entryUtil').noSpecialCharacter(_ember['default'].$("#beneficiary-city").val()));
								});
								_ember['default'].$("#beneficiary-postcode").on('input', function () {

									_ember['default'].$("#beneficiary-postcode").val(self.get('entryUtil').onlyAlphaNumeric(_ember['default'].$("#beneficiary-postcode").val()));
								});
							}, 500);
						})();
					}
				} else {
					this.errorMessage("Sender email is not setup. Please add a sender email via the Paylink settings");
				}
			},

			moveToBenifeciary: function moveToBenifeciary() {
				var _this8 = this;

				_ember['default'].$("#paymentForm").parsley().destroy();
				_ember['default'].$('form').parsley({
					successClass: " ",
					errorClass: "form__input--error",
					errorsWrapper: "<p></p>",
					errorTemplate: "<span class='form__message'></span>"
				});
				_ember['default'].$("#amount").parsley().validate();

				if (Number(this.get("amount").replace(/,/g, '')) <= Number(this.get("max")) && _ember['default'].$("#amount").parsley().isValid({ force: true })) {
					var myVar;

					(function () {
						_ember['default'].$("#Overview").hide();
						_this8.set("isOverview", false);
						_this8.get("isFlinks2", false);
						_ember['default'].$("#paymentInfo").hide();
						_ember['default'].$("#benifeciaryInfo").show();
						_ember['default'].$("#bankInfo").hide();
						_ember['default'].$("#accountInfo").hide();
						_ember['default'].$("#etransfer").hide();
						_this8.set("isBank", false);
						_this8.set("stageNumber", 2);
						_this8.set("stageName", "Account Holder Details");
						localStorage.removeItem("accountId");
						localStorage.removeItem("institution");
						localStorage.removeItem("loginId");
						var self = _this8;
						myVar = setTimeout(function () {
							self.initialiseAutoAddress();

							clearTimeout(myVar);
							_ember['default'].$("#beneficiary-address").focusout(function () {

								setTimeout(function () {
									var address = _ember['default'].$("#beneficiary-address").val();
									var city = _ember['default'].$("#beneficiary-city").val();
									var state = _ember['default'].$("#beneficiary-state").val();
									var postcode = _ember['default'].$("#beneficiary-postcode").val();

									if (address) {
										_ember['default'].$("#beneficiary-address").parsley().reset();
									}
									if (city) {
										_ember['default'].$("#beneficiary-city").parsley().reset();
									}
									if (state) {
										_ember['default'].$("#beneficiary-state").parsley().reset();
									}
									if (postcode) {
										_ember['default'].$("#beneficiary-postcode").parsley().reset();
									}
								}, 500);
							});
							_ember['default'].$("#beneficiary-address").keyup(function () {
								if (!_ember['default'].$("#beneficiary-address").val()) {
									_ember['default'].$("#beneficiary-city").val("");
									_ember['default'].$("#beneficiary-postcode").val("");
									_ember['default'].$("#beneficiary-address").val("");
									_ember['default'].$("#beneficiary-state").val("");
								}
							});
							_ember['default'].$("#beneficiary-city").keyup(function () {
								if (!_ember['default'].$("#beneficiary-city").val()) {
									_ember['default'].$("#beneficiary-city").val("");
									_ember['default'].$("#beneficiary-postcode").val("");
									_ember['default'].$("#beneficiary-address").val("");
									_ember['default'].$("#beneficiary-state").val("");
								}
							});
							_ember['default'].$("#beneficiary-postcode").keyup(function () {
								if (!_ember['default'].$("#beneficiary-postcode").val()) {
									_ember['default'].$("#beneficiary-city").val("");
									_ember['default'].$("#beneficiary-postcode").val("");
									_ember['default'].$("#beneficiary-address").val("");
									_ember['default'].$("#beneficiary-state").val("");
								}
							});
							_ember['default'].$("#beneficiary-address").on('input', function () {

								_ember['default'].$("#beneficiary-address").val(self.get('entryUtil').noSpecialCharacter(_ember['default'].$("#beneficiary-address").val()));
							});
							_ember['default'].$("#beneficiary-city").on('input', function () {

								_ember['default'].$("#beneficiary-city").val(self.get('entryUtil').noSpecialCharacter(_ember['default'].$("#beneficiary-city").val()));
							});
							_ember['default'].$("#beneficiary-postcode").on('input', function () {

								_ember['default'].$("#beneficiary-postcode").val(self.get('entryUtil').onlyAlphaNumeric(_ember['default'].$("#beneficiary-postcode").val()));
							});
						}, 500);
					})();
				}
			},
			moveToEtransfer: function moveToEtransfer() {

				if (this.checkIsAllowed() && $("#amount").parsley().isValid({ force: true })) {
					_ember['default'].$("#Overview").hide();
					this.set("isOverview", false);
					_ember['default'].$("#paymentInfo").hide();
					_ember['default'].$("#benifeciaryInfo").hide();
					_ember['default'].$("#etransfer").show();
					this.set("stageNumber", 2);
					this.set("stageName", "Account Details");
				}
			},
			handleAccountOptions: function handleAccountOptions(value) {
				if (value === "Withdraw") {
					this.set("isDeposit", "false");
					while (this.get("signatureCurrNum") > 0) {
						this.set('signatureNumbers', this.get('signatureNumbers').slice(0, -1));
						this.set("signatureCurrNum", this.get("signatureCurrNum") - 1);
					}
					this.set("signatureCurrNum", this.get("signatureCurrNum") + 1);
					this.get('signatureNumbers').pushObject(this.get("signatureCurrNum"));
					this.set('signatureNumDel', 1);
				} else if (value === "Both") {
					this.set("isDeposit", true);
					this.set('signatureNumDel', 0);
					while (this.get("signatureCurrNum") > 0) {
						this.set('signatureNumbers', this.get('signatureNumbers').slice(0, -1));
						this.set("signatureCurrNum", this.get("signatureCurrNum") - 1);
					}
					this.set('signatureNumDel', 1);
					this.set("signatureCurrNum", this.get("signatureCurrNum") + 1);
					this.get('signatureNumbers').pushObject(this.get("signatureCurrNum"));
				} else {
					this.set("isDeposit", "true");
				}
			},
			handleIfFlinks: function handleIfFlinks(value) {
				var _this9 = this;

				if (value === "Online Banking") {
					(function () {
						_this9.SetIq11URL();
						_this9.set("isFlinks", true);
						var self = _this9;
						window.flinksInterval = setInterval(function () {

							if (localStorage.getItem("FlinksToken") != null) {
								var paymentFormArr = _ember['default'].$('#paymentForm').serializeArray();
								self.set('paymentToAdd', self.addPaymentMethod(paymentFormArr));
								self.set('paymentToAdd.DollarAmount', self.get("amount").replace(/,/g, ''));
								if (localStorage.getItem("institution") && localStorage.getItem("institution") != "undefined") {
									self.set('paymentToAdd.InstitutionName', localStorage.getItem("institution"));
									self.set("paymentToAdd.FinancialInstitutionNumber", "");
								} else {
									var finNumber = localStorage.getItem("InstitutionNumber");
									var bank = _vopay_appConstants['default'].canadaBanks.filter(function (b) {
										return parseInt(b.code) === parseInt(finNumber);
									});

									if (bank[0]) {
										self.set('paymentToAdd.InstitutionName', bank[0].name);
									} else {
										self.set('paymentToAdd.InstitutionName', "");
									}
									self.set("paymentToAdd.FinancialInstitutionNumber", finNumber);
								}
								if (self.get("country") === "Canada") {
									self.set("paymentToAdd.Country", "CA");
								} else {
									self.set("paymentToAdd.Country", self.get("isoCountry"));
								}
								if (self.get("paymentToAdd.Iban")) {
									self.set("paymentToAdd.Iban", self.get("isoCountry") + "" + self.get("paymentToAdd.Iban"));
								}

								self.set("paymentToAdd.Currency", self.get("currency2"));
								self.set("paymentToAdd.Notes", _ember['default'].$("#Notes").val());
								self.set("paymentToAdd.TransactionLabel", _ember['default'].$("#TransactionLabel").val());
								_ember['default'].$("#paymentInfo").hide();
								_ember['default'].$("#benifeciaryInfo").hide();
								_ember['default'].$("#bankInfo").hide();
								_ember['default'].$("#Overview").show();
								self.set("isOverview", true);
								_ember['default'].$("#etransfer").hide();
								self.set("isFlinks2", true);
								self.set("isFlinks", false);
								self.set("paymentToAdd.Token", localStorage.getItem("FlinksToken"));
								if (localStorage.getItem("AccountNumber")) {
									self.set('paymentToAdd.MaskedAccount', localStorage.getItem("AccountNumber"));
								} else {
									self.set("paymentToAdd.MaskedAccount", localStorage.getItem("MaskedAccount"));
								}
								if (localStorage.getItem("BankId")) {
									self.set('paymentToAdd.BankId', localStorage.getItem("BankId"));
								}
								localStorage.removeItem("BankId");
								localStorage.removeItem("InstitutionNumber");
								self.set("paymentToAdd.BranchTransitNumber", "");
								self.set("paymentToAdd.AccountNumber", "");
								self.set("paymentToAdd.FullName", localStorage.getItem("FullName"));
								localStorage.removeItem("TokenLocation");
								localStorage.removeItem("FlinksToken");
								localStorage.removeItem("MaskedAccount");
								localStorage.removeItem("FullName");
								localStorage.removeItem("accountId");
								localStorage.removeItem("loginId");
								localStorage.removeItem("institution");
								localStorage.removeItem("AccountNumber");
								clearInterval(window.flinksInterval);
							}
						}, 100);
					})();
				} else {
					this.set("isFlinks", false);
					clearInterval(window.flinksInterval);
				}
			},
			handleAccountType: function handleAccountType(value) {
				if (value === "Business") {
					this.set("isBusiness", true);
				} else {
					this.set("isBusiness", false);
				}
			},
			updateInstitutionName: function updateInstitutionName() {

				var finNumber = this.get('financialInstitutionNumber');
				this.set('financialInstitutionNumber', (0, _vopay_appHelpersCompleteWithZeros.completeWithZeros)(["l", finNumber, 3]));

				var bank = _vopay_appConstants['default'].canadaBanks.filter(function (b) {
					return parseInt(b.code) === parseInt(finNumber);
				});

				var eClass = _ember['default'].$(".form__bankFlagIcon").attr("class").match(/b[0-9]{3}/);

				if (eClass) {
					_ember['default'].$(".form__bankFlagIcon").removeClass('' + eClass[0]);
				}

				if (bank[0]) {

					_ember['default'].$(".form__bankFlagIcon").addClass('b' + (0, _vopay_appHelpersCompleteWithZeros.completeWithZeros)(["l", finNumber, 3]));
					this.set('institutionName', bank[0].name);
					_ember['default'].$("#institutionName").parsley().reset();
					_ember['default'].$("#financialInstitutionNumber").parsley().reset();
					_ember['default'].$(".newPaymentMethod__institution").slideDown();
				} else {
					_ember['default'].$("#paymentForm").parsley().destroy();
					_ember['default'].$('form').parsley({
						successClass: " ",
						errorClass: "form__input--error",
						errorsWrapper: "<p></p>",
						errorTemplate: "<span class='form__message'></span>"
					});
					_ember['default'].$(".newPaymentMethod__institution").slideUp();
					_ember['default'].$(".form__bankFlagIcon").addClass('b0');
					this.set('financialInstitutionNumber', '');
					this.set('institutionName', '');
					_ember['default'].$("#financialInstitutionNumber").val('');
					if (!finNumber) {
						_ember['default'].$("#financialInstitutionNumber").parsley().reset();
					} else {
						_ember['default'].$("#financialInstitutionNumber").parsley().validate();
					}
					// Ember.$("#financialInstitutionNumber").focus();
				}
			},
			cancel: function cancel() {
				if (sessionStorage.getItem("returnUrl")) {
					var url = sessionStorage.getItem("returnUrl");
					sessionStorage.removeItem("returnUrl");
					window.location.href = url;
				} else {
					this.get("routing").transitionTo("dashboard.info");
				}
			},
			closeDefaultBank: function closeDefaultBank() {
				this.set("isShowingDefaultBank", false);
			},
			moveToOverview: function moveToOverview() {
				var _this10 = this;

				if (this.checkIsAllowed()) {
					if (this.get("ClientAccountsEnabled")) {
						if (this.validateForm('#paymentForm') && Number(this.get("amount").replace(/,/g, '')) <= Number(this.get("max"))) {
							var paymentFormArr = _ember['default'].$('#paymentForm').serializeArray();
							this.set('paymentToAdd', this.addPaymentMethod(paymentFormArr));
							this.set('paymentToAdd.DollarAmount', this.get("amount").replace(/,/g, '').replace(/[\u00a0]/g, "").replace(/[\u202f]/g, ""));
							if (this.get("country") === "Canada") {
								this.set("paymentToAdd.Country", "CA");
							} else {
								this.set("paymentToAdd.Country", this.get("isoCountry"));
							}
							this.set("paymentToAdd.Currency", this.get("currency2"));
							//var newBeneficiary= this.get('store').createRecord('beneficiary', this.get("paymentToAdd"));
							//newBeneficiary.save();
							//add enpoint to get message of deafault bank account

							var options = {
								ClientAccountID: this.get("paymentToAdd.ClientAccountID")
							};
							this.set("paymentToAdd.Notes", _ember['default'].$("#Notes").val());
							this.get("api").getBankAccount(options).then(function (data) {
								var DefaultBank = _this10.GetDefaultBank(data);

								if (DefaultBank != null) {
									_this10.set('paymentToAdd.FinancialInstitutionNumberDisplay', DefaultBank.FinancialInstitutionNumber);
									_this10.set('paymentToAdd.BranchTransitNumberDisplay', DefaultBank.BranchTransitNumber);
									_this10.set('paymentToAdd.MaskedAccount', DefaultBank.AccountNumber);
									_ember['default'].$("#paymentInfo").hide();
									_ember['default'].$("#benifeciaryInfo").hide();
									_ember['default'].$("#bankInfo").hide();
									_ember['default'].$("#Overview").show();
									_ember['default'].$("#etransfer").hide();
								} else {
									_this10.set("isShowingDefaultBank", true);
									//error with link to add bank account
								}
							}, function (error) {
								_this10.errorMessage(error);
							});
							this.set("isOverview", true);
						}
					} else if (this.get("isNew")) {
						if (this.validateForm('#paymentForm') && Number(this.get("amount").replace(/,/g, '')) <= Number(this.get("max"))) {
							var paymentFormArr = _ember['default'].$('#paymentForm').serializeArray();
							this.set('paymentToAdd', this.addPaymentMethod(paymentFormArr));
							this.set('paymentToAdd.DollarAmount', this.get("amount").replace(/,/g, '').replace(/[\u00a0]/g, "").replace(/[\u202f]/g, ""));
							if (this.get("country") === "Canada") {
								this.set("paymentToAdd.Country", "CA");
							} else {
								this.set("paymentToAdd.Country", this.get("isoCountry"));
							}
							if (this.get("paymentToAdd.Iban")) {
								this.set("paymentToAdd.Iban", this.get("isoCountry") + "" + this.get("paymentToAdd.Iban"));
							}
							this.set("paymentToAdd.TransactionLabel", _ember['default'].$("#TransactionLabel").val());
							this.set("paymentToAdd.Currency", this.get("currency2"));
							//var newBeneficiary= this.get('store').createRecord('beneficiary', this.get("paymentToAdd"));
							//newBeneficiary.save();
							this.set("paymentToAdd.Notes", _ember['default'].$("#Notes").val());

							_ember['default'].$("#paymentInfo").hide();
							_ember['default'].$("#benifeciaryInfo").hide();
							_ember['default'].$("#bankInfo").hide();
							_ember['default'].$("#Overview").show();
							_ember['default'].$("#etransfer").hide();
							this.set("isOverview", true);
						}
					} else {
						if (this.validateForm('#paymentForm') && Number(this.get("amount").replace(/,/g, '')) <= Number(this.get("max"))) {
							this.set('paymentToAdd.DollarAmount', this.get("amount").replace(/,/g, '').replace(/[\u00a0]/g, "").replace(/[\u202f]/g, ""));
							this.set("paymentToAdd.Notes", _ember['default'].$("#Notes").val());
							this.set("paymentToAdd.TransactionLabel", _ember['default'].$("#TransactionLabel").val());
							this.set("paymentToAdd.MessageForRecipient", _ember['default'].$("input[name=MessageForRecipient]").val());
							var paymentFormArr = _ember['default'].$('#paymentForm').serializeArray();
							var addPaymentMethod = this.addPaymentMethod(paymentFormArr);
							this.set('paymentToAdd.ClientAccountID', addPaymentMethod.ClientAccountID);
							this.set('paymentToAdd.ClientAccountName', addPaymentMethod.ClientAccountName);

							_ember['default'].$("#paymentInfo").hide();
							_ember['default'].$("#benifeciaryInfo").hide();
							_ember['default'].$("#bankInfo").hide();
							_ember['default'].$("#Overview").show();
							_ember['default'].$("#etransfer").hide();
							this.set("isOverview", true);
						}
					}
				}
			},
			emailRequst: function emailRequst() {
				var _this11 = this;

				var options = {
					PayLinkID: this.get("PayLinkID")
				};
				this.get("api").RequestAccountEmail(options).then(function (data) {}, function (error) {
					_this11.errorMessage(error);
				});
			},
			MoveCreateNew: function MoveCreateNew() {
				window.location.reload();
			},
			LocationMove: function LocationMove() {
				localStorage.setItem("link", window.location.href);
				window.location.href = this.get("transLocation");
			},
			CopyLink: function CopyLink() {
				/* Get the text field */
				var copyText = document.getElementById("link");

				/* Select the text field */
				copyText.select();
				copyText.setSelectionRange(0, 99999); /*For mobile devices*/

				/* Copy the text inside the text field */
				document.execCommand("copy");
			},
			handlePaymentType: function handlePaymentType(value) {
				if (value === "Interac E-Transfer") {
					this.set("isInteracEtransfer", true);
				} else {
					this.set("isInteracEtransfer", false);
				}
				if (value === "Credit Card") {
					this.set("isShowingModal", true);
					this.set("isCredit", false);
				} else {
					this.set("isCredit", false);
				}
				if (value === "Visa Direct") {
					this.set("isVisaDirect", true);
				} else {
					this.set("isVisaDirect", false);
				}
				if (this.get("country") === "Canada") {
					this.filterList("CAD", "CA");
				} else {
					this.filterList(this.get("currency2"), this.get("country"));
				}
				this.set("currentSelection", "");
			},
			create: function create() {
				var _this12 = this;

				localStorage.removeItem("accountId");
				localStorage.removeItem("institution");
				localStorage.removeItem("loginId");

				if (this.validateForm('#paymentForm') || this.get("isFlinks2") || this.get("isVisaDirect")) {
					// Add payment

					_ember['default'].$('.complete').prop('disabled', true);
					if (this.get("isDeposit") === "true") {
						if (this.get("isRequestInfo")) {
							this.set("isStore", false);
							this.set("paymentToAdd.DelayBankingInfo", true);
						}
						this.get("api").fundTransaction(this.get("paymentToAdd")).then(function (transaction) {

							if (_this12.get("isStore")) {
								var data = _this12.get("paymentToAdd");
								delete data.DollarAmount;
								delete data.ClientAccountID;
								delete data.ClientAccountName;

								if (_this12.get("isCredit")) {
									data.Type = "Credit Card";
								} else if (_this12.get("isInteracEtransfer")) {
									data.Type = "Interac E-Transfer";
								} else {
									data.Type = "Bank Account";
								}
								var id = _this12.get("firebase").createRecordUser("accounts", data);
								var input = document.getElementById('uploadFile');
								for (var i = 0; i < input.files.length; i++) {
									var file = input.files[i];
									_this12.get("firebase").addFileUser("accounts", id, file);
								}
							}
							localStorage.setItem("link", window.location.href);
							window.location.href = "#/dashboard/transaction/Fund/" + transaction.TransactionID + "/" + _this12.get("paymentToAdd.Currency");
						}, function (error) {
							_this12.errorMessage(error);
							_ember['default'].$('.complete').removeAttr('disabled');
						});
					} else {
						if (this.get("isInteracEtransfer")) {
							this.get("api").BulkPayout(this.get("paymentToAdd")).then(function (transaction) {

								if (_this12.get("isStore")) {
									var data = _this12.get("paymentToAdd");
									if (_this12.get("isCredit")) {
										data.Type = "Credit Card";
									} else if (_this12.get("isInteracEtransfer")) {
										data.Type = "Interac E-Transfer";
									} else {
										data.Type = "Bank Account";
									}
									delete data.DollarAmount;
									delete data.ClientAccountID;
									delete data.ClientAccountName;
									if (_this12.get("isNew")) {
										_this12.get("firebase").createRecordUser("beneficiary", data);
									}
								}
								localStorage.setItem("link", window.location.href);
								window.location.href = "#/dashboard/transaction/eTransfer/" + transaction.TransactionID;
							}, function (error) {
								_this12.errorMessage(error);
								_ember['default'].$('.complete').removeAttr('disabled');
							});
						} else if (this.get("isVisaDirect")) {
							this.get("api").visaDirectPushFunds(this.get("paymentToAdd")).then(function (transaction) {

								if (_this12.get("isStore")) {
									var data = _this12.get("paymentToAdd");
									data.Type = "Visa Direct";
									delete data.DollarAmount;
									delete data.ClientAccountID;
									delete data.ClientAccountName;
									if (_this12.get("isNew")) {
										_this12.get("firebase").createRecordUser("beneficiary", data);
									}
								}
								localStorage.setItem("link", window.location.href);
								window.location.href = "#/dashboard/transaction/VisaDirect/" + transaction.TransactionID;
							}, function (error) {
								_this12.errorMessage(error);
								_ember['default'].$('.complete').removeAttr('disabled');
							});
						} else {
							if (this.get("isRequestInfo")) {
								this.set("isStore", false);
								this.set("paymentToAdd.DelayBankingInfo", true);
							}
							this.get("api").withdrawTransaction(this.get("paymentToAdd")).then(function (transaction) {

								var data = _this12.get("paymentToAdd");
								if (_this12.get("isStore") && _this12.get("isNew")) {

									if (_this12.get("isCredit")) {
										data.Type = "Credit Card";
									} else if (_this12.get("isInteracEtransfer")) {
										data.Type = "Interac E-Transfer";
									} else {
										data.Type = "Bank Account";
									}
									delete data.DollarAmount;
									delete data.ClientAccountID;
									delete data.ClientAccountName;
									if (_this12.get("isFlinks2")) {
										data.OuthType = "iQ11";
										_this12.get("firebase").createRecordUser("beneficiary", data);
									} else {
										_this12.get("firebase").createRecordUser("beneficiary", data);
									}
								}
								if (_this12.get("isRequestInfo")) {
									_this12.set("paymentToAdd.TransactionID", transaction.TransactionID);

									var options = {
										RedirectURL: "https://request.vopay.com/",
										PaymentSelectionMethod: "bank",
										RedirectMethod: "JavascriptMessage",
										customerName: _this12.get("LongName"),
										Language: _this12.get("paymentToAdd.Language"),
										AccountSelectionMethod: _this12.get("paymentToAdd.AccountSelectionMethod"),
										TermsAndConditions: _this12.get("paymentToAdd.TermsAndConditions"),
										ClientReferenceNumber: _this12.get("paymentToAdd.ClientReferenceNumber")
									};
									console.log(options);
									_this12.get('api').getApiURL(options).then(function (iframe) {

										_this12.set("paymentToAdd.IframeKey", iframe.IframeKey);
										if ($("#UploadInvoice")[0].files[0] != null) {
											(0, _vopay_appUtilsBase64encoder['default'])($("#UploadInvoice")[0]).then(function (dataFile) {

												var optionsUpload = {
													DocumentName: $("#UploadInvoice")[0].files[0].name.split('.').slice(0, -1).join('.'),
													DocumentContent: dataFile,
													DocumentType: "invoice"
												};
												_this12.get("api").UploadDocument(optionsUpload).then(function (dataUpload) {
													console.log(dataUpload.DocumentID);
													_this12.set("paymentToAdd.DocumentID", dataUpload.DocumentID);
													_this12.get("api").RequestAccountInfo(_this12.get("paymentToAdd")).then(function (data) {

														_this12.set("transLocation", "#/dashboard/paylink-accounts/details/Withdraw/" + data.TransactionID + "/");
														_this12.set("link", data.Link);
														_ember['default'].$("#Overview").hide();
														_ember['default'].$("#email").show();
														_this12.set("isEmail", true);
													}, function (error) {
														_this12.errorMessage(error);
														_ember['default'].$('.complete').removeAttr('disabled');
													});
												}, function (errorUpload) {
													_this12.errorMessage(errorUpload);
													_ember['default'].$('.complete').removeAttr('disabled');
												});
											}, function (error3) {
												_this12.errorMessage(error3);
												_ember['default'].$('.complete').removeAttr('disabled');
											});
										} else {
											_this12.get("api").RequestAccountInfo(_this12.get("paymentToAdd")).then(function (data) {
												/**
            		localStorage.setItem("link", window.location.href);
            		window.location.href = "#/dashboard/paylink-accounts/details/Withdraw/"+data.TransactionID+"/"; 
            	**/
												_this12.set("transLocation", "#/dashboard/paylink-accounts/details/Withdraw/" + data.TransactionID + "/");
												_this12.set("link", data.Link);
												_ember['default'].$("#Overview").hide();
												_ember['default'].$("#email").show();
												_this12.set("isEmail", true);
											}, function (error) {
												_this12.errorMessage(error);
												_ember['default'].$('.complete').removeAttr('disabled');
											});
										}
									}, function (error2) {
										_this12.errorMessage(error2);
										_ember['default'].$('.complete').removeAttr('disabled');
									});
								} else {
									localStorage.setItem("link", window.location.href);
									window.location.href = "#/dashboard/transaction/Withdraw/" + transaction.TransactionID + "/" + _this12.get("paymentToAdd.Currency");
								}
							}, function (error) {
								var data2 = _this12.get("paymentToAdd");
								if ((_this12.get("isFlinks2") || data2.OuthType === "Flinks" || data2.OuthType === "iQ11") && _this12.get("fundCount") !== 3 && (error === "Operation is still pending" || error === "Operation had been dispatched to background process" || error === "Connection could not be established to the Bank" || error === "Online Banking Authentication Failed")) {
									_this12.set("fundCount", _this12.get("fundCount") + 1);

									var self = _this12;
									setTimeout(function () {
										self.send('create');
									}, 15000);
								} else {
									_this12.errorMessage(error);
									_this12.set("fundCount", 0);
									_ember['default'].$('.complete').removeAttr('disabled');
								}
							});
						}
					}
					//    this.get('api').addPaymentMethod(this.get('paymentToAdd')).then(

					//   bank => { // Resolve payment method
					//     if(this.get("paymentToAdd.IsDefault")){
					//       this.get('api').setDefault(bank.ID).then(
					//         () =>{
					//         	Ember.$('#paymentForm button').removeAttr('disabled');
					//         	if(sessionStorage.getItem("returnUrl")){
					//         		let url = sessionStorage.getItem("returnUrl");
					//         		sessionStorage.removeItem("returnUrl")
					//         		window.location.href= url;
					//         	}
					//         	else{
					//         		this.get("routing").transitionTo("dashboard.info");
					//         	}

					//         },
					//         err =>{
					//         	Ember.$('#paymentForm button').removeAttr('disabled');
					//           this.handleErrors(paymentFormArr, err);
					//         }
					//       );
					//     }
					//     else{
					//     	Ember.$('#paymentForm button').removeAttr('disabled');
					//     	if(sessionStorage.getItem("returnUrl")){
					//         	let url = sessionStorage.getItem("returnUrl");
					//         	sessionStorage.removeItem("returnUrl")
					//         	window.location.href= url;
					//       }
					//       else{
					//         	this.get("routing").transitionTo("dashboard.info");
					//       }
					//     }
					//   },
					//   err2 => {
					//   	Ember.$('#paymentForm button').removeAttr('disabled');
					//   	this.handleErrors(paymentFormArr, err2);
					//   }
					// );
				}
			}

		}
	});
});