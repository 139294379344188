define('vopay_app/helpers/calc-sum', ['exports', 'ember'], function (exports, _ember) {
    exports.calcSum = calcSum;

    function calcSum(params /*, hash*/) {

        if (!params[0] || params[0] !== params[0] || typeof params[0] !== 'number') {

            return 0;
        }

        if (params.lenght < 2) {

            return 0;
        }

        var decimal = params[0];

        params.shift();

        var value = params.reduce(function (cc, v) {
            return cc + parseFloat(v);
        }, 0);

        return value.toFixed(decimal);
    }

    exports['default'] = _ember['default'].Helper.helper(calcSum);
});