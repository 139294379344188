define('vopay_app/components/template-info', ['exports', 'ember', 'vopay_app/constants'], function (exports, _ember, _vopay_appConstants) {
	exports['default'] = _ember['default'].Component.extend({
		firebase: _ember['default'].inject.service('firebase'),
		PaymentTypeOptions: ["Collect", "Payment"],
		PaymentType: "Collect",
		routing: _ember['default'].inject.service('-routing'),
		currencies2: _vopay_appConstants['default'].currencies,
		init: function init() {
			this._super.apply(this, arguments);
			this.set("PaymentType", this.get("template.PaymentType"));
			this.set("currencyName", this.getCurrencyName(this.get("template.Currency")));
		},
		getCurrencyName: function getCurrencyName(name) {
			var ctr = this.get('currencies2');
			for (var key in ctr) {
				if (ctr[key].iso3 === name) {
					return ctr[key].name;
				}
			}
		},
		actions: {
			handlePaymentType: function handlePaymentType(value) {
				this.set("PaymentType", value);
			},
			moveToBulk: function moveToBulk() {
				if (this.get("PaymentType") == "Payment") {
					this.get("routing").transitionTo("dashboard.bulk-payment");
				} else {
					this.get("routing").transitionTo("dashboard.bulk-collect");
				}
				this.set("IsFinished", false);
			},
			FinishHandler: function FinishHandler() {
				this.set("IsFinished", true);
			}
		}
	});
});