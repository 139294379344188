define('vopay_app/helpers/not-empty', ['exports', 'ember'], function (exports, _ember) {
  exports.notEmpty = notEmpty;

  function notEmpty(params /*, hash*/) {

    if (Object.keys(params[0]).length > 0) {
      return true;
    } else {
      return false;
    }
  }

  exports['default'] = _ember['default'].Helper.helper(notEmpty);
});