define('vopay_app/routes/dashboard/transfer', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		api: _ember['default'].inject.service('api'),
		model: function model() {

			return _ember['default'].RSVP.hash({
				currencies: this.get('api').getGCMCurriencies(),
				subAccounts: this.get("api").getSubAccounts()
			});
		}

	});
});