define('vopay_app/components/paylink-settings', ['exports', 'ember', 'vopay_app/utils/entry-filter'], function (exports, _ember, _vopay_appUtilsEntryFilter) {
    exports['default'] = _ember['default'].Component.extend({
        IsDeclineEnable: false,
        IsDeclineEnablePrev: false,
        entryUtil: _vopay_appUtilsEntryFilter['default'].create(),
        api: _ember['default'].inject.service('api'),
        formObjectMethod: function formObjectMethod(formObj) {
            var arrLength = formObj.length;
            var payment = {};

            for (var i = 0; i < arrLength; i++) {
                payment[formObj[i].name] = formObj[i].value;
            }

            delete payment.InstitutionName;

            return payment;
        },
        errorMessage: function errorMessage(Message) {
            this.set("ErrorMessage", Message);
            this.set("isError", true);
        },
        watchUserName: _ember['default'].observer('PayLinkExpirationTime', function () {
            var data = this.get('entryUtil').onlyNumbers(this.get('PayLinkExpirationTime'));
            this.set('PayLinkExpirationTime', data);
        }),
        didInsertElement: function didInsertElement() {

            this._super.apply(this, arguments);

            _ember['default'].$("#paymentForm").parsley().destroy();
            _ember['default'].$('form').parsley({
                successClass: " ",
                errorClass: "form__input--error",
                errorsWrapper: "<p></p>",
                errorTemplate: "<span class='form__message'></span>"
            });
            this.set("PayLinkExpirationTime", this.get('settingsData.PaylinkSettings.PayLinkExpirationTime'));
        },
        init: function init() {
            this._super.apply(this, arguments);
            var isDecline = this.get("settingsData.PaylinkSettings.EnablePaylinkDecline");
            var IsSendEmail = this.get("settingsData.PaylinkSettings.SendPaylinkConfirmationEmails");
            if (isDecline == true || isDecline == "1") {
                this.set("IsDeclineEnable", true);
                this.set("IsDeclineEnablePrev", true);
            } else {
                this.set("IsDeclineEnable", false);
                this.set("IsDeclineEnablePrev", false);
            }
            if (IsSendEmail == true || IsSendEmail == "1") {
                this.set("IsSendEmailEnable", true);
                this.set("IsSendEmailEnablePrev", true);
            } else {
                this.set("IsSendEmailEnable", false);
                this.set("IsSendEmailEnablePrev", false);
            }
        },
        validateForm: function validateForm(formId) {

            var form = _ember['default'].$(formId);

            form.parsley({
                excluded: 'input[type=button], input[type=submit], input[type=reset]',
                inputs: 'input, textarea, select, input[type=hidden], :hidden'
            }).validate();

            return form.parsley().isValid();
        },
        actions: {
            closeModalError: function closeModalError() {
                this.set("isError", false);
            },
            moveToOverview: function moveToOverview() {
                if (this.validateForm('#paymentForm')) {
                    $("#Overview").show();
                    $("#paymentInfo").hide();
                    var paymentFormArr = _ember['default'].$('#paymentForm').serializeArray();
                    this.set('paymentToAdd', this.formObjectMethod(paymentFormArr));
                    this.set('paymentToAdd.IsDeclineEnable', this.get("IsDeclineEnable"));
                    this.set('paymentToAdd.SendPaylinkConfirmationEmails', this.get("IsSendEmailEnable"));
                    this.set("isSaved", false);
                }
            },
            TurnOffDecline: function TurnOffDecline() {
                this.set("IsDeclineEnable", !this.get("IsDeclineEnablePrev"));
                this.set("IsDeclineEnablePrev", this.get("IsDeclineEnable"));
            },
            TurnOffSendEmail: function TurnOffSendEmail() {
                this.set("IsSendEmailEnable", !this.get("IsSendEmailEnablePrev"));
                this.set("IsSendEmailEnablePrev", this.get("IsSendEmailEnable"));
            },
            moveToPayment: function moveToPayment() {

                $("#Overview").hide();
                $("#paymentInfo").show();
            },
            save: function save() {
                var _this = this;

                var options = this.get("paymentToAdd");
                var self = this;
                $("#Overview").hide();
                $("#paymentInfo").show();
                this.set("isSaved", true);
                this.get("api").setSenderEmail(options).then(function (data2) {
                    _this.get("api").setTokenExpiration(options).then(function (data3) {
                        _this.get("api").setDecline(options).then(function (data4) {
                            _this.get("api").setSendeEmail(options).then(function (data5) {}, function (error5) {
                                _this.errorMessage(error5);
                            });
                        }, function (error4) {
                            _this.errorMessage(error4);
                        });
                    }, function (error3) {
                        _this.errorMessage(error3);
                    });
                }, function (error) {
                    _this.errorMessage(error);
                });
            }
        }
    });
});