define('vopay_app/routes/dashboard/permissions/edit', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		api: _ember['default'].inject.service('api'),
		model: function model(id) {
			return _ember['default'].RSVP.hash({
				accountID: id["accountID"],
				data: this.get('api').getSubAccounts()
			});
		}
	});
});