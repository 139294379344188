define('vopay_app/components/schedule-transactions', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        api: _ember['default'].inject.service('api'),
        ScheduleType: "All",
        Status: "All",
        Frequency: "All",
        keyword: "",
        Statuses: ["All", "Upcoming", "Cancelled By User", "Pending", "In Progress", "Successful", "Failed", "Cancelled", "Cancellation Requested"],
        isError: false,
        init: function init() {
            this._super.apply(this, arguments);

            this.set("completed2", this.get("completed"));
            this.set("upcoming2", this.get("upcoming"));
        },
        wachValue: _ember['default'].observer('amount', function () {

            var isZeroAppend = false;
            var amount = this.get('amount');
            if (amount) {
                var myElement = _ember['default'].$("#Amount");
                if (myElement) {
                    var start = myElement.selectionStart;
                    var end = myElement.selectionEnd;

                    var amount2 = amount;
                    if (amount.replace()) {
                        amount2 = amount.replace(/[^\d.]+/g, '');
                        amount2 = Math.floor(parseFloat(amount2));
                    }
                    var split = amount.split(".");
                    var ending = split[1];

                    var amount3 = undefined;

                    var ending2 = null;
                    if (ending && ending.length && ending.length > 2) {

                        ending2 = ending.substring(0, 2);
                    } else {
                        ending2 = ending;
                    }

                    if (ending2 || ending2 === 0) {
                        amount3 = amount2.toLocaleString(undefined) + "." + ending2;
                    } else if (split[0] == "") {

                        if (ending2 || ending2 === 0) {
                            amount3 = "0." + ending2;
                            isZeroAppend = true;
                        } else {
                            amount3 = "0.";
                            isZeroAppend = true;
                        }
                    } else if (!ending && split.length == 2) {
                        amount3 = amount2.toLocaleString(undefined) + ".";
                    } else {
                        if (amount2) {
                            amount3 = amount2.toLocaleString(undefined);
                        } else {
                            amount3 = "";
                        }
                    }

                    this.$(myElement).val(amount3);

                    if (myElement.setSelectionRange) {
                        if (isZeroAppend) {
                            myElement.setSelectionRange(start + 2, end + 2);
                        } else if (amount3.length > amount.length && amount3.length > 4) {
                            myElement.setSelectionRange(start + 1, end + 1);
                        } else if (amount3.length < amount.length) {
                            myElement.setSelectionRange(start - 1, end - 1);
                        } else {
                            myElement.setSelectionRange(start, end);
                        }
                    } else if (myElement.createTextRange) {
                        var range = myElement.createTextRange();
                        range.collapse(true);
                        range.moveEnd('character', start);
                        range.moveStart('character', end);
                        range.select();
                    }
                }
            }
        }),
        filterTransactions: function filterTransactions(searchTerms, Status) {
            var completed = this.get("completed");
            var upcoming = this.get("upcoming");
            var filteredListUpcoming = {};
            var filteredListCompleted = {};
            searchTerms = searchTerms.toLowerCase();
            searchTerms = searchTerms.replace(/\$/g, '');
            searchTerms = searchTerms.replace(/\,/g, '');
            searchTerms = searchTerms.replace(/\s|_/g, "", '');
            if (upcoming && Status == "Upcoming") {
                var keys = Object.keys(upcoming);
                var next = -1;
                for (var i = 0; i < keys.length; i++) {
                    if (typeof upcoming[i] !== 'undefined') {
                        if (upcoming[i].Status == "upcoming" || upcoming[i].Status == "") {
                            if (upcoming[i].TransactionID.toLowerCase().replace(/\s|_/g, "").includes(searchTerms)) {
                                next = next + 1;
                                filteredListUpcoming[next] = upcoming[i];
                            }
                        }
                    }
                }
                this.set("upcoming2", filteredListUpcoming);
            } else {
                if (upcoming) {
                    var keys = Object.keys(upcoming);
                    var next = -1;
                    for (var i = 0; i < keys.length; i++) {

                        if (typeof upcoming[i] !== 'undefined') {

                            if (Status === null || Status === "" || Status === "All" || Status.toLowerCase() === upcoming[i].Status.toLowerCase()) {

                                if (upcoming[i].TransactionID && upcoming[i].TransactionID.toLowerCase().replace(/\s|_/g, "").includes(searchTerms) || upcoming[i].Status.toLowerCase().replace(/\s|_/g, "").includes(searchTerms) || upcoming[i].ScheduledDate.replace(/\s|_/g, "").includes(searchTerms) || ("$" + upcoming[i].Amount + " CAD").toLowerCase().replace(/\s|_/g, "").includes(searchTerms)) {
                                    next = next + 1;
                                    filteredListUpcoming[next] = upcoming[i];
                                }
                            }
                        }
                    }
                    this.set("upcoming2", filteredListUpcoming);
                }
            }
            if (completed) {
                var keys = Object.keys(completed);
                var next = -1;
                for (var i = 0; i < keys.length; i++) {

                    if (typeof completed[i] !== 'undefined') {

                        if (Status === null || Status === "" || Status === "All" || Status.toLowerCase() === completed[i].Status.toLowerCase()) {

                            if (completed[i].TransactionID.toLowerCase().replace(/\s|_/g, "").includes(searchTerms) || completed[i].Status.toLowerCase().replace(/\s|_/g, "").includes(searchTerms) || completed[i].ScheduledDate.replace(/\s|_/g, "").includes(searchTerms) || ("$" + completed[i].Amount + " CAD").toLowerCase().replace(/\s|_/g, "").includes(searchTerms)) {
                                next = next + 1;
                                filteredListCompleted[next] = completed[i];
                            }
                        }
                    }
                }
                this.set("completed2", filteredListCompleted);
            }
        },
        actions: {
            filterStatus: function filterStatus(value) {
                this.set("Status", value);
                this.filterTransactions(this.get("keyword"), this.get("Status"));
            },

            filterKeyword: function filterKeyword(value) {
                this.set("keyword", value);
                this.filterTransactions(this.get("keyword"), this.get("Status"));
            },
            CancelTransation: function CancelTransation(id) {
                var _this = this;

                var options = {};
                options.TransactionID = id;
                var self = this;
                this.get("api").cancelAccountTransactions(options).then(function (data) {
                    var options2 = {};
                    options2.ScheduledTransactionID = _this.get("ScheduledTransactionID");
                    _this.get('api').getScheduleTransactions(options2).then(function (data2) {
                        var transaction = data2.ScheduledTransactions;
                        transaction = transaction[0];
                        self.set("completed", transaction.CompletedTransactions);
                        self.set("upcoming", transaction.UpcomingTransactions);
                        self.filterTransactions(self.get("keyword"), self.get("Status"));
                    }, function (err2) {
                        self.set("ErrorMessage", err);
                        self.set("isError", true);
                    });
                }, function (err) {
                    _this.set("ErrorMessage", err);
                    _this.set("isError", true);
                    //this.errorMessage(err);
                });
            },
            EditTransactionUpcoming: function EditTransactionUpcoming(date, amount) {

                this.set("amount", amount);
                this.set("ChangeDate", date);
                this.set("isForm", true);
            },
            closeForm: function closeForm() {
                this.set("amount", 0);
                this.set("ChangeDate", null);
                this.set("isForm", false);
            },
            update: function update() {
                var _this2 = this;

                var options = {};
                options.UpcomingPayment = this.get("ChangeDate");
                options.ScheduledTransactionID = this.get("ScheduledTransactionID");
                options.Amount = this.get("amount").replace(/[^\d.]+/g, '');
                var self = this;
                this.get("api").editUpcomingTransaction(options).then(function (data) {

                    _this2.get('api').getScheduleTransactions(options).then(function (data2) {
                        var transaction = data2.ScheduledTransactions;
                        transaction = transaction[0];
                        self.set("completed", transaction.CompletedTransactions);
                        self.set("upcoming", transaction.UpcomingTransactions);
                        self.filterTransactions(self.get("keyword"), self.get("Status"));
                        self.set("isForm", false);
                    }, function (err2) {
                        self.set("ErrorMessage", err2);
                        self.set("isError", true);
                    });
                }, function (err) {
                    _this2.set("ErrorMessage", err);
                    _this2.set("isError", true);
                    //this.errorMessage(err);
                });
            },
            CancelTransactionUpcoming: function CancelTransactionUpcoming(date) {
                var _this3 = this;

                var options = {};
                options.UpcomingPayment = date;
                options.ScheduledTransactionID = this.get("ScheduledTransactionID");
                options.Amount = 0;
                var self = this;
                this.get("api").editUpcomingTransaction(options).then(function (data) {

                    _this3.get('api').getScheduleTransactions(options).then(function (data2) {
                        var transaction = data2.ScheduledTransactions;
                        transaction = transaction[0];
                        self.set("completed", transaction.CompletedTransactions);
                        self.set("upcoming", transaction.UpcomingTransactions);
                        self.filterTransactions(self.get("keyword"), self.get("Status"));
                    }, function (err2) {
                        self.set("ErrorMessage", err);
                        self.set("isError", true);
                    });
                }, function (err) {
                    _this3.set("ErrorMessage", err);
                    _this3.set("isError", true);
                    //this.errorMessage(err);
                });
            },
            closeModalError: function closeModalError() {
                this.set("isError", false);
            }

        }
    });
});