define('vopay_app/authenticators/custom', ['exports', 'ember-simple-auth/authenticators/base', 'vopay_app/config/environment'], function (exports, _emberSimpleAuthAuthenticatorsBase, _vopay_appConfigEnvironment) {

  // let endpoint = 'http://dev1.vopay.com/api';
  var earthnode = _vopay_appConfigEnvironment['default'].earthnode;

  /***
  * setCookie
  * @param token (String) - Token value
  *
  * Sets the token cookie
  */

  exports['default'] = _emberSimpleAuthAuthenticatorsBase['default'].extend({
    //default restore function
    api: Ember.inject.service('api'),
    restore: function restore() {

      //checks if the user is already authed by trying to access the /user endpoint
      return new Ember.RSVP.Promise(function (resolve, reject) {
        // jshint ignore:line

        var url = earthnode + '/account/balance';

        Ember.$.ajax({
          url: url,
          method: 'POST',
          data: {
            AccountID: localStorage.getItem("accountID"),
            Key: localStorage.getItem("key"),
            Signature: localStorage.getItem("signature")
          }
        }).done(function (data) {
          if (data.Success === true) {
            resolve(data);
          } else {
            reject(data.ErrorMessage);
          }
        }).fail(function (err) {

          reject(err);
        });
      });
    },

    //default authenticate function
    authenticate: function authenticate(options) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        // jshint ignore:line

        var url = earthnode + '/portal/login';

        Ember.$.ajax({
          url: url,
          method: 'POST',
          data: {
            Username: options.Username,
            Password: options.Password
          }
        }).done(function (data) {
          if (data.Success === true) {

            localStorage.setItem("key", data.Key);
            localStorage.setItem("accountID", data.AccountID);
            localStorage.setItem("AccountName", data.AccountName);
            localStorage.setItem("signature", data.Signature);
            localStorage.setItem("EFTCollectEnabled", data.EFTCollectEnabled);
            localStorage.setItem("EFTSendEnabled", data.EFTSendEnabled);
            localStorage.setItem("GCMEnabled", data.GCMEnabled);
            localStorage.setItem("UserManagementEnabled", data.UserManagementEnabled);
            localStorage.setItem("InteracMoneyRequestEnabled", data.InteracMoneyRequestEnabled);
            localStorage.setItem("USDEFTCollectEnabled", data.USDEFTCollectEnabled);
            localStorage.setItem("USDEFTSendEnabled", data.USDEFTSendEnabled);
            localStorage.setItem("InteracBulkPayoutEnabled ", data.InteracBulkPayoutEnabled);
            localStorage.setItem("PortalViewOnly", data.PortalViewOnly);
            localStorage.setItem("SubAccountPermissions", data.SubAccountPermissions);
            localStorage.setItem("ClientAccountsEnabled", data.ClientAccountsEnabled);
            localStorage.setItem("TransactionLabelOverrideEnabled", data.TransactionLabelOverrideEnabled);
            localStorage.setItem("VisaDirectEnabled", data.VisaDirectEnabled);
            localStorage.setItem("CreditCardEnabled", data.CreditCardEnabled);

            window.firebase.auth().signInWithCustomToken(data.FirebaseToken).then(function (data) {
              // Handle Errors here.
              localStorage.setItem("user", window.firebase.auth().currentUser.uid);
              resolve(data);

              // ...
            });
          } else {

              reject(data.ErrorMessage);
            }
        }).fail(function (err) {

          reject(err);
        });
      });
    },

    //default invalidate method
    invalidate: function invalidate() {
      window.sift_id = null;
      window.session_id = null;
    }
  });
});
/* global Ember */