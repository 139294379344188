define("vopay_app/templates/components/account-overview", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 22,
            "column": 0
          }
        },
        "moduleName": "vopay_app/templates/components/account-overview.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "dashboard overview__parent");
        dom.setAttribute(el1, "style", " min-height: 100vh;");
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "dashboard__section dashboard__section--body dashboard__section--fullWidth dashboard__section--noHeading transactions__recent overview__parent");
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "overview__container");
        dom.setAttribute(el3, "style", "width: 100%; max-height: initial; min-height: auto;");
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n		");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "overview__container");
        dom.setAttribute(el3, "style", "width: 29%; margin-right: 1%; margin-left: 0%;");
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n		");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "overview__container");
        dom.setAttribute(el3, "style", "width: 25%; margin-right: 1%; margin-left: 0%; ");
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n		");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "overview__container");
        dom.setAttribute(el3, "style", "width: 44%; margin-right: 0%; margin-left: 0%;");
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n		");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n	\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element0, 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [4]), 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element0, [6]), 1, 1);
        morphs[3] = dom.createMorphAt(dom.childAt(element0, [7]), 1, 1);
        morphs[4] = dom.createMorphAt(dom.childAt(element0, [8]), 1, 1);
        return morphs;
      },
      statements: [["content", "jump-page", ["loc", [null, [3, 2], [3, 15]]], 0, 0, 0, 0], ["content", "currency-chart", ["loc", [null, [10, 3], [10, 21]]], 0, 0, 0, 0], ["inline", "operating-calendar", [], ["bankHoliday", ["subexpr", "@mut", [["get", "bankHoliday", ["loc", [null, [13, 36], [13, 47]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [13, 3], [13, 49]]], 0, 0], ["inline", "order-status", [], ["limit", 5], ["loc", [null, [15, 3], [15, 27]]], 0, 0], ["content", "transaction-chart", ["loc", [null, [17, 3], [17, 24]]], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});