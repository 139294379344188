define("vopay_app/templates/components/password-widget", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 5,
            "column": 5
          }
        },
        "moduleName": "vopay_app/templates/components/password-widget.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("ul");
        dom.setAttribute(el1, "class", "password__requirments");
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("li");
        var el3 = dom.createTextNode("Your Password must include at least:");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("li");
        var el3 = dom.createElement("span");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("8 characters");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("li");
        var el3 = dom.createElement("span");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("1 number");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [3, 0]);
        var element2 = dom.childAt(element0, [5, 0]);
        var morphs = new Array(2);
        morphs[0] = dom.createAttrMorph(element1, 'class');
        morphs[1] = dom.createAttrMorph(element2, 'class');
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["icon-checkmark\t", ["subexpr", "if", [["get", "hasCharcters", ["loc", [null, [3, 38], [3, 50]]], 0, 0, 0, 0], "password_checkmark--highlight"], [], ["loc", [null, [3, 33], [3, 84]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["icon-checkmark ", ["subexpr", "if", [["get", "hasNumber", ["loc", [null, [4, 38], [4, 47]]], 0, 0, 0, 0], "password_checkmark--highlight"], [], ["loc", [null, [4, 33], [4, 81]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});