define('vopay_app/routes/application', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    memoryStorage: _ember['default'].inject.service('memory-storage'),

    actions: {

      error: function error(err, transition) {

        if (err) {
          if (err.Authentication) {
            location.reload(false);
            return false;
          }
          if (err === "Invalid Login. Please provide the required parameters") {
            location.reload(false);
            return false;
          } else {
            transition.abort();
            return true;
          }
        } else {
          transition.abort();
          return true;
        }
      }

    }

  });
});