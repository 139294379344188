define('vopay_app/routes/dashboard/client-accounts/transfer-funds', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		session: _ember['default'].inject.service('session'),
		api: _ember['default'].inject.service('api'),

		model: function model() {

			var authed = this.get('session.isAuthenticated');

			if (authed) {

				return _ember['default'].RSVP.hash({
					clientAccounts: this.get("api").getClientAccounts()
				});
			} else {
				this.transitionTo('login');
			}
		}
	});
});