define('vopay_app/components/bank-account-transfer', ['exports', 'ember', 'vopay_app/constants', 'vopay_app/helpers/complete-with-zeros', 'vopay_app/utils/entry-filter', 'vopay_app/mixins/error-handler', 'vopay_app/config/environment'], function (exports, _ember, _vopay_appConstants, _vopay_appHelpersCompleteWithZeros, _vopay_appUtilsEntryFilter, _vopay_appMixinsErrorHandler, _vopay_appConfigEnvironment) {
	var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

	var businessShortName = _vopay_appConfigEnvironment['default'].businessShortName;
	var businessLongName = _vopay_appConfigEnvironment['default'].businessLongName;

	exports['default'] = _ember['default'].Component.extend(_vopay_appMixinsErrorHandler['default'], {
		SupportEmail: _vopay_appConfigEnvironment['default'].businessEmail,
		LongName: businessLongName,
		ShortName: businessShortName,
		api: _ember['default'].inject.service('api'),
		firebase: _ember['default'].inject.service('firebase'),
		routing: _ember['default'].inject.service('-routing'),
		institutionName: '',
		financialInstitutionNumber: null,
		accountNumber: null,
		branchTransitNumber: null,
		entryUtil: _vopay_appUtilsEntryFilter['default'].create(),
		CurrencyName: "Canadian Dollar",
		isStore: true,
		isStore2: true,
		ClientAccountsEnabled: false,
		Currency: "CAD",
		currency2: "CAD",
		currencies4: {
			CAD: {
				name: 'Canadian Dollar',
				iso3: 'CAD'
			}
		},
		country: "Canada",
		currency: "CAD",
		InformationOptions: ["Manual", "Online Banking"],
		InformationValue: "Manual",
		accountTypeOptions: ["Business", "Individual"],
		defaultAccountType: "Business",
		signatureNumbers: [],
		signatureCurrNum: 0,
		signatureNumDel: 0,
		FlinksURL: "",
		isBusiness: true,
		isBusiness2: true,
		stageNumber: 1,
		stageName: "Bank Account Transfer",
		watchIsName: _ember['default'].observer("isAgree", function () {
			if (this.get("isAgree") === true) {
				_ember['default'].$("#termsConditions").show();
				_ember['default'].$("#overlay").show();
			}
		}),
		watchUserName: _ember['default'].observer('userName', function () {

			this.set('userName', this.get('entryUtil').onlyCharacters(this.get('userName')));
		}),

		watchfInstitutionNumber: _ember['default'].observer('financialInstitutionNumber', function () {

			this.set('financialInstitutionNumber', this.get('entryUtil').onlyNumbers(this.get('financialInstitutionNumber')));
		}),

		watchAccountNumber: _ember['default'].observer('accountNumber', function () {

			this.set('accountNumber', this.get('entryUtil').onlyNumbers(this.get('accountNumber')));
		}),

		watchBranchTransitNumber: _ember['default'].observer('branchTransitNumber', function () {

			this.set('branchTransitNumber', this.get('entryUtil').onlyNumbers(this.get('branchTransitNumber')));
		}),
		watchPhoneNumber: _ember['default'].observer('PhoneNumber', function () {

			this.set('PhoneNumber', this.get('entryUtil').onlyNumbers(this.get('PhoneNumber')));
		}),
		watchDualPhoneNumber: _ember['default'].observer('DualPhoneNumber', function () {

			this.set('DualPhoneNumber', this.get('entryUtil').onlyNumbers(this.get('DualPhoneNumber')));
		}),
		watchSignatoryPhoneNumber: _ember['default'].observer('SignatoryPhoneNumber', function () {

			this.set('SignatoryPhoneNumber', this.get('entryUtil').onlyNumbers(this.get('SignatoryPhoneNumber')));
		}),

		watchfInstitutionNumber2: _ember['default'].observer('financialInstitutionNumber2', function () {

			this.set('financialInstitutionNumber2', this.get('entryUtil').onlyNumbers(this.get('financialInstitutionNumber2')));
		}),

		watchAccountNumber2: _ember['default'].observer('accountNumber2', function () {

			this.set('accountNumber2', this.get('entryUtil').onlyNumbers(this.get('accountNumber2')));
		}),

		watchBranchTransitNumber2: _ember['default'].observer('branchTransitNumber2', function () {

			this.set('branchTransitNumber2', this.get('entryUtil').onlyNumbers(this.get('branchTransitNumber2')));
		}),
		watchPhoneNumber2: _ember['default'].observer('PhoneNumber2', function () {

			this.set('PhoneNumber2', this.get('entryUtil').onlyNumbers(this.get('PhoneNumber2')));
		}),
		watchDualPhoneNumber2: _ember['default'].observer('DualPhoneNumber2', function () {

			this.set('DualPhoneNumber2', this.get('entryUtil').onlyNumbers(this.get('DualPhoneNumber2')));
		}),
		watchSignatoryPhoneNumber2: _ember['default'].observer('SignatoryPhoneNumber2', function () {

			this.set('SignatoryPhoneNumber2', this.get('entryUtil').onlyNumbers(this.get('SignatoryPhoneNumber2')));
		}),
		watchPhoneNumberRequest: _ember['default'].observer('PhoneNumberRequest', function () {

			this.set('PhoneNumberRequest', this.get('entryUtil').onlyNumbers(this.get('PhoneNumberRequest')));
		}),
		wachValue: _ember['default'].observer('amount', function () {
			var isZeroAppend = false;
			var amount = this.get('amount');
			if (amount) {
				var myElement = this.$("#amount")[0];

				if (myElement) {
					var start = myElement.selectionStart;
					var end = myElement.selectionEnd;

					var amount2 = amount;
					if (amount.replace()) {
						amount2 = amount.replace(/[^\d.]+/g, '');
						amount2 = Math.floor(parseFloat(amount2));
					}
					var split = amount.split(".");
					var ending = split[1];

					var amount3 = undefined;

					var ending2 = null;
					if (ending && ending.length && ending.length > 2) {

						ending2 = ending.substring(0, 2);
					} else {
						ending2 = ending;
					}

					if (ending2 || ending2 === 0) {
						amount3 = amount2.toLocaleString(undefined) + "." + ending2;
					} else if (split[0] == "") {

						if (ending2 || ending2 === 0) {
							amount3 = "0." + ending2;
							isZeroAppend = true;
						} else {
							amount3 = "0.";
							isZeroAppend = true;
						}
					} else if (!ending && split.length == 2) {
						amount3 = amount2.toLocaleString(undefined) + ".";
					} else {
						if (amount2) {
							amount3 = amount2.toLocaleString(undefined);
						} else {
							amount3 = "";
						}
					}

					this.$(myElement).val(amount3);

					if (myElement.setSelectionRange) {
						if (isZeroAppend) {
							myElement.setSelectionRange(start + 2, end + 2);
						} else if (amount3.length > amount.length && amount3.length > 4) {
							myElement.setSelectionRange(start + 1, end + 1);
						} else if (amount3.length < amount.length) {
							myElement.setSelectionRange(start - 1, end - 1);
						} else {
							myElement.setSelectionRange(start, end);
						}
					} else if (myElement.createTextRange) {
						var range = myElement.createTextRange();
						range.collapse(true);
						range.moveEnd('character', start);
						range.moveStart('character', end);
						range.select();
					}
				}
			}
		}),
		init: function init() {
			this._super.apply(this, arguments);
			if (localStorage.getItem("ClientAccountsEnabled") === "1") {
				this.set("ClientAccountsEnabled", true);
			}

			var bankAccounts = this.get("bankAccounts.BankAccounts");
			var myAccounts = Object.values(bankAccounts);
			myAccounts = myAccounts.map(function (bankAccount) {
				return _extends({}, bankAccount, { Type: 'Bank Account', Country: 'CA' });
			});

			var firebaseAccounts = $.extend(true, {}, this.get("firebaseBeneficiaries"), this.get("firebaseLinkedAccounts"));
			var nonBankAccounts = Object.values(firebaseAccounts);
			nonBankAccounts = nonBankAccounts.filter(function (account) {
				return account.Type != "Bank Account";
			});

			var data = myAccounts.concat(nonBankAccounts);

			var keys = Object.keys(data).sort(function (a, b) {
				var aName = data[a].timestamp;
				var bName = data[b].timestamp;
				if (!aName) {
					aName = 0;
				}
				if (!bName) {
					bName = 0;
				}
				return aName < bName ? -1 : aName > bName ? 1 : 0;
			});
			var ordered = {};
			keys.forEach(function (key) {
				ordered[key] = data[key];
			});
			data = ordered;
			this.set("accounts", data);
			this.set("fromAccounts", data);
			this.set("toAccounts", data);
			var type = "Bank Account";

			var filteredAccounts = {};
			for (var key in data) {
				if (data[key]["Currency"] === "CAD" && data[key]["Type"] === type) {
					filteredAccounts[key] = data[key];
				}
			}
			this.set("accounts2", filteredAccounts);
			this.set("toAccounts", filteredAccounts);
			this.set("fromAccounts", filteredAccounts);
		},
		SetIq11URL: function SetIq11URL(ClientReferenceNumber) {
			var _this = this;

			var options = null;
			var AccountSelectionMethod = "any";

			if (location.hostname == "localhost") {
				options = {
					RedirectURL: "https://" + location.hostname + ":4200/",
					AccountSelectionMethod: AccountSelectionMethod,
					customerName: this.get("LongName"),
					ClientControlled: 'true',
					ClientReferenceNumber: ClientReferenceNumber
				};
			} else if (location.hostname == "bs-local.com") {

				options = {
					RedirectURL: "https://" + location.hostname + ":4200/",
					AccountSelectionMethod: AccountSelectionMethod,
					customerName: this.get("LongName"),
					ClientControlled: 'true',
					ClientReferenceNumber: ClientReferenceNumber
				};
			} else {
				options = {
					RedirectURL: "https://" + location.hostname + "/",
					AccountSelectionMethod: AccountSelectionMethod,
					customerName: this.get("LongName"),
					ClientControlled: 'true',
					ClientReferenceNumber: ClientReferenceNumber
				};
			}
			this.get('api').getApiURL(options).then(function (data) {
				_this.set("FlinksURL", data.EmbedURL);
			});
		},
		cancel: function cancel() {
			if (sessionStorage.getItem("returnUrl")) {
				var url = sessionStorage.getItem("returnUrl");
				sessionStorage.removeItem("returnUrl");
				window.location.href = url;
			} else {
				this.get("routing").transitionTo("dashboard.info");
			}
		},
		initialiseAutoAddress: function initialiseAutoAddress() {
			window.initializeLocationIQ("beneficiary-address", "beneficiary-address2", "Country", "beneficiary-state", "beneficiary-city", "beneficiary-postcode", this.get("isoCountry"), "Address1");
		},
		initialiseAutoAddress2: function initialiseAutoAddress2() {
			window.initializeLocationIQ("beneficiary-address22", "beneficiary-address2", "Country", "beneficiary-state2", "beneficiary-city2", "beneficiary-postcode2", this.get("isoCountry"), "Address1");
		},
		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
			_ember['default'].$("#paymentForm").parsley().destroy();
			_ember['default'].$('form').parsley({
				successClass: " ",
				errorClass: "form__input--error",
				errorsWrapper: "<p></p>",
				errorTemplate: "<span class='form__message'></span>"
			});
			var self = this;
			window.addEventListener('message', function (e) {
				if (e.data.step == "ACCOUNT_SELECTED") {
					localStorage.setItem("accountId", e.data.accountId);
				}
				if (e.data.step == "REDIRECT") {
					localStorage.setItem("loginId", e.data.loginId);
					localStorage.setItem("institution", e.data.institution);
					//location.href = e.data.url;
				}
				if (e.data.Step == "LINK") {
					localStorage.setItem("institution", e.data.Institution);
					localStorage.setItem("AccountNumber", e.data.AccountNumber);
					localStorage.setItem("BankId", e.data.BankId);
					localStorage.setItem("InstitutionNumber", e.data.InstitutionNumber);
				}
			});
		},
		validateForm: function validateForm(formId) {

			var form = _ember['default'].$(formId);

			form.parsley({
				excluded: 'input[type=button], input[type=submit], input[type=reset]',
				inputs: 'input, textarea, select, input[type=hidden], :hidden'
			}).validate();

			return form.parsley().isValid();
		},
		resetForm: function resetForm(formId) {

			var form = _ember['default'].$(formId);

			form.parsley().reset();
		},
		addPaymentMethod: function addPaymentMethod(formObj) {
			var arrLength = formObj.length;
			var payment = {};

			for (var i = 0; i < arrLength; i++) {
				payment[formObj[i].name] = formObj[i].value;
			}

			delete payment.InstitutionName;

			return payment;
		},
		getIsoCountry: function getIsoCountry(name) {
			var ctr = this.get('countries').filter(function (f) {
				return f.Country.toUpperCase() === name.toUpperCase();
			}).map(function (c) {
				return c.Abbreviation;
			});
			return ctr[0];
		},
		getCurrency: function getCurrency(name) {
			var ctr = this.get('countries').filter(function (f) {
				return f.Country.toUpperCase() === name.toUpperCase();
			}).map(function (c) {
				return c.DefaultCurrency;
			});
			return ctr[0];
		},
		getCountryID: function getCountryID(name) {
			var ctr = this.get('countries').filter(function (f) {
				return f.Country.toUpperCase() === name.toUpperCase();
			}).map(function (c) {
				return c.CountryID;
			});
			return ctr[0];
		},
		getCurrencyName: function getCurrencyName(name) {
			var ctr = this.get('currencies2');
			for (var key in ctr) {
				if (ctr[key].iso3 === name) {
					return ctr[key].name;
				}
			}
		},

		filterList: function filterList(currency, type) {
			this.set("PaymentType", type);
			var type2 = "Bank Account";
			if (this.get("isCredit")) {
				type2 = "Credit Card";
			}
			if (this.get("isInteracEtransfer")) {
				type2 = "Interac E-Transfer";
			}
			if (this.get("isRequest")) {
				type2 = "Money Request";
			}
			var filteredAccounts = {};
			var data = this.get("accounts");
			for (var key in data) {
				if (data[key]["Currency"] === currency && data[key]["Type"] === type2) {
					filteredAccounts[key] = data[key];
				}
			}

			this.set("accounts2", filteredAccounts);
		},
		checkIsAllowed: function checkIsAllowed() {
			if (localStorage.getItem("EFTCollectEnabled") === "0" || localStorage.getItem("EFTSendEnabled") === "0") {
				this.set("isShowingModal", true);
				return false;
			} else {
				return true;
			}
		},
		errorMessage: function errorMessage(Message) {
			this.set("ErrorMessage", Message);
			this.set("isError", true);
		},
		actions: {
			ClientAccountChange: function ClientAccountChange(value) {
				var _this2 = this;

				if (value != "") {
					var options = null;
					if (location.hostname == "localhost") {
						options = {
							RedirectURL: "https://" + location.hostname + ":4200/",
							customerName: this.get("LongName"),

							ClientAccountID: value,
							ClientControlled: 'true'
						};
					} else if (location.hostname == "bs-local.com") {

						options = {
							RedirectURL: "https://" + location.hostname + ":4200/",
							customerName: this.get("LongName"),

							ClientAccountID: value,
							ClientControlled: 'true'
						};
					} else {
						options = {
							RedirectURL: "https://" + location.hostname + "/",

							customerName: this.get("LongName"),
							ClientAccountID: value,
							ClientControlled: 'true'
						};
					}
					this.get('api').getApiURL(options).then(function (data) {
						_this2.set("FlinksURL", data.EmbedURL);
					});
				}
			},
			updateInstitutionName: function updateInstitutionName() {
				var finNumber = this.get('financialInstitutionNumber');
				this.set('financialInstitutionNumber', (0, _vopay_appHelpersCompleteWithZeros.completeWithZeros)(["l", finNumber, 3]));

				var bank = _vopay_appConstants['default'].canadaBanks.filter(function (b) {
					return parseInt(b.code) === parseInt(finNumber);
				});

				var eClass = _ember['default'].$("#paymentForm .form__bankFlagIcon").attr("class").match(/b[0-9]{3}/);

				if (eClass) {
					_ember['default'].$("#paymentForm .form__bankFlagIcon").removeClass('' + eClass[0]);
				}

				if (bank[0]) {

					_ember['default'].$("#paymentForm .form__bankFlagIcon").addClass('b' + (0, _vopay_appHelpersCompleteWithZeros.completeWithZeros)(["l", finNumber, 3]));
					this.set('institutionName', bank[0].name);
					_ember['default'].$("#institutionName").parsley().reset();
					_ember['default'].$("#financialInstitutionNumber").parsley().reset();
					_ember['default'].$("#paymentForm .newPaymentMethod__institution").slideDown();
				} else {
					_ember['default'].$("#paymentForm").parsley().destroy();
					_ember['default'].$('form').parsley({
						successClass: " ",
						errorClass: "form__input--error",
						errorsWrapper: "<p></p>",
						errorTemplate: "<span class='form__message'></span>"
					});
					_ember['default'].$("#paymentForm .newPaymentMethod__institution").slideUp();
					_ember['default'].$("#paymentForm .form__bankFlagIcon").addClass('b0');
					this.set('financialInstitutionNumber', '');
					this.set('institutionName', '');
					_ember['default'].$("#financialInstitutionNumber").val('');
					if (!finNumber) {
						_ember['default'].$("#financialInstitutionNumber").parsley().reset();
					} else {
						_ember['default'].$("#financialInstitutionNumber").parsley().validate();
					}
					// Ember.$("#financialInstitutionNumber").focus();
				}
			},
			updateInstitutionName2: function updateInstitutionName2() {

				var finNumber = this.get('financialInstitutionNumber2');

				this.set('financialInstitutionNumber2', (0, _vopay_appHelpersCompleteWithZeros.completeWithZeros)(["l", finNumber, 3]));

				var bank = _vopay_appConstants['default'].canadaBanks.filter(function (b) {
					return parseInt(b.code) === parseInt(finNumber);
				});

				var eClass = _ember['default'].$("#paymentForm2 .form__bankFlagIcon").attr("class").match(/b[0-9]{3}/);

				if (eClass) {
					_ember['default'].$("#paymentForm2 .form__bankFlagIcon").removeClass('' + eClass[0]);
				}

				if (bank[0]) {

					_ember['default'].$("#paymentForm2 .form__bankFlagIcon").addClass('b' + (0, _vopay_appHelpersCompleteWithZeros.completeWithZeros)(["l", finNumber, 3]));
					this.set('institutionName2', bank[0].name);
					_ember['default'].$("#institutionName2").parsley().reset();
					_ember['default'].$("#financialInstitutionNumber2").parsley().reset();
					_ember['default'].$("#paymentForm2 .newPaymentMethod__institution").slideDown();
				} else {
					_ember['default'].$("#paymentForm2").parsley().destroy();
					_ember['default'].$('#paymentForm2').parsley({
						successClass: " ",
						errorClass: "form__input--error",
						errorsWrapper: "<p></p>",
						errorTemplate: "<span class='form__message'></span>"
					});
					_ember['default'].$("#paymentForm2 .newPaymentMethod__institution").slideUp();
					_ember['default'].$("#paymentForm2 .form__bankFlagIcon").addClass('b0');
					this.set('financialInstitutionNumber2', '');
					this.set('institutionName2', '');

					if (!finNumber) {

						_ember['default'].$("#financialInstitutionNumber2").parsley().reset();
					} else {

						_ember['default'].$("#financialInstitutionNumber2").parsley().validate();
					}

					// Ember.$("#financialInstitutionNumber").focus();
				}
			},
			showTerms: function showTerms() {
				_ember['default'].$("#termsConditions").show();
				_ember['default'].$("#overlay").show();
				this.set("isAgree", false);
				_ember['default'].$("#isAgree").parsley().reset();
			},
			backTerms: function backTerms() {
				_ember['default'].$("#termsConditions").hide();
				_ember['default'].$("#overlay").hide();
				this.set("isAgree", false);
				_ember['default'].$("#isAgree").parsley().reset();
			},
			AgreeToTerms: function AgreeToTerms() {

				_ember['default'].$("#overlay").hide();
				_ember['default'].$("#termsConditions").hide();
				this.set("agreedToTerms", true);
				this.set("isAgree", true);
				_ember['default'].$("#isAgree").parsley().reset();
			},
			closeModal: function closeModal() {
				this.set("isShowingModal", false);
				return false;
			},
			CurrencyChanged: function CurrencyChanged(currency) {
				this.set("currency2", currency);
				var country = this.get("country");
				if (country === "Canada") {
					this.filterList("CAD");
				} else {
					this.filterList(currency);
				}
				this.set("currencyName", this.getCurrencyName(currency));
			},
			selectAccountFrom: function selectAccountFrom(value) {
				var _this3 = this;

				if (value === "New") {
					var myVar;

					(function () {
						_this3.set("isNew", true);
						_this3.set("isNew1", true);
						var self = _this3;
						_this3.set("toAccounts", _this3.get("accounts2"));

						myVar = setTimeout(function () {
							self.initialiseAutoAddress();
							clearTimeout(myVar);
						}, 1000);
					})();
				} else {
					var toArray = Object.assign({}, this.get("accounts2"));
					delete toArray[value];
					this.set("toAccounts", toArray);
					this.set("isNew1", false);
					this.set("isNew", false);
					var data = this.get("accounts");
					var paymentToAdd = data[value];
					//paymentToAdd.DollarAmount = Ember.$("#amount").val().replace(/\,/g,"");
					this.set("paymentToAddFrom", paymentToAdd);
				}
			},
			closeModalFlinks: function closeModalFlinks() {
				this.set("isFlinks", false);
				this.set("InformationValue", "Manual");
				clearInterval(window.flinksInterval);
			},
			closeModalError: function closeModalError() {
				this.set("isError", false);
			},

			moveToBank: function moveToBank() {
				var _this4 = this;

				if (this.validateForm('#paymentForm') || this.get("isFlinks2") || this.get("isSecond")) {
					(function () {
						/**
      	    		this.resetForm('#paymentForm');
      	    		this.set("isBank", true);
      	    		this.get("isFlinks2", false)
      		    	Ember.$("#Overview").hide();
      		    	Ember.$("#paymentInfo").hide();
      		    	Ember.$("#benifeciaryInfo").hide();
      				Ember.$("#bankInfo").show();
      				Ember.$("#benifeciaryInfo2").hide();
      		    	this.set("stageNumber", 3);
      				this.set("stageName", "Bank Details");
      				this.get("isSecond", true);
      				Ember.$("#accountInfo").hide();
      				this.set("isOverview", false); */
						_this4.SetIq11URL(_this4.get("ClientReferenceNumber"));
						_this4.set("isFlinks", true);
						var self = _this4;
						window.flinksInterval = setInterval(function () {
							if (localStorage.getItem("FlinksToken") != null) {
								var paymentFormArr = _ember['default'].$('#paymentForm').serializeArray();
								if (self.get("isNew") == true && self.get("isNew2") == true) {
									self.set('paymentToAddFrom', self.addPaymentMethod(paymentFormArr));
									self.set('paymentToAddFrom.DollarAmount', self.get("amount").replace(/,/g, ''));

									if (localStorage.getItem("institution") && localStorage.getItem("institution") != "undefined") {
										self.set('paymentToAddFrom.InstitutionName', localStorage.getItem("institution"));
										self.set("paymentToAddFrom.FinancialInstitutionNumber", "");
									} else {
										var finNumber = localStorage.getItem("InstitutionNumber");
										var bank = _vopay_appConstants['default'].canadaBanks.filter(function (b) {
											return parseInt(b.code) === parseInt(finNumber);
										});

										if (bank[0]) {
											self.set('paymentToAddFrom.InstitutionName', bank[0].name);
										} else {
											self.set('paymentToAddFrom.InstitutionName', "");
										}
										self.set("paymentToAddFrom.FinancialInstitutionNumber", finNumber);
									}
									if (self.get("country") === "Canada") {
										self.set("paymentToAddFrom.Country", "CA");
									} else {
										self.set("paymentToAddFrom.Country", self.get("isoCountry"));
									}
									if (self.get("paymentToAddFrom.Iban")) {
										self.set("paymentToAddFrom.Iban", self.get("isoCountry") + "" + self.get("paymentToAddTo.Iban"));
									}

									self.set("paymentToAddFrom.Currency", self.get("currency2"));
									self.set("paymentToAddFrom.Notes", _ember['default'].$("#Notes").val());
									self.set("paymentToAddFrom.Token", localStorage.getItem("FlinksToken"));
									if (localStorage.getItem("AccountNumber")) {
										self.set('paymentToAddFrom.MaskedAccount', localStorage.getItem("AccountNumber"));
									} else {
										self.set("paymentToAddFrom.MaskedAccount", localStorage.getItem("MaskedAccount"));
									}
									if (localStorage.getItem("BankId")) {
										self.set('paymentToAddFrom.BankId', localStorage.getItem("BankId"));
									}
									localStorage.removeItem("BankId");

									self.set("paymentToAddFrom.BranchTransitNumber", "");
									self.set("paymentToAddFrom.AccountNumber", "");

									self.set("InformationValue", "Manual");
									self.initialiseAutoAddress2();
									self.set("stageNumber", 4);
									_ember['default'].$("#paymentInfo").hide();
									_ember['default'].$("#benifeciaryInfo").hide();
									_ember['default'].$("#bankInfo").hide();
									_ember['default'].$("#benifeciaryInfo2").show();
									_ember['default'].$("#etransfer").hide();

									self.set("isOverview", false);
								} else if (self.get("isNew") == true) {
									self.set('paymentToAddFrom', self.addPaymentMethod(paymentFormArr));
									self.set('paymentToAddFrom.DollarAmount', self.get("amount").replace(/,/g, ''));

									if (localStorage.getItem("institution") && localStorage.getItem("institution") != "undefined") {
										self.set('paymentToAddFrom.InstitutionName', localStorage.getItem("institution"));
										self.set("paymentToAddFrom.FinancialInstitutionNumber", "");
									} else {
										var finNumber = localStorage.getItem("InstitutionNumber");
										var bank = _vopay_appConstants['default'].canadaBanks.filter(function (b) {
											return parseInt(b.code) === parseInt(finNumber);
										});

										if (bank[0]) {
											self.set('paymentToAddFrom.InstitutionName', bank[0].name);
										} else {
											self.set('paymentToAddFrom.InstitutionName', "");
										}
										self.set("paymentToAddFrom.FinancialInstitutionNumber", finNumber);
									}
									if (self.get("country") === "Canada") {
										self.set("paymentToAddFrom.Country", "CA");
									} else {
										self.set("paymentToAddFrom.Country", self.get("isoCountry"));
									}
									if (self.get("paymentToAddFrom.Iban")) {
										self.set("paymentToAddFrom.Iban", self.get("isoCountry") + "" + self.get("paymentToAddFrom.Iban"));
									}

									self.set("paymentToAddFrom.Currency", self.get("currency2"));
									self.set("paymentToAddFrom.Notes", _ember['default'].$("#Notes").val());
									self.set("paymentToAddFrom.Token", localStorage.getItem("FlinksToken"));
									if (localStorage.getItem("AccountNumber")) {
										self.set('paymentToAddFrom.MaskedAccount', localStorage.getItem("AccountNumber"));
									} else {
										self.set("paymentToAddFrom.MaskedAccount", localStorage.getItem("MaskedAccount"));
									}
									if (localStorage.getItem("BankId")) {
										self.set('paymentToAddFrom.BankId', localStorage.getItem("BankId"));
									}
									localStorage.removeItem("BankId");

									self.set("paymentToAddFrom.BranchTransitNumber", "");
									self.set("paymentToAddFrom.AccountNumber", "");
									self.set("InformationValue", "Manual");

									self.set("paymentToAddTo.DollarAmount", _ember['default'].$("#amount").val().replace(/\,/g, ""));
									self.set("paymentToAddFrom.DollarAmount", _ember['default'].$("#amount").val().replace(/\,/g, ""));
									_ember['default'].$("#paymentInfo").hide();
									_ember['default'].$("#benifeciaryInfo").hide();
									_ember['default'].$("#bankInfo").hide();
									_ember['default'].$("#Overview").show();
									_ember['default'].$("#etransfer").hide();
									self.set("isOverview", true);
								} else if (self.get("isNew2") == true) {
									self.set('paymentToAddTo', self.addPaymentMethod(paymentFormArr));
									self.set('paymentToAddTo.DollarAmount', self.get("amount").replace(/,/g, ''));
									if (localStorage.getItem("institution") && localStorage.getItem("institution") != "undefined") {
										self.set('paymentToAddTo.InstitutionName', localStorage.getItem("institution"));
										self.set("paymentToAddTo.FinancialInstitutionNumber", "");
									} else {
										var finNumber = localStorage.getItem("InstitutionNumber");
										var bank = _vopay_appConstants['default'].canadaBanks.filter(function (b) {
											return parseInt(b.code) === parseInt(finNumber);
										});

										if (bank[0]) {
											self.set('paymentToAddTo.InstitutionName', bank[0].name);
										} else {
											self.set('paymentToAddTo.InstitutionName', "");
										}
										self.set("paymentToAddTo.FinancialInstitutionNumber", finNumber);
									}
									if (self.get("country") === "Canada") {
										self.set("paymentToAddTo.Country", "CA");
									} else {
										self.set("paymentToAddTo.Country", self.get("isoCountry"));
									}
									if (self.get("paymentToAddTo.Iban")) {
										self.set("paymentToAddTo.Iban", self.get("isoCountry") + "" + self.get("paymentToAddTo.Iban"));
									}

									self.set("paymentToAddTo.Currency", self.get("currency2"));
									self.set("paymentToAddTo.Notes", _ember['default'].$("#Notes").val());
									self.set("paymentToAddTo.Token", localStorage.getItem("FlinksToken"));
									if (localStorage.getItem("AccountNumber")) {
										self.set('paymentToAddTo.MaskedAccount', localStorage.getItem("AccountNumber"));
									} else {
										self.set("paymentToAddTo.MaskedAccount", localStorage.getItem("MaskedAccount"));
									}
									if (localStorage.getItem("BankId")) {
										self.set('paymentToAddTo.BankId', localStorage.getItem("BankId"));
									}
									localStorage.removeItem("BankId");

									self.set("paymentToAddTo.BranchTransitNumber", "");
									self.set("paymentToAddTo.AccountNumber", "");

									self.set("paymentToAddTo.DollarAmount", _ember['default'].$("#amount").val().replace(/\,/g, ""));
									self.set("paymentToAddFrom.DollarAmount", _ember['default'].$("#amount").val().replace(/\,/g, ""));
									_ember['default'].$("#paymentInfo").hide();
									_ember['default'].$("#benifeciaryInfo").hide();
									_ember['default'].$("#bankInfo").hide();
									_ember['default'].$("#Overview").show();
									_ember['default'].$("#etransfer").hide();
								}

								self.set("financialInstitutionNumber", "");
								self.set("accountNumber", "");
								self.set("branchTransitNumber", "");
								self.set("SignatoryPhoneNumber", "");
								self.set("DualPhoneNumber", "");
								self.set("isFlinks2", true);
								self.set("isFlinks", false);
								self.set("InformationValue", "Manual");
								localStorage.removeItem("AccountNumber");
								localStorage.removeItem("TokenLocation");
								localStorage.removeItem("FlinksToken");
								localStorage.removeItem("MaskedAccount");
								localStorage.removeItem("FullName");
								localStorage.removeItem("accountId");
								localStorage.removeItem("loginId");
								localStorage.removeItem("institution");
								clearInterval(window.flinksInterval);
							}
						}, 100);
					})();
				}
			},
			moveToBank2: function moveToBank2() {
				var _this5 = this;

				if (this.validateForm('#paymentForm2') || this.get("isFlinks3")) {
					(function () {
						/** 
      	this.resetForm('#paymentForm2');
      	this.set("isBank2", true);
      	this.get("isFlinks2", false)
      	Ember.$("#Overview").hide();
      	Ember.$("#paymentInfo").hide();
      	Ember.$("#benifeciaryInfo").hide();
      Ember.$("#bankInfo").hide();
      Ember.$("#benifeciaryInfo2").hide();
      Ember.$("#bankInfo2").show();
      	this.set("stageNumber", 5);
      	this.set("stageName", "Bank Details");
      Ember.$("#accountInfo").hide();
      this.set("isOverview", false);*/
						_this5.SetIq11URL(_this5.get("ClientReferenceNumber2"));
						_this5.set("isFlinks", true);
						var self = _this5;
						window.flinksInterval = setInterval(function () {
							if (localStorage.getItem("FlinksToken") != null) {
								var paymentFormArr = _ember['default'].$('#paymentForm2').serializeArray();
								self.set('paymentToAddTo', self.addPaymentMethod(paymentFormArr));
								self.set('paymentToAddTo.DollarAmount', self.get("amount").replace(/,/g, ''));
								if (localStorage.getItem("institution") && localStorage.getItem("institution") != "undefined") {
									self.set('paymentToAddTo.InstitutionName', localStorage.getItem("institution"));
									self.set("paymentToAddTo.FinancialInstitutionNumber", "");
								} else {
									var finNumber = localStorage.getItem("InstitutionNumber");
									var bank = _vopay_appConstants['default'].canadaBanks.filter(function (b) {
										return parseInt(b.code) === parseInt(finNumber);
									});

									if (bank[0]) {
										self.set('paymentToAddTo.InstitutionName', bank[0].name);
									} else {
										self.set('paymentToAddTo.InstitutionName', "");
									}
									self.set("paymentToAddTo.FinancialInstitutionNumber", finNumber);
								}
								if (self.get("country") === "Canada") {
									self.set("paymentToAddTo.Country", "CA");
								} else {
									self.set("paymentToAddTo.Country", self.get("isoCountry"));
								}
								if (self.get("paymentToAddTo.Iban")) {
									self.set("paymentToAddTo.Iban", self.get("isoCountry") + "" + self.get("paymentToAddTo.Iban"));
								}

								self.set("paymentToAddTo.Currency", self.get("currency2"));
								self.set("paymentToAddTo.Notes", _ember['default'].$("#Notes").val());
								self.set("paymentToAddTo.Token", localStorage.getItem("FlinksToken"));
								if (localStorage.getItem("AccountNumber")) {
									self.set('paymentToAddTo.MaskedAccount', localStorage.getItem("AccountNumber"));
								} else {
									self.set("paymentToAddTo.MaskedAccount", localStorage.getItem("MaskedAccount"));
								}
								if (localStorage.getItem("BankId")) {
									self.set('paymentToAddTo.BankId', localStorage.getItem("BankId"));
								}
								localStorage.removeItem("BankId");

								self.set("paymentToAddTo.BranchTransitNumber", "");
								self.set("paymentToAddTo.AccountNumber", "");

								if (self.get("isFlinks2")) {
									self.set("paymentToAddTo.DollarAmount", _ember['default'].$("#amount").val().replace(/\,/g, ""));
									self.set("paymentToAddFrom.DollarAmount", _ember['default'].$("#amount").val().replace(/\,/g, ""));
									var paymentFormDataArr = _ember['default'].$('#paymentForm').serializeArray();
									var paymentData = self.addPaymentMethod(paymentFormDataArr);
									self.set("paymentToAddTo.ClientAccountID", paymentData.ClientAccountID);
									self.set("paymentToAddFrom.ClientAccountID", paymentData.ClientAccountID);
									self.set("paymentToAddTo.ClientAccountName", paymentData.ClientAccountName);
									self.set("paymentToAddFrom.ClientAccountName", paymentData.ClientAccountName);
								} else {
									var _paymentFormArr = _ember['default'].$('#paymentForm').serializeArray();
									self.set('paymentToAddFrom', self.addPaymentMethod(_paymentFormArr));
									self.set("paymentToAddFrom.Currency", self.get("currency2"));

									if (self.get("country") === "Canada") {
										self.set("paymentToAddFrom.Country", "CA");
									} else {
										self.set("paymentToAddFrom.Country", self.get("isoCountry"));
									}
									self.set("paymentToAddTo.DollarAmount", _ember['default'].$("#amount").val().replace(/\,/g, ""));
									self.set("paymentToAddFrom.DollarAmount", _ember['default'].$("#amount").val().replace(/\,/g, ""));
									var paymentFormDataArr = _ember['default'].$('#paymentForm').serializeArray();
									var paymentData = self.addPaymentMethod(paymentFormDataArr);
									self.set("paymentToAddTo.ClientAccountID", paymentData.ClientAccountID);
									self.set("paymentToAddFrom.ClientAccountID", paymentData.ClientAccountID);
									self.set("paymentToAddTo.ClientAccountName", paymentData.ClientAccountName);
									self.set("paymentToAddFrom.ClientAccountName", paymentData.ClientAccountName);
								}

								self.set("isOverview", true);
								_ember['default'].$("#paymentInfo").hide();
								_ember['default'].$("#benifeciaryInfo").hide();
								_ember['default'].$("#bankInfo2").hide();
								_ember['default'].$("#Overview").show();
								_ember['default'].$("#etransfer").hide();
								_ember['default'].$("#benifeciaryInfo2").hide();
								self.set("financialInstitutionNumber2", "");
								self.set("accountNumber2", "");
								self.set("branchTransitNumber2", "");
								self.set("SignatoryPhoneNumber2", "");
								self.set("DualPhoneNumber2", "");
								self.set("isFlinks3", true);
								self.set("isFlinks", false);
								self.set("InformationValue", "Manual");
								localStorage.removeItem("AccountNumber");
								localStorage.removeItem("TokenLocation");
								localStorage.removeItem("FlinksToken");
								localStorage.removeItem("MaskedAccount");
								localStorage.removeItem("FullName");
								localStorage.removeItem("accountId");
								localStorage.removeItem("loginId");
								localStorage.removeItem("institution");
								clearInterval(window.flinksInterval);
							}
						}, 100);
					})();
				}
			},
			moveToBeneficiary2: function moveToBeneficiary2() {
				_ember['default'].$("#beneficiary-address22").focusout(function () {

					setTimeout(function () {
						var address = _ember['default'].$("#beneficiary-address22").val();
						var city = _ember['default'].$("#beneficiary-city2").val();
						var state = _ember['default'].$("#beneficiary-state2").val();
						var postcode = _ember['default'].$("#bbeneficiary-postcode2").val();

						if (address) {
							_ember['default'].$("#beneficiary-address22").parsley().reset();
						}
						if (city) {
							_ember['default'].$("#beneficiary-city2").parsley().reset();
						}
						if (state) {
							_ember['default'].$("#beneficiary-state2").parsley().reset();
						}
						if (postcode) {
							_ember['default'].$("#beneficiary-postcode2").parsley().reset();
						}
					}, 500);
				});
				if (this.validateForm('#paymentForm') || this.get("isFlinks2") === true) {

					_ember['default'].$("#Overview").hide();
					_ember['default'].$("#paymentInfo").hide();
					_ember['default'].$("#benifeciaryInfo").hide();
					_ember['default'].$("#benifeciaryInfo2").show();
					_ember['default'].$("#bankInfo").hide();
					_ember['default'].$("#bankInfo2").hide();
					this.set("isBank2", false);
					this.set("stageNumber", 4);
					this.get("isFlinks3", false);
					this.set("stageName", "Account Holder Details");
					this.initialiseAutoAddress2();
					this.set("isOverview", false);
				}
			},
			moveToBenifeciary: function moveToBenifeciary() {
				_ember['default'].$("#beneficiary-address").focusout(function () {

					setTimeout(function () {
						var address = _ember['default'].$("#beneficiary-address").val();
						var city = _ember['default'].$("#beneficiary-city").val();
						var state = _ember['default'].$("#beneficiary-state").val();
						var postcode = _ember['default'].$("#beneficiary-postcode").val();

						if (address) {
							_ember['default'].$("#beneficiary-address").parsley().reset();
						}
						if (city) {
							_ember['default'].$("#beneficiary-city").parsley().reset();
						}
						if (state) {
							_ember['default'].$("#beneficiary-state").parsley().reset();
						}
						if (postcode) {
							_ember['default'].$("#beneficiary-postcode").parsley().reset();
						}
					}, 500);
				});
				_ember['default'].$("#beneficiary-address").keyup(function () {
					if (!_ember['default'].$("#beneficiary-address").val()) {
						_ember['default'].$("#beneficiary-city").val("");
						_ember['default'].$("#beneficiary-postcode").val("");
						_ember['default'].$("#beneficiary-address").val("");
						_ember['default'].$("#beneficiary-state").val("");
					}
				});
				_ember['default'].$("#beneficiary-city").keyup(function () {
					if (!_ember['default'].$("#beneficiary-city").val()) {
						_ember['default'].$("#beneficiary-city").val("");
						_ember['default'].$("#beneficiary-postcode").val("");
						_ember['default'].$("#beneficiary-address").val("");
						_ember['default'].$("#beneficiary-state").val("");
					}
				});
				_ember['default'].$("#beneficiary-postcode").keyup(function () {
					if (!_ember['default'].$("#beneficiary-postcode").val()) {
						_ember['default'].$("#beneficiary-city").val("");
						_ember['default'].$("#beneficiary-postcode").val("");
						_ember['default'].$("#beneficiary-address").val("");
						_ember['default'].$("#beneficiary-state").val("");
					}
				});
				var self = this;
				_ember['default'].$("#beneficiary-address").on('input', function () {

					_ember['default'].$("#beneficiary-address").val(self.get('entryUtil').noSpecialCharacter(_ember['default'].$("#beneficiary-address").val()));
				});
				_ember['default'].$("#beneficiary-city").on('input', function () {

					_ember['default'].$("#beneficiary-city").val(self.get('entryUtil').noSpecialCharacter(_ember['default'].$("#beneficiary-city").val()));
				});
				_ember['default'].$("#beneficiary-postcode").on('input', function () {

					_ember['default'].$("#beneficiary-postcode").val(self.get('entryUtil').onlyAlphaNumeric(_ember['default'].$("#beneficiary-postcode").val()));
				});
				_ember['default'].$("#beneficiary-address22").focusout(function () {

					setTimeout(function () {
						var address = _ember['default'].$("#beneficiary-address22").val();
						var city = _ember['default'].$("#beneficiary-city2").val();
						var state = _ember['default'].$("#beneficiary-state2").val();
						var postcode = _ember['default'].$("#bbeneficiary-postcode2").val();

						if (address) {
							_ember['default'].$("#beneficiary-address22").parsley().reset();
						}
						if (city) {
							_ember['default'].$("#beneficiary-city2").parsley().reset();
						}
						if (state) {
							_ember['default'].$("#beneficiary-state2").parsley().reset();
						}
						if (postcode) {
							_ember['default'].$("#beneficiary-postcode2").parsley().reset();
						}
					}, 500);
				});
				_ember['default'].$("#beneficiary-address22").keyup(function () {
					if (!_ember['default'].$("#beneficiary-address22").val()) {
						_ember['default'].$("#beneficiary-city2").val("");
						_ember['default'].$("#beneficiary-postcode2").val("");
						_ember['default'].$("#beneficiary-address22").val("");
						_ember['default'].$("#beneficiary-state2").val("");
					}
				});
				_ember['default'].$("#beneficiary-city2").keyup(function () {
					if (!_ember['default'].$("#beneficiary-city2").val()) {
						_ember['default'].$("#beneficiary-city2").val("");
						_ember['default'].$("#beneficiary-postcode2").val("");
						_ember['default'].$("#beneficiary-address22").val("");
						_ember['default'].$("#beneficiary-state2").val("");
					}
				});
				_ember['default'].$("#beneficiary-postcode2").keyup(function () {
					if (!_ember['default'].$("#beneficiary-postcode2").val()) {
						_ember['default'].$("#beneficiary-city2").val("");
						_ember['default'].$("#beneficiary-postcode2").val("");
						_ember['default'].$("#beneficiary-address22").val("");
						_ember['default'].$("#beneficiary-state2").val("");
					}
				});
				_ember['default'].$("#beneficiary-address22").on('input', function () {

					_ember['default'].$("#beneficiary-address22").val(self.get('entryUtil').noSpecialCharacter(_ember['default'].$("#beneficiary-address22").val()));
				});
				_ember['default'].$("#beneficiary-city2").on('input', function () {

					_ember['default'].$("#beneficiary-city2").val(self.get('entryUtil').noSpecialCharacter(_ember['default'].$("#beneficiary-city2").val()));
				});
				_ember['default'].$("#beneficiary-postcode2").on('input', function () {

					_ember['default'].$("#beneficiary-postcode2").val(self.get('entryUtil').onlyAlphaNumeric(_ember['default'].$("#beneficiary-postcode2").val()));
				});

				$("#amount").parsley().validate();

				if (this.checkIsAllowed() && $("#amount").parsley().isValid({ force: true })) {
					_ember['default'].$("#Overview").hide();
					_ember['default'].$("#paymentInfo").hide();
					_ember['default'].$("#benifeciaryInfo").show();
					_ember['default'].$("#benifeciaryInfo2").hide();
					_ember['default'].$("#bankInfo").hide();
					this.set("isBank", false);
					this.get("isSecond", false);
					this.get("isFlinks2", false);
					this.set("stageNumber", 2);
					if (this.get("isCollect") === "true") {
						this.set("stageName", "Account Holder Details");
					} else {
						this.set("stageName", "Account Holder Details");
					}
					this.set("isOverview", false);
					_ember['default'].$("#accountInfo").hide();
				}
			},
			moveToPaymentInfo: function moveToPaymentInfo() {
				_ember['default'].$("#Overview").hide();
				_ember['default'].$("#paymentInfo").show();
				_ember['default'].$("#benifeciaryInfo").hide();
				_ember['default'].$("#bankInfo").hide();
				this.set("isBank", false);
				this.get("isSecond", false);
				this.set("stageNumber", 1);
				this.set("isOverview", false);
				this.set("stageName", "Bank Account Transfer");
			},
			recipientCountryChanged: function recipientCountryChanged(country) {

				this.set("showRecipient", false);
				this.set('country', country);
				this.set("isoCountry", this.getIsoCountry(country));
				this.set('countryID', this.getCountryID(country));
			},
			handleIfFlinks: function handleIfFlinks(value) {
				var _this6 = this;

				if (value === "Online Banking") {
					(function () {
						_this6.SetIq11URL(_this6.get("ClientReferenceNumber"));
						_this6.set("isFlinks", true);
						var self = _this6;
						window.flinksInterval = setInterval(function () {
							if (localStorage.getItem("FlinksToken") != null) {
								var paymentFormArr = _ember['default'].$('#paymentForm').serializeArray();
								if (self.get("isNew") == true && self.get("isNew2") == true) {
									self.set('paymentToAddFrom', self.addPaymentMethod(paymentFormArr));
									self.set('paymentToAddFrom.DollarAmount', self.get("amount").replace(/,/g, ''));
									if (localStorage.getItem("institution") && localStorage.getItem("institution") != "undefined") {
										self.set('paymentToAddFrom.InstitutionName', localStorage.getItem("institution"));
										self.set("paymentToAddFrom.FinancialInstitutionNumber", "");
									} else {
										var finNumber = localStorage.getItem("InstitutionNumber");
										var bank = _vopay_appConstants['default'].canadaBanks.filter(function (b) {
											return parseInt(b.code) === parseInt(finNumber);
										});

										if (bank[0]) {
											self.set('paymentToAddFrom.InstitutionName', bank[0].name);
										} else {
											self.set('paymentToAddFrom.InstitutionName', "");
										}
										self.set("paymentToAddFrom.FinancialInstitutionNumber", finNumber);
									}
									if (self.get("country") === "Canada") {
										self.set("paymentToAddFrom.Country", "CA");
									} else {
										self.set("paymentToAddFrom.Country", self.get("isoCountry"));
									}
									if (self.get("paymentToAddFrom.Iban")) {
										self.set("paymentToAddFrom.Iban", self.get("isoCountry") + "" + self.get("paymentToAddTo.Iban"));
									}

									self.set("paymentToAddFrom.Currency", self.get("currency2"));
									self.set("paymentToAddFrom.Notes", _ember['default'].$("#Notes").val());
									self.set("paymentToAddFrom.Token", localStorage.getItem("FlinksToken"));
									if (localStorage.getItem("AccountNumber")) {
										self.set('paymentToAddFrom.MaskedAccount', localStorage.getItem("AccountNumber"));
									} else {
										self.set("paymentToAddFrom.MaskedAccount", localStorage.getItem("MaskedAccount"));
									}
									if (localStorage.getItem("BankId")) {
										self.set('paymentToAddFrom.BankId', localStorage.getItem("BankId"));
									}
									localStorage.removeItem("BankId");

									self.set("paymentToAddFrom.BranchTransitNumber", "");
									self.set("paymentToAddFrom.AccountNumber", "");

									self.set("InformationValue", "Manual");
									self.initialiseAutoAddress2();

									_ember['default'].$("#paymentInfo").hide();
									_ember['default'].$("#benifeciaryInfo").hide();
									_ember['default'].$("#bankInfo").hide();
									_ember['default'].$("#benifeciaryInfo2").show();
									_ember['default'].$("#etransfer").hide();
									self.set("isOverview", false);
								} else if (self.get("isNew") == true) {
									self.set('paymentToAddFrom', self.addPaymentMethod(paymentFormArr));
									self.set('paymentToAddFrom.DollarAmount', self.get("amount").replace(/,/g, ''));
									if (localStorage.getItem("institution") && localStorage.getItem("institution") != "undefined") {
										self.set('paymentToAddFrom.InstitutionName', localStorage.getItem("institution"));
										self.set("paymentToAddFrom.FinancialInstitutionNumber", "");
									} else {
										var finNumber = localStorage.getItem("InstitutionNumber");
										var bank = _vopay_appConstants['default'].canadaBanks.filter(function (b) {
											return parseInt(b.code) === parseInt(finNumber);
										});

										if (bank[0]) {
											self.set('paymentToAddFrom.InstitutionName', bank[0].name);
										} else {
											self.set('paymentToAddFrom.InstitutionName', "");
										}
										self.set("paymentToAddFrom.FinancialInstitutionNumber", finNumber);
									}
									if (self.get("country") === "Canada") {
										self.set("paymentToAddFrom.Country", "CA");
									} else {
										self.set("paymentToAddFrom.Country", self.get("isoCountry"));
									}
									if (self.get("paymentToAddFrom.Iban")) {
										self.set("paymentToAddFrom.Iban", self.get("isoCountry") + "" + self.get("paymentToAddFrom.Iban"));
									}

									self.set("paymentToAddFrom.Currency", self.get("currency2"));
									self.set("paymentToAddFrom.Notes", _ember['default'].$("#Notes").val());
									self.set("paymentToAddFrom.Token", localStorage.getItem("FlinksToken"));
									if (localStorage.getItem("AccountNumber")) {
										self.set('paymentToAddFrom.MaskedAccount', localStorage.getItem("AccountNumber"));
									} else {
										self.set("paymentToAddFrom.MaskedAccount", localStorage.getItem("MaskedAccount"));
									}
									if (localStorage.getItem("BankId")) {
										self.set('paymentToAddFrom.BankId', localStorage.getItem("BankId"));
									}
									localStorage.removeItem("BankId");

									self.set("paymentToAddFrom.BranchTransitNumber", "");
									self.set("paymentToAddFrom.AccountNumber", "");
									self.set("InformationValue", "Manual");
									var paymentFormDataArr = _ember['default'].$('#paymentForm').serializeArray();
									var paymentData = self.addPaymentMethod(paymentFormDataArr);
									self.set("paymentToAddTo.ClientAccountID", paymentData.ClientAccountID);
									self.set("paymentToAddFrom.ClientAccountID", paymentData.ClientAccountID);
									self.set("paymentToAddTo.ClientAccountName", paymentData.ClientAccountName);
									self.set("paymentToAddFrom.ClientAccountName", paymentData.ClientAccountName);
									self.set("paymentToAddTo.DollarAmount", _ember['default'].$("#amount").val().replace(/\,/g, ""));
									self.set("paymentToAddFrom.DollarAmount", _ember['default'].$("#amount").val().replace(/\,/g, ""));
									_ember['default'].$("#paymentInfo").hide();
									_ember['default'].$("#benifeciaryInfo").hide();
									_ember['default'].$("#bankInfo").hide();
									_ember['default'].$("#Overview").show();
									_ember['default'].$("#etransfer").hide();
									self.set("isOverview", true);
								} else if (self.get("isNew2") == true) {
									self.set('paymentToAddTo', self.addPaymentMethod(paymentFormArr));
									self.set('paymentToAddTo.DollarAmount', self.get("amount").replace(/,/g, ''));
									if (localStorage.getItem("institution") && localStorage.getItem("institution") != "undefined") {
										self.set('paymentToAddTo.InstitutionName', localStorage.getItem("institution"));
										self.set("paymentToAddTo.FinancialInstitutionNumber", "");
									} else {
										var finNumber = localStorage.getItem("InstitutionNumber");
										var bank = _vopay_appConstants['default'].canadaBanks.filter(function (b) {
											return parseInt(b.code) === parseInt(finNumber);
										});

										if (bank[0]) {
											self.set('paymentToAddTo.InstitutionName', bank[0].name);
										} else {
											self.set('paymentToAddTo.InstitutionName', "");
										}
										self.set("paymentToAddTo.FinancialInstitutionNumber", finNumber);
									}
									if (self.get("country") === "Canada") {
										self.set("paymentToAddTo.Country", "CA");
									} else {
										self.set("paymentToAddTo.Country", self.get("isoCountry"));
									}
									if (self.get("paymentToAddTo.Iban")) {
										self.set("paymentToAddTo.Iban", self.get("isoCountry") + "" + self.get("paymentToAddTo.Iban"));
									}

									self.set("paymentToAddTo.Currency", self.get("currency2"));
									self.set("paymentToAddTo.Notes", _ember['default'].$("#Notes").val());
									self.set("paymentToAddTo.Token", localStorage.getItem("FlinksToken"));
									if (localStorage.getItem("AccountNumber")) {
										self.set('paymentToAddTo.MaskedAccount', localStorage.getItem("AccountNumber"));
									} else {
										self.set("paymentToAddTo.MaskedAccount", localStorage.getItem("MaskedAccount"));
									}
									if (localStorage.getItem("BankId")) {
										self.set('paymentToAddTo.BankId', localStorage.getItem("BankId"));
									}
									localStorage.removeItem("BankId");

									self.set("paymentToAddTo.BranchTransitNumber", "");
									self.set("paymentToAddTo.AccountNumber", "");
									var paymentFormDataArr = _ember['default'].$('#paymentForm').serializeArray();
									var paymentData = self.addPaymentMethod(paymentFormDataArr);
									self.set("paymentToAddTo.ClientAccountID", paymentData.ClientAccountID);
									self.set("paymentToAddFrom.ClientAccountID", paymentData.ClientAccountID);
									self.set("paymentToAddTo.ClientAccountName", paymentData.ClientAccountName);
									self.set("paymentToAddFrom.ClientAccountName", paymentData.ClientAccountName);
									self.set("paymentToAddTo.DollarAmount", _ember['default'].$("#amount").val().replace(/\,/g, ""));
									self.set("paymentToAddFrom.DollarAmount", _ember['default'].$("#amount").val().replace(/\,/g, ""));
									_ember['default'].$("#paymentInfo").hide();
									_ember['default'].$("#benifeciaryInfo").hide();
									_ember['default'].$("#bankInfo").hide();
									_ember['default'].$("#Overview").show();
									_ember['default'].$("#etransfer").hide();
								}

								self.set("financialInstitutionNumber", "");
								self.set("accountNumber", "");
								self.set("branchTransitNumber", "");
								self.set("SignatoryPhoneNumber", "");
								self.set("DualPhoneNumber", "");
								self.set("isFlinks2", true);
								self.set("isFlinks", false);
								self.set("InformationValue", "Manual");
								localStorage.removeItem("AccountNumber");
								localStorage.removeItem("TokenLocation");
								localStorage.removeItem("FlinksToken");
								localStorage.removeItem("MaskedAccount");
								localStorage.removeItem("FullName");
								localStorage.removeItem("accountId");
								localStorage.removeItem("loginId");
								localStorage.removeItem("institution");
								clearInterval(window.flinksInterval);
							}
						}, 100);
					})();
				} else {
					this.set("isFlinks", false);
					clearInterval(window.flinksInterval);
				}
			},
			handleIfFlinks2: function handleIfFlinks2(value) {
				var _this7 = this;

				if (value === "Online Banking") {
					(function () {
						_this7.SetIq11URL(_this7.get("ClientReferenceNumber2"));
						_this7.set("isFlinks", true);
						var self = _this7;
						window.flinksInterval = setInterval(function () {
							if (localStorage.getItem("FlinksToken") != null) {
								var paymentFormArr = _ember['default'].$('#paymentForm2').serializeArray();
								self.set('paymentToAddTo', self.addPaymentMethod(paymentFormArr));
								self.set('paymentToAddTo.DollarAmount', self.get("amount").replace(/,/g, ''));

								if (localStorage.getItem("institution") && localStorage.getItem("institution") != "undefined") {
									self.set('paymentToAddTo.InstitutionName', localStorage.getItem("institution"));
									self.set("paymentToAddTo.FinancialInstitutionNumber", "");
								} else {
									var finNumber = localStorage.getItem("InstitutionNumber");
									var bank = _vopay_appConstants['default'].canadaBanks.filter(function (b) {
										return parseInt(b.code) === parseInt(finNumber);
									});

									if (bank[0]) {
										self.set('paymentToAddTo.InstitutionName', bank[0].name);
									} else {
										self.set('paymentToAddTo.InstitutionName', "");
									}
									self.set("paymentToAddTo.FinancialInstitutionNumber", finNumber);
								}
								if (self.get("country") === "Canada") {
									self.set("paymentToAddTo.Country", "CA");
								} else {
									self.set("paymentToAddTo.Country", self.get("isoCountry"));
								}
								if (self.get("paymentToAddTo.Iban")) {
									self.set("paymentToAddTo.Iban", self.get("isoCountry") + "" + self.get("paymentToAddTo.Iban"));
								}

								self.set("paymentToAddTo.Currency", self.get("currency2"));
								self.set("paymentToAddTo.Notes", _ember['default'].$("#Notes").val());
								self.set("paymentToAddTo.Token", localStorage.getItem("FlinksToken"));
								if (localStorage.getItem("AccountNumber")) {
									self.set('paymentToAddTo.MaskedAccount', localStorage.getItem("AccountNumber"));
								} else {
									self.set("paymentToAddTo.MaskedAccount", localStorage.getItem("MaskedAccount"));
								}
								if (localStorage.getItem("BankId")) {
									self.set('paymentToAddTo.BankId', localStorage.getItem("BankId"));
								}
								localStorage.removeItem("BankId");

								self.set("paymentToAddTo.BranchTransitNumber", "");
								self.set("paymentToAddTo.AccountNumber", "");

								if (self.get("isFlinks2")) {
									self.set("paymentToAddTo.DollarAmount", _ember['default'].$("#amount").val().replace(/\,/g, ""));
									self.set("paymentToAddFrom.DollarAmount", _ember['default'].$("#amount").val().replace(/\,/g, ""));
									var paymentFormDataArr = _ember['default'].$('#paymentForm').serializeArray();
									var paymentData = self.addPaymentMethod(paymentFormDataArr);

									self.set("paymentToAddTo.ClientAccountID", paymentData.ClientAccountID);
									self.set("paymentToAddFrom.ClientAccountID", paymentData.ClientAccountID);
									self.set("paymentToAddTo.ClientAccountName", paymentData.ClientAccountName);
									self.set("paymentToAddFrom.ClientAccountName", paymentData.ClientAccountName);
								} else {
									var _paymentFormArr2 = _ember['default'].$('#paymentForm').serializeArray();
									self.set('paymentToAddFrom', self.addPaymentMethod(_paymentFormArr2));
									self.set("paymentToAddFrom.Currency", self.get("currency2"));

									if (self.get("country") === "Canada") {
										self.set("paymentToAddFrom.Country", "CA");
									} else {
										self.set("paymentToAddFrom.Country", self.get("isoCountry"));
									}
									var paymentFormDataArr = _ember['default'].$('#paymentForm').serializeArray();
									var paymentData = self.addPaymentMethod(paymentFormDataArr);

									self.set("paymentToAddTo.ClientAccountID", paymentData.ClientAccountID);
									self.set("paymentToAddFrom.ClientAccountID", paymentData.ClientAccountID);
									self.set("paymentToAddTo.ClientAccountName", paymentData.ClientAccountName);
									self.set("paymentToAddFrom.ClientAccountName", paymentData.ClientAccountName);
									self.set("paymentToAddTo.DollarAmount", _ember['default'].$("#amount").val().replace(/\,/g, ""));
									self.set("paymentToAddFrom.DollarAmount", _ember['default'].$("#amount").val().replace(/\,/g, ""));
								}

								self.set("isOverview", true);
								_ember['default'].$("#paymentInfo").hide();
								_ember['default'].$("#benifeciaryInfo").hide();
								_ember['default'].$("#bankInfo2").hide();
								_ember['default'].$("#benifeciaryInfo2").hide();
								_ember['default'].$("#Overview").show();
								_ember['default'].$("#etransfer").hide();
								self.set("financialInstitutionNumber2", "");
								self.set("accountNumber2", "");
								self.set("branchTransitNumber2", "");
								self.set("SignatoryPhoneNumber2", "");
								self.set("DualPhoneNumber2", "");
								self.set("isFlinks3", true);
								self.set("isFlinks", false);
								self.set("InformationValue", "Manual");
								localStorage.removeItem("AccountNumber");
								localStorage.removeItem("TokenLocation");
								localStorage.removeItem("FlinksToken");
								localStorage.removeItem("MaskedAccount");
								localStorage.removeItem("FullName");
								localStorage.removeItem("accountId");
								localStorage.removeItem("loginId");
								localStorage.removeItem("institution");
								clearInterval(window.flinksInterval);
							}
						}, 100);
					})();
				} else {
					this.set("isFlinks", false);
					clearInterval(window.flinksInterval);
				}
			},
			handleAccountType: function handleAccountType(value) {
				if (value === "Business") {
					this.set("isBusiness", true);
				} else {
					this.set("isBusiness", false);
				}
			},
			handleAccountType2: function handleAccountType2(value) {
				if (value === "Business") {
					this.set("isBusiness2", true);
				} else {
					this.set("isBusiness2", false);
				}
			},
			selectAccountTo: function selectAccountTo(value) {
				var _this8 = this;

				if (value === "New") {
					var myVar;

					(function () {
						_this8.set("isNew2", true);
						_this8.set("fromAccounts", _this8.get("accounts2"));
						var self = _this8;
						myVar = setTimeout(function () {
							self.initialiseAutoAddress();
							clearTimeout(myVar);
						}, 1000);
					})();
				} else {
					this.set("isNew2", false);
					var fromArray = Object.assign({}, this.get("accounts2"));
					delete fromArray[value];
					this.set("fromAccounts", fromArray);

					var data = this.get("accounts");
					var paymentToAdd = data[value];
					//paymentToAdd.DollarAmount = Ember.$("#amount").val().replace(/\,/g,"");
					this.set("paymentToAddTo", paymentToAdd);
				}
			},

			create: function create() {
				var _this9 = this;

				//this.get("routing").transitionTo("dashboard.info");

				this.get("api").fundTransaction(this.get("paymentToAddFrom")).then(function (data) {
					_this9.set("paymentToAddTo.ParentTransactionID", data.TransactionID);
					_this9.get("api").withdrawTransaction(_this9.get("paymentToAddTo")).then(function (data2) {
						localStorage.setItem("link", window.location.href);
						window.location.href = "#/dashboard/transaction/Fund/" + data.TransactionID;
					}, function (err) {
						_this9.errorMessage(err);
					});
				}, function (err) {
					_this9.errorMessage(err);
				});
			},
			overview: function overview() {
				var paymentFormArr = _ember['default'].$('#paymentForm').serializeArray();
				var paymentData = this.addPaymentMethod(paymentFormArr);
				if (this.get("isNew") == true && this.get("isNew2") == true) {

					var paymentFormArr2 = _ember['default'].$('#paymentForm2').serializeArray();
					if (!this.get("isFlinks2")) {
						this.set('paymentToAddFrom', this.addPaymentMethod(paymentFormArr));
						this.set("paymentToAddFrom.Currency", this.get("currency2"));
						if (this.get("country") === "Canada") {
							this.set("paymentToAddFrom.Country", "CA");
						} else {
							this.set("paymentToAddFrom.Country", this.get("isoCountry"));
						}
					}
					if (!this.get("isFlinks3")) {
						this.set('paymentToAddTo', this.addPaymentMethod(paymentFormArr2));

						this.set("paymentToAddTo.Currency", this.get("currency2"));
						if (this.get("country") === "Canada") {
							this.set("paymentToAddTo.Country", "CA");
						} else {
							this.set("paymentToAddTo.Country", this.get("isoCountry"));
						}
					}
				} else if (this.get("isNew") == true) {
					this.set('paymentToAddFrom', this.addPaymentMethod(paymentFormArr));
					this.set("paymentToAddFrom.Currency", this.get("currency2"));

					if (this.get("country") === "Canada") {
						this.set("paymentToAddFrom.Country", "CA");
					} else {
						this.set("paymentToAddFrom.Country", this.get("isoCountry"));
					}
				} else if (this.get("isNew2") == true) {
					this.set('paymentToAddTo', this.addPaymentMethod(paymentFormArr));
					this.set("paymentToAddTo.Currency", this.get("currency2"));
					if (this.get("country") === "Canada") {
						this.set("paymentToAddTo.Country", "CA");
					} else {
						this.set("paymentToAddTo.Country", this.get("isoCountry"));
					}
				}

				if (this.checkIsAllowed() && this.validateForm('#paymentForm') && this.get("isAgree")) {
					this.set("paymentToAddTo.DollarAmount", _ember['default'].$("#amount").val().replace(/\,/g, ""));
					this.set("paymentToAddFrom.DollarAmount", _ember['default'].$("#amount").val().replace(/\,/g, ""));
					this.set("paymentToAddTo.ClientAccountID", paymentData.ClientAccountID);
					this.set("paymentToAddFrom.ClientAccountID", paymentData.ClientAccountID);
					this.set("paymentToAddTo.ClientAccountName", paymentData.ClientAccountName);
					this.set("paymentToAddFrom.ClientAccountName", paymentData.ClientAccountName);

					_ember['default'].$("#Overview").show();
					_ember['default'].$("#paymentInfo").hide();
					_ember['default'].$("#benifeciaryInfo").hide();
					_ember['default'].$("#bankInfo").hide();
					this.set("isOverview", true);
				}
				if (this.checkIsAllowed() && this.validateForm('#paymentForm2') && this.get("isAgree") && this.get("isNew2") == true && this.get("isNew") == true) {
					this.set("paymentToAddTo.DollarAmount", _ember['default'].$("#amount").val().replace(/\,/g, ""));
					this.set("paymentToAddFrom.DollarAmount", _ember['default'].$("#amount").val().replace(/\,/g, ""));
					this.set("paymentToAddTo.ClientAccountID", paymentData.ClientAccountID);
					this.set("paymentToAddFrom.ClientAccountID", paymentData.ClientAccountID);
					this.set("paymentToAddTo.ClientAccountName", paymentData.ClientAccountName);
					this.set("paymentToAddFrom.ClientAccountName", paymentData.ClientAccountName);
					_ember['default'].$("#Overview").show();
					_ember['default'].$("#paymentInfo").hide();
					_ember['default'].$("#benifeciaryInfo").hide();
					_ember['default'].$("#bankInfo2").hide();
					_ember['default'].$("#bankInfo").hide();
					this.set("isOverview", true);
				}
			}
		}
	});
});