define('vopay_app/routes/dashboard/e-voucher', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service('session'),
    api: _ember['default'].inject.service('api'),
    moveToTop: (function () {
      window.scrollTo(0, 0);
    }).on('activate'),
    model: function model() {

      var authed = true;
      var options = {};
      if (authed) {

        return _ember['default'].RSVP.hash({
          transactions: this.get("api").getVopinTransactions(options)
        });
      } else {
        this.transitionTo('login');
      }
    },

    _parsePaymentData: function _parsePaymentData(payment) {

      var paymentObject = {};
      var parsedPayment = {};

      paymentObject = JSON.parse(payment);

      for (var key in paymentObject) {
        var iKey = key.replace(/_+/g, "");
        parsedPayment[iKey] = paymentObject[key];
      }

      return parsedPayment;
    },

    _retrievePayment: function _retrievePayment(transactionList) {
      var _this = this;

      return new _ember['default'].RSVP.Promise(function (resolve) {
        _this.get('api').getPaymentMethods().then(function (data) {

          for (var trans in transactionList) {

            var payments = trans.PaymentMethod === 'Bank' ? data.BankAccounts : data.CreditCards;
            for (var key in payments) {

              if (trans.PaymentMethodID === payments[key].BankAccountID || trans.PaymentMethodID === payments[key].CreditCardID) {

                transactionList[trans].payment = payments[key];
              }
            }
          }

          resolve(transactionList);
        });
      });
    },

    _retrieveRecipient: function _retrieveRecipient(transactionList) {
      var _this2 = this;

      return new _ember['default'].RSVP.Promise(function (resolve) {

        _this2.get('api').listRecipients().then(function (data) {

          for (var i = 0; i < data.length; i++) {

            data[i].BankAccount = _this2._parsePaymentData(data[i].BankAccount);
            for (var trans in transactionList) {

              if (transactionList[trans].RemittanceRecipientID === data[i].RemittanceRecipientID) {

                transactionList[trans].recipient = data[i];
              }
            }
          }

          for (var trans in transactionList) {
            if (transactionList[trans].recipient) {
              if (transactionList[trans].recipient.DeliveryMethod === "cash") {
                transactionList[trans].DeliveryMethodClass = 'cashPickUp';
                transactionList[trans].DeliveryMethodLabel = 'Cash Pick-Up';
              } else {
                transactionList[trans].DeliveryMethodClass = 'bankDeposit';
                transactionList[trans].DeliveryMethodLabel = 'Bank Deposit';
              }
            }
          }
          resolve(transactionList);
        });
      });
    },

    _setupTransaction: function _setupTransaction(transactionList) {

      return new _ember['default'].RSVP.Promise(function (resolve) {

        for (var trans in transactionList) {

          // Status transaction

          switch (transactionList[trans].RemittanceStatus) {//pending, cancelled or processed
            case 'pending':
              transactionList[trans].StatusTransClass = 'inProgress';
              transactionList[trans].StatusTrans = 'In Progress';
              transactionList[trans].isTrackable = true;
              break;
            case 'cancelled':
              transactionList[trans].StatusTransClass = 'cancelled';
              transactionList[trans].StatusTrans = 'Cancelled';
              transactionList[trans].isTrackable = false;
              break;
            case 'failed':
              transactionList[trans].StatusTransClass = 'failed';
              transactionList[trans].StatusTrans = 'In Progress';
              transactionList[trans].isTrackable = false;
              break;
            case 'successful':
              transactionList[trans].StatusTransClass = 'completed';
              transactionList[trans].StatusTrans = 'Completed';
              transactionList[trans].isTrackable = false;
              break;
            default:
              transactionList[trans].StatusTransClass = 'inProgress';
              transactionList[trans].StatusTrans = 'In Progress';
              transactionList[trans].isTrackable = true;
              break;
          }

          switch (transactionList[trans].PaymentStatus) {//pending, cancelled or processed
            case 'pending':
              transactionList[trans].StatusTransClass = 'inProgress';
              transactionList[trans].StatusTrans = 'In Progress';
              transactionList[trans].isTrackable = true;
              break;
            case 'cancelled':
              transactionList[trans].StatusTransClass = 'cancelled';
              transactionList[trans].StatusTrans = 'Cancelled';
              transactionList[trans].isTrackable = false;
              break;
            case 'failed':
              transactionList[trans].StatusTransClass = 'failed';
              transactionList[trans].StatusTrans = 'In Progress';
              transactionList[trans].isTrackable = false;
              break;
          }

          // Payment methods
          switch (transactionList[trans].PaymentMethod) {//pending, cancelled or processed
            case 'bank':
              transactionList[trans].PaymentMethodClass = 'bankDeposit';
              transactionList[trans].PaymentMethodLabel = 'Bank Deposit';
              break;
            case 'CreditCard':
              transactionList[trans].PaymentMethodClass = 'creditCard';
              transactionList[trans].PaymentMethodLabel = 'Credit Card';
              break;
            default:
              transactionList[trans].PaymentMethodClass = 'bankDeposit';
              transactionList[trans].PaymentMethodLabel = 'Bank Deposit';
              break;
          }
        }

        resolve(transactionList);
      });
    },

    _checkInfo: function _checkInfo(transactionList) {},

    _retrieveTransactions: function _retrieveTransactions() {
      var _this3 = this;

      return this.get('api').getTransactions().then(function (trans) {
        return _this3._setupTransaction(trans);
      }).then(function (trans) {
        return _this3._retrieveRecipient(trans);
      }).then(function (trans) {
        return _this3._retrievePayment(trans);
      })['catch'](function (trans) {
        return _this3._checkInfo(trans);
      });
    }
  });
});