define('vopay_app/components/currency-input', ['exports', 'ember', 'vopay_app/utils/entry-filter'], function (exports, _ember, _vopay_appUtilsEntryFilter) {
  exports['default'] = _ember['default'].Component.extend({
    selectedCurrency: null,
    menuActive: false,
    entryUtil: _vopay_appUtilsEntryFilter['default'].create(),
    api: _ember['default'].inject.service('api'),
    maxMessage: null,
    wachValue: _ember['default'].observer('amount', function () {
      var amount = this.get('amount');
      if (amount) {
        var myElement = this.$(".currencyInputField")[0];
        if (myElement) {
          var start = myElement.selectionStart;
          var end = myElement.selectionEnd;
          var amount2 = amount;
          if (amount.replace()) {
            amount2 = Math.floor(parseFloat(amount.replace(/,/g, '')));
          }
          var split = amount.split(".");
          var ending = split[1];
          var amount3 = undefined;
          if (Math.floor(amount2) === 0) {
            amount3 = amount;
          } else {
            if (ending || ending === 0) {
              amount3 = this.get('entryUtil').onlyCurrencySimple(amount2.toLocaleString(undefined) + "." + ending);
            } else {

              amount3 = this.get('entryUtil').onlyCurrencySimple(amount2.toLocaleString(undefined));
            }
          }
          this.$(myElement).val(amount3);
          if (myElement.setSelectionRange) {
            if (amount3.length > amount.length && amount3.length > 4) {
              myElement.setSelectionRange(start + 1, end + 1);
            } else if (amount3.length < amount.length) {
              myElement.setSelectionRange(start - 1, end - 1);
            } else {
              myElement.setSelectionRange(start, end);
            }
          } else if (myElement.createTextRange) {
            var range = myElement.createTextRange();
            range.collapse(true);
            range.moveEnd('character', start);
            range.moveStart('character', end);
            range.select();
          }
        }
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.set("maxMessage", "You can send a maximum of $" + this.get("maxNumber"));
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      _ember['default'].$(".currencyInput_content option[value=\"" + this.get("country") + "\"]").prop('selected', true);
      _ember['default'].$('form').parsley({
        successClass: " ",
        errorClass: "form__input--error",
        errorsWrapper: "<p></p>",
        errorTemplate: "<span class='form__message'></span>"
      });
      this.$("input").focusin(function () {
        _ember['default'].$("#sendingLabel").addClass("form__label--activeBlue");
      });
      this.$("input").focusout(function () {
        _ember['default'].$("#sendingLabel").removeClass("form__label--activeBlue");
      });
    },
    /***
    * toggleMenu
    *
    * Toggles the dropdown menu
    */
    toggleMenu: function toggleMenu() {

      if (!this.get('menuActive')) {
        this.set('menuActive', true);
      } else {
        this.set('menuActive', false);
      }
    },

    actions: {
      /***
      * currencyChanged
      * @param country (String) - Country to set
      * @param currency (String) - Currency to set
      *
      * Sets the country and currency, sends to top level component
      */
      currencyChanged: function currencyChanged(country, currency) {
        this.sendAction('currencyChanged', country, currency);
        _ember['default'].$(".currencyInput_content option[value=\"" + country + "\"]").prop('selected', true);
        this.toggleMenu();
      },

      /***
      * toggleMenu
      *
      * Toggles the dropdown menu
      */
      activateMenu: function activateMenu() {
        this.toggleMenu();
      },

      /***
      * showPopup
      *
      * Triggers event to trigger popup
      */
      showPopup: function showPopup() {
        _ember['default'].$.event.trigger({
          type: "triggerPopup",
          popup: "suggestion"
        });

        this.toggleMenu();
      },

      /***
        Activate proper action on element selection
      */
      selectChanged: function selectChanged(country, currency) {
        if (country === "other") {

          this.send('showPopup');
        } else {

          this.send('currencyChanged', country, currency);
        }
      },

      senderAmountChanged: function senderAmountChanged(amount) {

        this.sendAction('senderAmountChanged', amount);
      }

    }
  });
});
/**
* Initalising properties:
*
* amount: starting amount
* country: starting country
* currency: starting currency
* amountChanged (callback): fired when the amount is changed
* currencyChanged (callback): fired when the currency is changed
* lock (optional): limits countries to just Canada
*
*/